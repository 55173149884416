import { configure, extend } from "vee-validate";
import {
  confirmed,
  digits,
  email,
  max,
  min,
  required,
} from "vee-validate/dist/rules";
import { futureButNoMoreThan9Months } from "@/validation/custom-rules";
import vueI18n from "@/i18n";
import {
  defaultMessageConfigurationFn,
  getFullNameRule,
  getOrganizationNumberRule,
  getPasswordRule,
  getPersonalIdentificationNumberRule,
  getPostalCodeRule,
  getTelephoneRule,
  inSweden,
} from "@defa-as/utils";

export const setupValidation = () => {
  registerValidationRules();
  configureValidationMessages();
};

const registerValidationRules = () => {
  extend("email", email);
  extend("required", required);
  extend("min", min);
  extend("max", max);
  extend("digits", digits);
  extend("confirmed", confirmed);
  extend("futureButNoMoreThan9Months", futureButNoMoreThan9Months);
  extend("telephone", getTelephoneRule());
  extend("password", getPasswordRule());
  extend("fullName", getFullNameRule());
  extend("postalCode", getPostalCodeRule());
  extend("organizationNumber", getOrganizationNumberRule());
  if (inSweden()) {
    extend(
      "personalIdentificationNumber",
      getPersonalIdentificationNumberRule()
    );
  }
};

const configureValidationMessages = () => {
  configure({
    defaultMessage: defaultMessageConfigurationFn(vueI18n),
    useConstraintAttrs: false,
  });
};

export const validationConfigForFields = {
  customerCarDeliveryDate: {
    rules: {
      required: true,
      futureButNoMoreThan9Months: true,
    },
  },
};
