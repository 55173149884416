
















import { Component, Prop, Vue } from "vue-property-decorator";
import InputPassword from "@/components/forms/InputPassword.vue";

@Component({
  components: {
    InputPassword,
  },
})
export default class PasswordWithConfirmation extends Vue {
  @Prop({ required: true }) readonly passwordValue!: string;
  @Prop({ required: true }) readonly passwordConfirmValue!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: "" }) readonly labelPasswordNew!: string;

  get passwordValueForInput() {
    return this.passwordValue;
  }

  set passwordValueForInput(value: string) {
    this.$emit("input:password", value);
  }

  get passwordConfirmValueForInput() {
    return this.passwordConfirmValue;
  }

  set passwordConfirmValueForInput(value: string) {
    this.$emit("input:password-confirm", value);
  }
}
