













import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { assignmentModule } from "@/store/modules/assignment";

@Component({
  components: {
    DefaBaseButton,
  },
})
export default class ProgressTabInstallationPartnerButtonEmail extends Vue {
  @Prop({ default: false }) readonly secondary!: boolean;
  sendingInstallationPartnerEmail = false;

  async onClickButton() {
    this.sendingInstallationPartnerEmail = true;
    try {
      await assignmentModule.sendAssignmentToInstallationPartner();
    } finally {
      this.sendingInstallationPartnerEmail = false;
    }
  }
}
