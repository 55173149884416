import { getRetailerGroupHandleForDomain } from "@/config/portals/retailer-groups";
import { publicRetailerGroupHandle } from "@defa-as/utils";

export const getTitle = () => {
  const retailerGroupForDomain = getRetailerGroupHandleForDomain();
  const suffix = document.title;
  if (retailerGroupForDomain === "ham") {
    return `Harald A. Møller | ${suffix}`;
  }
  if (retailerGroupForDomain === "bos") {
    return `Bertel O. Steen | ${suffix}`;
  }
  if (retailerGroupForDomain === "upl") {
    return `Upplands Motor | ${suffix}`;
  }
  if (retailerGroupForDomain === "nc") {
    return `Nic Christianssen Gruppen | ${suffix}`;
  }
  if (retailerGroupForDomain === publicRetailerGroupHandle) {
    return suffix;
  }
  return `${retailerGroupForDomain
    .charAt(0)
    .toUpperCase()}${retailerGroupForDomain.slice(1)} | ${suffix}`;
};
