


















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";

@Component({
  components: { DefaBaseButton },
})
export default class HomecheckAction extends Vue {
  @Prop({ required: true }) readonly readonly!: boolean;
  @Prop({ default: false }) readonly loading!: boolean;

  get isDisabled() {
    return this.readonly || this.loading;
  }
}
