





























































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseInput,
  DefaIconCaret,
  DefaLoaderCircle,
} from "@defa-as/components";
import type { InstallationPartner, Nullable } from "@defa-as/utils";
import ProgressTabInstallationPartnerLookupEntry from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerLookupEntry.vue";
import { findInstallationPartnerByZip } from "@/http/requests/requests-assignment";

const installersPerPage = 5;

const keyInstallersById = (
  installersKeyedById: Record<string, InstallationPartner>,
  installer: InstallationPartner
) => {
  installersKeyedById[installer.id] = installer;
  return installersKeyedById;
};

@Component({
  components: {
    DefaLoaderCircle,
    ProgressTabInstallationPartnerLookupEntry,
    DefaBaseInput,
    DefaIconCaret,
  },
})
export default class ProgressTabInstallationPartnerLookup extends Vue {
  @Prop({ default: "" }) readonly zip!: string;
  @Prop({ default: null }) readonly installationPartner!: InstallationPartner;
  @Prop({ default: "bottom" }) readonly positionDropdown!: "bottom" | "top";
  search = "";
  opened = false;
  selected = false;
  currentPage = 1;
  installersKeyedById: Nullable<Record<string, InstallationPartner>> = null;
  loading = false;
  pagination = {
    total: 0,
    // eslint-disable-next-line camelcase
    current_page: 1,
  };

  get installerLookupCaretDirection() {
    if (this.opened) {
      return "up";
    }
    return "down";
  }

  get positionDropdownClass() {
    return `installation-partner-lookup__selector-pane--${this.positionDropdown}`;
  }

  get totalPages() {
    return Math.ceil(this.pagination.total / installersPerPage);
  }

  get hasNextPage() {
    return this.currentPage < this.totalPages;
  }

  get hasPreviousPage() {
    return this.currentPage > 1;
  }

  get hasResults() {
    return this.totalPages > 0;
  }

  get showNotFound() {
    return this.search.length > 1 && !this.loading;
  }

  get installers() {
    return this.installersKeyedById
      ? Object.values(this.installersKeyedById)
      : [];
  }

  get pagedSuggestions() {
    if (this.installersKeyedById) {
      const offset = (this.currentPage - 1) * installersPerPage;
      return this.installers.slice(offset, offset + installersPerPage);
    }
    return [];
  }

  get colorPrimaryCaretPrevious() {
    if (this.hasPreviousPage) {
      return "var(--defa-oreon-color-black)";
    }
    return "#f0f0f0";
  }

  get colorPrimaryCaretNext() {
    if (this.hasNextPage) {
      return "var(--defa-oreon-color-black)";
    }
    return "#f0f0f0";
  }

  created() {
    if (this.installationPartner) {
      this.selected = true;
    }
  }

  async lookupCompany() {
    this.loading = true;
    try {
      const installersResponse = await findInstallationPartnerByZip({
        page: this.pagination.current_page,
        search: this.search,
        zip: this.zip,
      });
      if (this.search) {
        this.installersKeyedById = installersResponse.data.reduce(
          keyInstallersById,
          {}
        );
        this.currentPage = 1;
      } else {
        this.installersKeyedById = [
          ...this.installers,
          ...installersResponse.data,
        ].reduce(keyInstallersById, {});
      }
      this.pagination = installersResponse.meta.pagination;
    } finally {
      this.loading = false;
    }
  }

  onClickSuggestion(installationPartner: InstallationPartner) {
    this.opened = false;
    this.selected = true;
    this.resetSuggestions();
    this.$emit("choose-installation-partner", {
      installationPartner: installationPartner.id,
    });
  }

  previousPage() {
    if (this.currentPage % installersPerPage === 1) {
      this.pagination.current_page--;
    }
    this.currentPage--;
  }

  nextPage() {
    if (this.currentPage % installersPerPage === 0) {
      this.pagination.current_page++;
      this.lookupCompany();
    }
    this.currentPage++;
  }

  resetSuggestions() {
    this.installersKeyedById = null;
  }

  toggle() {
    this.opened = !this.opened;
    if (this.opened) {
      this.lookupCompany();
    }
  }
}
