import { getModule, Module, VuexModule } from "vuex-module-decorators";
import type { AssignmentTimestamps, Nullable } from "@defa-as/utils";
import { captureExceptionWithSentry } from "@defa-as/utils";
import type { TranslateResult } from "vue-i18n";
import store from "@/store";
import { assignmentModule } from "@/store/modules/assignment";
import { activeOrderModule } from "@/store/modules/active-order";
import {
  payloadForAssignmentConfirmed,
  payloadForAssignmentRejected,
  payloadForInspectionFailed,
  payloadForInspectionScheduled,
  payloadForInstallationScheduled,
  payloadForReportCompletedInstallationFailed,
  payloadForReportCompletedInstallationSuccessful,
} from "@/store/helpers/progress/timestamp-payloads";
import {
  visibilityFutureTimestamp,
  visibilityInspectionSuccessful,
  visibilityInstallationSuccessful,
} from "@/store/helpers/progress/timestamp-visibility";

interface PayloadItem {
  value: string | TranslateResult;
}

interface PayloadItems {
  values: string[] | TranslateResult[];
  links?: string[] | TranslateResult[];
}

/**
 * This module is due for a rewrite.
 * A lot of the code in here is hard to read and overly verbose
 */
@Module({ name: "progress", store, dynamic: true, namespaced: true })
export class ProgressModule extends VuexModule {
  get isTimestampVisible() {
    return (
      timestampName: keyof AssignmentTimestamps,
      timestampValue: Nullable<string>
    ) => {
      if (timestampValue) {
        return true;
      }
      switch (timestampName) {
        case "inspectionSuccessful":
          return visibilityInspectionSuccessful();
        case "installationSuccessful":
          return visibilityInstallationSuccessful();
        case "assignmentOpened":
        case "assignmentConfirmed":
        case "consignmentRequested":
        case "consignmentSent":
        case "installationScheduled":
        case "consignmentCompleted":
        case "reportCompleted":
          return visibilityFutureTimestamp();
        default:
          return false;
      }
    };
  }

  get payloadItemsForTimestamp() {
    return (
      timestampName: keyof AssignmentTimestamps
    ): (PayloadItem | PayloadItems)[] => {
      const assignment = assignmentModule.assignmentData;
      switch (timestampName) {
        case "assignmentRejected":
          return payloadForAssignmentRejected(assignment.rejection);
        case "inspectionRequired":
          return payloadForAssignmentConfirmed(assignment.confirmation);
        case "inspectionSkipped":
          return payloadForAssignmentConfirmed(assignment.confirmation);
        case "inspectionScheduled":
          return payloadForInspectionScheduled(assignment.inspection);
        case "inspectionFailed":
          return payloadForInspectionFailed(assignment.inspection);
        case "installationScheduled":
          return payloadForInstallationScheduled(assignment.installation);
        case "reportCompleted": {
          if (assignment.timestamps.get("installationSuccessful")) {
            return payloadForReportCompletedInstallationSuccessful(
              assignment.installation
            );
          }
          if (assignment.timestamps.get("installationFailed")) {
            return payloadForReportCompletedInstallationFailed(
              assignment.installation
            );
          }
          captureExceptionWithSentry(
            new Error(
              "Neither 'installationSuccessful' or 'installationFailed' timestamps were set, even though timestamp 'reportCompleted' was set!"
            )
          );
          return [];
        }
        default:
          return [];
      }
    };
  }

  get installationProgressItems() {
    return [...assignmentModule.assignmentTimestamps.entries()]
      .filter(([timestampName, timestampValue]) =>
        this.isTimestampVisible(timestampName, timestampValue)
      )
      .map(([timestampName, timestampValue]) => ({
        id: timestampName,
        description: `progress.installationPartner.assignment.items.${timestampName}`,
        completed: Boolean(timestampValue),
        time: timestampValue,
        payloadItems: timestampValue
          ? this.payloadItemsForTimestamp(timestampName)
          : [],
      }));
  }

  get adminProgressItems() {
    const remoteHomecheckSent = activeOrderModule.activeOrderStateField(
      "remoteHomeCheckSent"
    );
    const remoteHomecheckCustomerSubmitted = activeOrderModule.activeOrderStateField(
      "remoteHomeCheckCustomerSubmitted"
    );
    return [
      { id: "remoteHomeCheckSent", ...remoteHomecheckSent },
      {
        id: "remoteHomeCheckCustomerSubmitted",
        ...remoteHomecheckCustomerSubmitted,
      },
    ].map((activeOrderStateFieldWithId) => ({
      id: activeOrderStateFieldWithId.id,
      description: `progress.administratorTasks.readonly.${activeOrderStateFieldWithId.id}`,
      completed: activeOrderStateFieldWithId.state,
      time: activeOrderStateFieldWithId.dateUpdated,
    }));
  }
}

export const progressModule = getModule(ProgressModule);
