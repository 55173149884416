















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";

type PasswordType = "passwordNew" | "passwordCurrent" | "passwordConfirm";

@Component({
  components: {
    DefaBaseInput,
  },
})
export default class InputPassword extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ required: true }) readonly passwordType!: PasswordType;
  @Prop({ default: false }) readonly disabled!: boolean;

  get autocomplete() {
    if (this.passwordType === "passwordCurrent") {
      return "current-password";
    }
    return "new-password";
  }

  get valueForInput() {
    return this.value;
  }

  set valueForInput(value: string) {
    this.$emit("input", value);
  }

  get labelOrDefault() {
    return this.label || this.$t(`inputPassword.${this.passwordType}.label`);
  }

  get rules() {
    if (this.passwordType === "passwordConfirm") {
      return {
        required: true,
        confirmed: "passwordNew",
      };
    }
    if (this.passwordType === "passwordNew") {
      return "required|password";
    }
    return "required";
  }

  get customMessages() {
    if (this.passwordType === "passwordConfirm") {
      return {
        confirmed: this.$t("shared.veeValidate.customMessages.passwordConfirm"),
      };
    }
    return null;
  }
}
