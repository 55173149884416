





























import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaCircleWrapper } from "@defa-as/components";

@Component({
  components: {
    DefaCircleWrapper,
  },
})
export default class AutomaticTask extends Vue {
  @Prop({ default: false }) readonly done!: boolean;
  @Prop({ default: "" }) readonly dateUpdated!: string;
  @Prop({ default: "" }) readonly description!: string;
  @Prop({ default: false }) readonly useI18nFunctionalComponent!: boolean;
  @Prop({ default: "" }) readonly instructions!: string;
}
