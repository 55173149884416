














import { Component, Prop, Vue } from "vue-property-decorator";
import OrderHeaderNavigation from "@/components/OrderHeaderNavigation.vue";
import OrderHeaderGeneralInfo from "@/components/OrderHeaderGeneralInfo.vue";
import OrderHeaderActions from "@/components/OrderHeaderActions.vue";

@Component({
  components: {
    OrderHeaderActions,
    OrderHeaderGeneralInfo,
    OrderHeaderNavigation,
  },
})
export default class OrderHeader extends Vue {
  @Prop({ required: true }) readonly saveDisabled!: boolean;
  @Prop({ required: true }) readonly loadingSave!: boolean;
  @Prop({ required: true }) readonly loadingComplete!: boolean;
}
