







































import { Component, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseCheckbox,
  DefaLoaderCircle,
  DefaModal,
} from "@defa-as/components";
import { userModule } from "@/store/modules/user";
import {
  getAutomaticallySwitchActiveRetailer,
  setAutomaticallySwitchActiveRetailer,
} from "@defa-as/utils";
import { getRetailerGroupHandleForDomain } from "@/config/portals/retailer-groups";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    DefaModal,
    DefaLoaderCircle,
    DefaBaseCheckbox,
    DefaBaseButton,
  },
})
export default class RetailerGroupModalVerification extends Vue {
  loading = false;
  automaticallySwitchActiveRetailer = false;

  get origin() {
    return window.location.origin;
  }

  get userModule() {
    return userModule;
  }

  async created() {
    if (getAutomaticallySwitchActiveRetailer()) {
      await this.confirm();
    }
  }

  async confirm() {
    this.loading = true;
    try {
      await userModule.switchActiveRetailerId({
        handle: getRetailerGroupHandleForDomain(),
      });
      if (this.automaticallySwitchActiveRetailer) {
        setAutomaticallySwitchActiveRetailer();
      }
      if (this.$route.name === ROUTE_NAMES.CONNECT_RETAILER) {
        await this.$router.replace({
          name: ROUTE_NAMES.ROOT,
        });
      }
    } catch (e) {
      if (e.response?.status >= 400) {
        await userModule.logout();
      }
    } finally {
      this.loading = false;
    }
  }
}
