import { Retailer, RetailerGroup } from "@defa-as/utils";
import { httpClient, longTermCache } from "@/http";
import { getRetailerGroupHandleForDomain } from "@/config/portals/retailer-groups";

export const getRetailers = async (
  retailerGroupHandle = getRetailerGroupHandleForDomain()
): Promise<Retailer[]> => {
  const retailersResponse = await httpClient.get<
    { data: Retailer[] },
    { data: Retailer[] }
  >(`/retailers/${retailerGroupHandle}`, {
    cache: longTermCache,
  });
  return retailersResponse.data;
};
export const getRetailerGroups = async (
  filterOnUserDomain = false
): Promise<RetailerGroup[]> => {
  const { data: retailerGroups } = await httpClient.get<
    { data: RetailerGroup[] },
    { data: RetailerGroup[] }
  >("/retailer-groups", {
    params: {
      "exclude-without-domain": true,
      ...(filterOnUserDomain ? { "filter-on-user-domain": true } : null),
    },
  });
  return retailerGroups;
};
