






















import { Component, Prop, Vue } from "vue-property-decorator";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";
import { Option } from "@defa-as/utils";

@Component({
  components: {
    BaseSelect,
  },
})
export default class OrderListPaginationAndSorting extends Vue {
  @Prop({ required: true }) readonly totalPages!: number;
  @Prop({ required: true }) readonly sortValue!: string;
  @Prop({ required: true }) readonly sortOptions!: Option[];
  @Prop({ required: true }) readonly pageValue!: number;
  @Prop({ required: true }) readonly pageOptions!: Option[];
}
