
























import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaTransitionFadeOutIn } from "@defa-as/components";
import ButtonPair from "@/components/ButtonPair.vue";

@Component({
  components: {
    ButtonPair,
    DefaTransitionFadeOutIn,
  },
})
export default class ProfileSection extends Vue {
  @Prop({ required: true }) readonly loading!: boolean;
  @Prop({ required: true }) readonly editLinkText!: string;
  @Prop({ default: "" }) readonly profileHeading!: string;
  @Prop({ default: false }) readonly editMode!: boolean;
}
