var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"retailer-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('BaseSelect',{staticClass:"retailer-form__select",attrs:{"options":_vm.retailerOptionsSortedAndMapped,"exclude-default-option":_vm.excludeDefaultOption,"legend":_vm.$t('retailerForm.legend'),"name":"retailer","rules":"required","option-label-prop":"name"},scopedSlots:_vm._u([{key:"selected-option",fn:function(selectedOption){return [_c('span',[_vm._v(_vm._s(selectedOption.name))]),(selectedOption.orgNumber)?_c('span',[_vm._v(" - "+_vm._s(selectedOption.orgNumber)+" ")]):_vm._e()]}},{key:"option",fn:function(ref){
var option = ref.option;
var preSearchMatch = ref.preSearchMatch;
var searchMatch = ref.searchMatch;
var postSearchMatch = ref.postSearchMatch;
return [_c('span',[_vm._v(_vm._s(preSearchMatch))]),_c('span',{staticClass:"retailer-form__select__search-match"},[_vm._v(_vm._s(searchMatch))]),_c('span',[_vm._v(_vm._s(postSearchMatch))]),(option.orgNumber)?_c('span',[_vm._v(" - "+_vm._s(option.orgNumber)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.model.retailerId),callback:function ($$v) {_vm.$set(_vm.model, "retailerId", $$v)},expression:"model.retailerId"}}),_vm._t("default"),_c('DefaBaseButton',{attrs:{"loading":_vm.loading,"disabled":_vm.isSubmitDisabled}},[_vm._v(" "+_vm._s(_vm.labelSubmit)+" ")])],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }