import i18n from "@/i18n";
import { userModule } from "@/store/modules/user";
import type {
  AssignmentConfirmation,
  AssignmentFile,
  AssignmentInspection,
  AssignmentInstallation,
  AssignmentRejection,
} from "@defa-as/utils";
import { durationToHoursAndMinutes, formatDatetimeShort } from "@defa-as/utils";

export const payloadForAssignmentRejected = (
  rejection: AssignmentRejection
) => [
  {
    value: i18n.t(
      `progress.installationPartner.assignment.payloadLabels.rejection.reason.${rejection.reason}`
    ),
  },
  ...(rejection.comment && userModule.isAdmin
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.rejection.comment",
            { comment: rejection.comment }
          ),
        },
      ]
    : []),
];

/**
 * This does say assignmentConfirmed, even though timestamps checked for are 'inspectionRequired|inspectionSkipped'
 * That was a mistake however, and should be remedied in https://github.com/defa-as/backend/issues/4
 */
export const payloadForAssignmentConfirmed = (
  confirmation: AssignmentConfirmation
) => [
  {
    value: i18n.t(
      "progress.installationPartner.assignment.payloadLabels.confirmation.customerContacted"
    ),
  },
  {
    value: i18n.t(
      `progress.installationPartner.assignment.payloadLabels.confirmation.correctWallbox.${confirmation.correctWallbox}`
    ),
  },
  ...(confirmation.additionalServicesRequired
    ? [
        {
          value: i18n.t(
            `progress.installationPartner.assignment.payloadLabels.confirmation.additionalServicesRequired.${confirmation.additionalServicesRequired}`
          ),
        },
      ]
    : []),
  ...(confirmation.installationPossible
    ? [
        {
          value: i18n.t(
            `progress.installationPartner.assignment.payloadLabels.confirmation.installationPossible.${confirmation.installationPossible}`
          ),
        },
      ]
    : []),
  ...(confirmation.comment && userModule.isAdmin
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.confirmation.comment",
            { comment: confirmation.comment }
          ),
        },
      ]
    : []),
];

export const payloadForInspectionScheduled = (
  inspection: AssignmentInspection
) =>
  inspection.scheduled
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.inspection.scheduled",
            { date: formatDatetimeShort(inspection.scheduled) }
          ),
        },
      ]
    : [];

export const payloadForInspectionFailed = (
  inspection: AssignmentInspection
) => [
  {
    value: i18n.t(
      `progress.installationPartner.assignment.payloadLabels.inspectionFailed.reasonFailed.${inspection.reasonFailed}`
    ),
  },
  // Comment is required to be filled out in IPV
  ...(userModule.isAdmin
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.inspectionFailed.comment",
            { comment: inspection.comment }
          ),
        },
      ]
    : []),
];

export const payloadForInstallationScheduled = (
  installation: AssignmentInstallation
) =>
  installation.scheduled
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.installation.scheduled",
            { date: formatDatetimeShort(installation.scheduled) }
          ),
        },
      ]
    : [];

export const payloadForReportCompletedInstallationSuccessful = (
  installation: AssignmentInstallation
) => [
  ...(installation.completed
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.installation.completed",
            {
              date: formatDatetimeShort(installation.completed),
            }
          ),
        },
      ]
    : []),
  ...(installation.workingTimeInMinutes && userModule.isAdmin
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.installation.workingTime",
            {
              duration: i18n.t("shared.formats.durationHoursAndMinutes", {
                ...durationToHoursAndMinutes(installation.workingTimeInMinutes),
              }),
            }
          ),
        },
      ]
    : []),
  ...(installation.serialNumbers &&
  Object.values(installation.serialNumbers).filter(Boolean).length
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.installation.serialNumbers",
            {
              serialNumbers: Object.values(installation.serialNumbers)
                .filter(Boolean)
                .join(", "),
            }
          ),
        },
      ]
    : []),
  ...(installation.files?.length
    ? [
        {
          value: (installation.files[0] as AssignmentFile).filename as string,
          link: (installation.files[0] as AssignmentFile).url as string,
        },
      ]
    : []),
];

export const payloadForReportCompletedInstallationFailed = (
  installation: AssignmentInstallation
) => [
  {
    value: i18n.t(
      `progress.installationPartner.assignment.payloadLabels.installation.reasonFailed.${installation.reasonFailed}`
    ),
  },
  // Comment is required to be filled out in IPV
  ...(userModule.isAdmin
    ? [
        {
          value: i18n.t(
            "progress.installationPartner.assignment.payloadLabels.installation.comment",
            { comment: installation.comment }
          ),
        },
      ]
    : []),
];
