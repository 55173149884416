import { render, staticRenderFns } from "./TabProgress.vue?vue&type=template&id=44854216&scoped=true&"
import script from "./TabProgress.vue?vue&type=script&lang=ts&"
export * from "./TabProgress.vue?vue&type=script&lang=ts&"
import style0 from "./TabProgress.vue?vue&type=style&index=0&id=44854216&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44854216",
  null
  
)

export default component.exports