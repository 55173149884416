import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";
import { isOrderCreationRoute } from "@/router/router-utils";
import { submitOrderModule } from "@/store/modules/submit-order";
import { Nullable } from "@defa-as/utils";

@Component
export default class UnsavedChangesModalMixin extends Vue {
  leavingModalShown = false;
  leaveRouteFn: Nullable<NavigationGuardNext> = null;
  postLeaveFn: Nullable<() => Promise<void> | void> = null;

  hideModal() {
    this.leavingModalShown = false;
  }

  onLeaveOrderCreation(leaveRouteFn: NavigationGuardNext, to: Route) {
    if (isOrderCreationRoute(to) || !submitOrderModule.hasStartedOrderProcess) {
      return leaveRouteFn();
    }
    this.onLeave(leaveRouteFn, {
      postLeaveFn: submitOrderModule.resetModel,
    });
  }

  onLeave(
    leaveRouteFn: NavigationGuardNext,
    config?: {
      postLeaveFn: () => Promise<void> | void;
    }
  ) {
    this.leavingModalShown = true;
    this.leaveRouteFn = leaveRouteFn;
    if (config) {
      this.postLeaveFn = config.postLeaveFn;
    }
  }

  onCancelLeave() {
    this.leavingModalShown = false;
  }

  async onConfirmLeave() {
    if (this.leaveRouteFn) {
      await this.leaveRouteFn();
    }
    if (this.postLeaveFn) {
      await this.postLeaveFn();
    }
  }
}
