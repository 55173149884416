






















import { Component, Prop, Vue } from "vue-property-decorator";
import { inNorway } from "@defa-as/utils";
import { DefaBaseButton } from "@defa-as/components";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    DefaBaseButton,
  },
})
export default class OrderHeaderActions extends Vue {
  @Prop({ required: true }) readonly saveDisabled!: boolean;
  @Prop({ required: true }) readonly loadingSave!: boolean;
  @Prop({ required: true }) readonly loadingComplete!: boolean;

  get userModule() {
    return userModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get completeAssignmentButtonVisible() {
    return (
      inNorway() && this.userModule.isAdmin && this.activeOrderModule.isNotDone
    );
  }
}
