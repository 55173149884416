





















import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaIconEthernet,
  DefaIconMobile,
  DefaIconWifi,
} from "@defa-as/components";
import type { Solution } from "@/store/types/submit-order";

@Component({
  components: {
    DefaIconEthernet,
    DefaIconWifi,
    DefaIconMobile,
  },
})
export default class BaseCardConnectionIconsSolution extends Vue {
  @Prop({ required: true }) readonly solution!: Solution;
}
