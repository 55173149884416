import { render, staticRenderFns } from "./ProfileHeader.vue?vue&type=template&id=06b7a6e8&scoped=true&"
import script from "./ProfileHeader.vue?vue&type=script&lang=ts&"
export * from "./ProfileHeader.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileHeader.vue?vue&type=style&index=0&id=06b7a6e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b7a6e8",
  null
  
)

export default component.exports