import axios, { AxiosAdapter, AxiosPromise } from "axios";
import LRUCache from "lru-cache";
import {
  cacheAdapterEnhancer,
  ICacheLike,
  throttleAdapterEnhancer,
} from "axios-extensions";
import { RecordedCache } from "axios-extensions/esm/throttleAdapterEnhancer";
import {
  applyRequestInterceptorsInOrder,
  defaultTimeout,
  getApiBaseUrl,
  requestInterceptorAddCustomHeaders,
  responseInterceptorExtractData,
  withAxiosRetry,
} from "@defa-as/utils";
import {
  requestInterceptorAddAuthorizationHeader,
  responseInterceptorReportError,
} from "@/http/interceptors";

const TEN_SECONDS_MS = 10 * 1000;
const FIVE_MINUTES_MS = 5 * 60 * 1000;
const TWELVE_HOURS_MS = 12 * 60 * 60 * 1000;

// Mimics default cache used in https://github.com/kuitos/axios-extensions#cacheadapterenhancer
const cache = new LRUCache({ maxAge: FIVE_MINUTES_MS, max: 100 });
const throttleCache = new LRUCache({ maxAge: FIVE_MINUTES_MS, max: 10 });
// Cache for /cars requests
export const longTermCache = new LRUCache({
  maxAge: TWELVE_HOURS_MS,
  max: 100,
});

export const httpClient = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    "Cache-Control": "no-cache",
  },
  timeout: defaultTimeout,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: true,
      defaultCache: cache as ICacheLike<AxiosPromise>,
    }),
    {
      threshold: TEN_SECONDS_MS,
      cache: throttleCache as ICacheLike<RecordedCache>,
    }
  ),
});

withAxiosRetry(httpClient);

applyRequestInterceptorsInOrder({
  axiosInstance: httpClient,
  interceptors: [
    requestInterceptorAddAuthorizationHeader,
    requestInterceptorAddCustomHeaders,
  ],
});
httpClient.interceptors.response.use(
  responseInterceptorExtractData,
  responseInterceptorReportError
);

export const resetCaches = () => {
  [cache, throttleCache, longTermCache].forEach((cache) => cache.reset());
};
export const resetThrottleCache = () => throttleCache.reset();
