


































































import { Component, Vue } from "vue-property-decorator";
import { DefaBaseInput, DefaIconCar } from "@defa-as/components";
import type { Order } from "@defa-as/utils";
import { formatDate } from "@defa-as/utils";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";
import { homecheckModule } from "@/store/modules/homecheck";
import BaseTextExpandable from "@/components/BaseTextExpandable.vue";
import InputTelephone from "@/components/forms/InputTelephone.vue";
import InputFullName from "@/components/forms/InputFullName.vue";

@Component({
  components: {
    DefaBaseInput,
    InputFullName,
    InputTelephone,
    DefaIconCar,
    BaseTextExpandable,
  },
})
export default class OrderContentCustomer extends Vue {
  get homecheckModule() {
    return homecheckModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get userModule() {
    return userModule;
  }

  get customerCarDeliveryDate() {
    if (this.activeOrderModule.activeOrder.customerCarDeliveryDate) {
      return formatDate(
        this.activeOrderModule.activeOrder.customerCarDeliveryDate.date
      );
    }
    return null;
  }

  onActiveOrderInput(key: keyof Order, value: string) {
    this.activeOrderModule.ACTIVE_ORDER_SET_FIELD({ key, value });
  }
}
