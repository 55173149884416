














import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import { Homecheck, validationConfigForFields } from "@defa-as/utils";

@Component({
  inheritAttrs: true,
  components: {
    DefaBaseInput,
  },
})
export default class HomecheckQuestionInput extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ required: true }) readonly fieldName!: Exclude<
    keyof Homecheck,
    | "comment"
    | "distributionBoardImages"
    | "distributionBoardSchemaImages"
    | "electricityMeterBoxImages"
    | "installationSiteImages"
  >;

  get rulesForQuestion() {
    return validationConfigForFields.homecheck[this.fieldName];
  }

  get inputLabel() {
    return this.$t(`shared.homecheckOption.label.${this.fieldName}`);
  }

  get valueForInput() {
    return this.value;
  }

  set valueForInput(value: string) {
    this.$emit("input", value);
  }
}
