




































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import type { Homecheck } from "@defa-as/utils";
import {
  homecheckOptions,
  inDenmark,
  inNorway,
  inSweden,
} from "@defa-as/utils";
import {
  DefaBaseButton,
  DefaBaseTextarea,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import HomecheckImages from "@//components/order/homecheck/image/HomecheckImages.vue";
import HomecheckQuestionSelect from "@/components/order/homecheck/questions/HomecheckQuestionSelect.vue";
import HomecheckQuestionInput from "@/components/order/homecheck/questions/HomecheckQuestionInput.vue";

@Component({
  components: {
    HomecheckQuestionInput,
    HomecheckImages,
    DefaTransitionFadeOutIn,
    HomecheckQuestionSelect,
    DefaBaseTextarea,
    DefaBaseButton,
  },
})
export default class HomecheckQuestions extends Vue {
  @Prop({ required: true }) readonly admin!: boolean;
  @Prop({ required: true }) readonly homecheck!: {
    [key in keyof Homecheck]: string;
  };

  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: false }) readonly readonly!: boolean;
  readonly mainFuseCapacityOptions = homecheckOptions.mainFuseCapacity();
  readonly voltageOptions = homecheckOptions.voltage();
  readonly yesNoUnknownOptions = homecheckOptions.yesNoUnknown();
  readonly cableLengthEstimateOptions = homecheckOptions.cableLengthEstimate();
  readonly internetConnectionOptions = homecheckOptions.internetConnection();
  readonly propertyTypeOptions = homecheckOptions.propertyType();
  readonly propertyOwnerApprovalOptions = homecheckOptions.propertyOwnerApproval();
  readonly yesNoOptions = homecheckOptions.yesNo();
  homecheckLocal = {
    ...this.homecheck,
  };

  get showPropertyOwnerApproval() {
    return this.homecheckLocal.propertyType !== "independentProperty";
  }

  get showEditLink() {
    return this.admin && this.readonly;
  }

  get inNorway() {
    return inNorway();
  }

  get inSweden() {
    return inSweden();
  }

  get inDenmark() {
    return inDenmark();
  }

  onSubmit() {
    this.$emit("submit", { homecheck: this.homecheckLocal });
  }

  onCancelEdit() {
    this.homecheckLocal = {
      ...this.homecheck,
    };
    this.$emit("unset-editable");
  }
}
