import { render, staticRenderFns } from "./ProfileRetailerInfo.vue?vue&type=template&id=47ed8016&scoped=true&"
import script from "./ProfileRetailerInfo.vue?vue&type=script&lang=ts&"
export * from "./ProfileRetailerInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileRetailerInfo.vue?vue&type=style&index=0&id=47ed8016&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ed8016",
  null
  
)

export default component.exports