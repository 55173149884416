import { httpClient } from "@/http";
import type { Assignment, InstallationPartner, Order } from "@defa-as/utils";
import type { SetInstallationPartnerPayload } from "@/store/types/assignment";
import { OrderWithHomecheckAndAssignment } from "@defa-as/utils";

interface FindInstallationPartnerResponse {
  data: InstallationPartner[];
  // eslint-disable-next-line camelcase
  meta: { pagination: { total: number; current_page: number } };
}

export const findInstallationPartnerByZip = async (params: {
  page: number;
  zip?: string;
  search?: string;
}) =>
  httpClient.get<
    FindInstallationPartnerResponse,
    FindInstallationPartnerResponse
  >("/installers", {
    params: {
      zip: params.zip || null,
      search: params.search || null,
      page: params.page,
    },
  });

export const setInstallationPartner = async (
  activeOrderId: Order["id"],
  body: SetInstallationPartnerPayload
) =>
  httpClient.post<Assignment, Assignment>(
    `/orders/${activeOrderId}/assignment`,
    body
  );

export const sendAssignmentToInstallationPartner = async (
  activeOrderId: Order["id"]
) =>
  httpClient.post<Assignment, Assignment>(
    `/orders/${activeOrderId}/assignment/send`
  );

export const cancelAssignment = async (
  activeOrderId: Order["id"],
  params: { message: string }
) => {
  const urlSearchParams = new URLSearchParams();
  if (params.message) {
    urlSearchParams.set("message", params.message);
  }
  return httpClient.delete<Assignment, Assignment>(
    `/orders/${activeOrderId}/assignment`,
    {
      params: urlSearchParams,
    }
  );
};

export const completeAssignment = async (orderId: Order["id"]) =>
  await httpClient.post<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}/done`);
