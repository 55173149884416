


































import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import ProgressItem from "@/components/order/progress/ProgressItem.vue";
import InstallationPartnerCancellationModal from "@/components/order/progress/installation-partner/InstallationPartnerCancellationModal.vue";
import ProgressTabInstallationPartnerButtonEmail from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerButtonEmail.vue";
import ModalMixin from "@/mixins/modal.mixin";
import { assignmentModule } from "@/store/modules/assignment";
import { progressModule } from "@/store/modules/progress";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    DefaBaseButton,
    ProgressItem,
    InstallationPartnerCancellationModal,
    ProgressTabInstallationPartnerButtonEmail,
  },
})
export default class ProgressTabInstallationPartnerProgress extends Mixins(
  ModalMixin
) {
  readonly setModalLoading!: () => void;
  readonly hideModal!: () => void;
  readonly unsetModalLoading!: () => void;

  get assignmentModule() {
    return assignmentModule;
  }

  get progressModule() {
    return progressModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get userModule() {
    return userModule;
  }

  get isInstallationPartnerButtonVisible() {
    return userModule.isAdmin && assignmentModule.hasInstallationPartner;
  }

  get isCancellationLinkVisible() {
    return (
      this.userModule.isAdmin &&
      this.activeOrderModule.isNotCancelled &&
      this.assignmentModule.hasInstallationPartner
    );
  }

  async onConfirmCancellation(messagePayload: { message: string }) {
    this.setModalLoading();
    try {
      await this.assignmentModule.cancelInstallationPartner(messagePayload);
    } finally {
      this.hideModal();
      this.unsetModalLoading();
    }
  }
}
