import {
  API_VERSION_JULY_2021,
  findRouteByName,
  Retailer,
  User,
} from "@defa-as/utils";
import { AxiosRequestConfig } from "axios";
import { httpClient } from "@/http";
import {
  getRetailerGroupHandleForDomain,
  isRetailerGroupForDomainPublic,
} from "@/config/portals/retailer-groups";
import { ROUTE_NAMES } from "@/router/route-names";
import { routes } from "@/router";

interface GDPRDocumentGenerationResponse {
  documentUrl: string;
}

export const getUserInfo = async <T extends User = User>(
  userId = "me",
  includePublicRgInfo = false
): Promise<T> => {
  const endpoint = includePublicRgInfo
    ? `/admin/users/${userId}`
    : `/users/${userId}`;
  const config: AxiosRequestConfig = {
    ...(includePublicRgInfo
      ? {
          params: {
            includePublicRgInfo: true,
          },
        }
      : null),
    headers: {
      version: API_VERSION_JULY_2021,
      "X-Defa-Version": API_VERSION_JULY_2021,
    },
  };
  return httpClient.get<T, T>(endpoint, config);
};

export const login = async (body: { email: string; password: string }) =>
  httpClient.post<User, User>(
    "/users/login",
    {
      loginName: body.email,
      password: body.password,
      activeRetailerGroupHandle: getRetailerGroupHandleForDomain(),
      context: {
        app: "EP",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const register = async (
  body: Pick<User, "email" | "fullName" | "telephoneNumber"> & {
    password: string;
    retailerNameInput?: string;
    retailerOrgNumberInput?: string;
  }
) =>
  httpClient.post(
    "/users",
    {
      email: body.email,
      password: body.password,
      telephoneNumber: body.telephoneNumber,
      fullName: body.fullName,
      group: getRetailerGroupHandleForDomain(),
      confirmationUrl: `${window.location.origin}${
        findRouteByName(routes, ROUTE_NAMES.REGISTER.CONFIRM).path
      }`,
      context: {
        app: "EP",
      },
      ...(isRetailerGroupForDomainPublic()
        ? {
            publicRg: {
              retailerNameInput: body.retailerNameInput,
              retailerOrgNumberInput: body.retailerOrgNumberInput,
            },
          }
        : null),
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
export const updateUser = async (body: Record<string, string>) =>
  httpClient.patch<User, User>("/users/me", body, {
    headers: {
      version: API_VERSION_JULY_2021,
      "X-Defa-Version": API_VERSION_JULY_2021,
    },
  });

export const activateRetailer = async (retailerId: Retailer["id"] | null) =>
  httpClient.post<User, User>(
    "/users/me/retailer/activate",
    {
      retailerId,
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const deactivateRetailer = async () => activateRetailer(null);

export const linkRetailer = async (retailerId: Retailer["id"]) =>
  httpClient.post<User, User>(
    "/users/me/retailer/link",
    {
      // Add retailer to existing retailers for different retailer groups, but don't add to current retailer group
      retailerId,
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const linkRetailerForPublicRg = async (
  userId: string,
  body: { adminComment: string; retailerId: string }
): Promise<User> =>
  httpClient.post<User, User>(
    `/admin/users/${userId}/link-retailer-public-rg`,
    body,
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const denyRetailerPublicForPublicRg = async (
  userId: string,
  body: {
    adminComment: string;
  }
): Promise<User> =>
  httpClient.post<User, User>(
    `/admin/users/${userId}/deny-retailer-public-rg`,
    body,
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const generateGDPRDocument = async () =>
  httpClient.post<
    GDPRDocumentGenerationResponse,
    GDPRDocumentGenerationResponse
  >("/users/me/gdpr-document/generate", undefined, {
    headers: {
      version: API_VERSION_JULY_2021,
      "X-Defa-Version": API_VERSION_JULY_2021,
    },
  });

export const signGDPRDocument = async () =>
  httpClient.post<User, User>("/users/me/gdpr-document/sign", undefined, {
    headers: {
      version: API_VERSION_JULY_2021,
      "X-Defa-Version": API_VERSION_JULY_2021,
    },
  });

export const requestPasswordReset = async (body: {
  email: string;
  recoverPasswordConfirmPath: string;
}) =>
  httpClient.post(
    "/users/reset/request",
    {
      email: body.email,
      recoverUrl: `${window.location.origin}${body.recoverPasswordConfirmPath}`,
      context: {
        app: "EP",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );

export const resetPassword = async (hash: string, body: { password: string }) =>
  httpClient.post(
    `/users/reset/change-password/${hash}`,
    {
      newPassword: body.password,
      context: {
        app: "EP",
      },
    },
    {
      headers: {
        version: API_VERSION_JULY_2021,
        "X-Defa-Version": API_VERSION_JULY_2021,
      },
    }
  );
