










































import { Component, Vue } from "vue-property-decorator";
import { pipelineFiltersModule } from "@/store/modules/pipeline-filters";
import OrderListPipelineFilterItem from "@/components/order-list/OrderListPipelineFilterItem.vue";

@Component({
  components: {
    OrderListPipelineFilterItem,
  },
})
export default class OrderListPipelineFilters extends Vue {
  get pipelineFiltersModule() {
    return pipelineFiltersModule;
  }
}
