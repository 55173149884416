























import { Component, Vue } from "vue-property-decorator";
import { DefaBlurUpImage, DefaImageCarousel } from "@defa-as/components";
import type { HomecheckImage, Nullable } from "@defa-as/utils";
import { homecheckModule } from "@/store/modules/homecheck";

@Component({
  components: {
    DefaImageCarousel,
    DefaBlurUpImage,
  },
})
export default class HomecheckImages extends Vue {
  image: Nullable<HomecheckImage> = null;

  get homecheckModule() {
    return homecheckModule;
  }

  onClickImage({ image }: { image: HomecheckImage }) {
    this.image = image;
  }

  onImageCarouselHide() {
    this.image = null;
  }
}
