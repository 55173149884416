






































import { Component, Mixins } from "vue-property-decorator";
import { inSweden } from "@defa-as/utils";
import {
  DefaBaseCheckbox,
  DefaBaseDatetimeInput,
  DefaBaseLinkExternal,
} from "@defa-as/components";
import { futureButNoMoreThan9Months } from "@/validation/custom-rules";
import { validationConfigForFields } from "@/validation";
import OrderSubmissionSection from "@/mixins/order-submission-section";

@Component({
  components: {
    DefaBaseDatetimeInput,
    DefaBaseCheckbox,
    DefaBaseLinkExternal,
  },
})
export default class OrderSubmissionCustomerExtra extends Mixins(
  OrderSubmissionSection
) {
  get inSweden() {
    return inSweden();
  }

  get customerCarDeliveryDate() {
    return this.submitOrderModule.customerCarDeliveryDate as Date;
  }

  set customerCarDeliveryDate(value: Date) {
    this.onCustomerInput("carDeliveryDate", value.toJSON());
  }

  get customerCarDeliveryDateRules() {
    return validationConfigForFields.customerCarDeliveryDate.rules;
  }

  get futureButNoMoreThan9Months() {
    return (value: Date) => !futureButNoMoreThan9Months(value);
  }
}
