























import { Component, Prop, Vue } from "vue-property-decorator";
import { uniq } from "lodash-es";
import BaseCardDetailsModalSolution from "@/components/BaseCardServicesIncludedModal.vue";
import type { CarModel, Solution } from "@/store/types/submit-order";

@Component({
  components: {
    BaseCardDetailsModalSolution,
  },
})
export default class BaseCardExtraDetailsSolution extends Vue {
  @Prop({ required: true }) readonly solution!: Solution;
  @Prop({ default: null }) readonly carModel!: CarModel;
  servicesIncludedModalShown = false;

  get hoursToChargeCar() {
    // If 2 cars are charging simultaneously, the max effect a car can get is half of the solution (or its max battery capacity, whichever is less)
    if (this.solution.connectorCount > 1) {
      const effectMaxPerHour = Math.min(
        this.carModel.maxEffect,
        this.solution.effect
      );
      const effectMinPerHour = Math.min(
        this.carModel.maxEffect,
        this.solution.effect / this.solution.connectorCount
      );
      return uniq([
        Math.ceil(this.carModel.batteryCapacity / effectMaxPerHour),
        Math.ceil(this.carModel.batteryCapacity / effectMinPerHour),
      ]);
    }
    const effect = Math.min(this.carModel.maxEffect, this.solution.effect);
    return [Math.ceil(this.carModel.batteryCapacity / effect)];
  }

  get hoursDisplay() {
    return (hours: number) =>
      hours <= 12 ? this.$t(`numbers.${hours}`) : hours.toString();
  }

  get chargingTime() {
    if (this.carModel) {
      if (this.hoursToChargeCar.length === 1) {
        return this.$tc(
          "baseCardExtraDetailsSolution.details.chargingSpeed",
          this.hoursToChargeCar[0],
          {
            model: this.carModel.name,
            hours: this.hoursDisplay(this.hoursToChargeCar[0]),
          }
        );
      }
      return this.$t(
        "baseCardExtraDetailsSolution.details.chargingSpeedRange",
        {
          model: this.carModel.name,
          hoursMin: this.hoursDisplay(this.hoursToChargeCar[0]),
          hoursMax: this.hoursDisplay(this.hoursToChargeCar[1]),
        }
      );
    }
    return null;
  }

  showServicesIncludedModal() {
    this.servicesIncludedModalShown = true;
  }

  hideServicesIncludedModal() {
    this.servicesIncludedModalShown = false;
  }
}
