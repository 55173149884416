






















import { Component, Vue } from "vue-property-decorator";
import OrderLines from "@/components/OrderLines.vue";
import { submitOrderModule } from "@/store/modules/submit-order";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    OrderLines,
  },
})
export default class OrderSubmissionSummary extends Vue {
  get solutionsRoute() {
    return {
      name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    };
  }

  get userModule() {
    return userModule;
  }

  get submitOrderModule() {
    return submitOrderModule;
  }
}
