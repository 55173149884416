















import { Component, Vue } from "vue-property-decorator";
import { onLocalOrTest } from "@defa-as/utils";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";

@Component
export default class OrderTabs extends Vue {
  get availableTabs() {
    let tabs = [
      {
        text: this.$t("orderTabs.progress"),
        route: {
          name: ROUTE_NAMES.ORDERS.DETAILS.PROGRESS,
        },
      },
    ];
    if (userModule.isAdmin) {
      tabs = [
        ...tabs,
        {
          text: this.$t("orderTabs.homecheck"),
          route: {
            name: ROUTE_NAMES.ORDERS.DETAILS.HOMECHECK,
          },
        },
        {
          text: this.$t("orderTabs.commentsInstallation"),
          route: {
            name: ROUTE_NAMES.ORDERS.DETAILS.COMMENTS_INSTALLATION,
          },
        },
      ];
      if (onLocalOrTest()) {
        tabs = [
          ...tabs,
          {
            text: this.$t("orderTabs.changelog"),
            route: {
              name: ROUTE_NAMES.ORDERS.DETAILS.CHANGELOG,
            },
          },
        ];
      }
    }
    return tabs;
  }
}
