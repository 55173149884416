






























import { Component, Prop, Vue } from "vue-property-decorator";

interface Option {
  value: string;
  label: string;
}

@Component
export default class HomecheckOptions extends Vue {
  @Prop({ required: true }) readonly name!: string;
  @Prop({ required: true }) readonly value!: string;
  @Prop({ required: true }) readonly options!: Option[];
  @Prop({ default: false }) readonly invalid!: boolean;

  get inputId() {
    return (option: Option) => `${this.name}__${option.value}`;
  }

  get isChecked() {
    return (option: Option) => this.value === option.value;
  }

  onChange(option: Option) {
    this.$emit("change", option.value);
  }
}
