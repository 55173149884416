import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { inSweden } from "@defa-as/utils";
import type { Nullable } from "@defa-as/utils";
import { add } from "date-fns";
import type {
  AddonProductsPerType,
  Customer,
  Product,
  ProductWithQuantityOne,
  Solution,
  SubmitOrder,
} from "@/store/types/submit-order";
import store from "@/store";
import { createOrder } from "@/http/requests/requests-order";

const initialState = (): SubmitOrder => ({
  solution: null,
  addonProducts: [],
  orderNotes: "",
  retailerOrderRef: "",
  customer: {
    fullName: "",
    email: "",
    emailConfirm: "",
    phone: "",
    addressLine1: "",
    place: "",
    postCode: "",
    approvedByHouseOwner: "",
    carBrand: null,
    carModel: null,
    carDeliveryDate: "",
    ...(inSweden() && { grantRequested: false }),
  },
});

@Module({
  name: "submitOrder",
  store,
  dynamic: true,
  preserveState: Boolean(store.state.submitOrder),
  namespaced: true,
})
export class SubmitOrderModule extends VuexModule {
  model = initialState();

  get retailerOrderRef() {
    return this.model.retailerOrderRef;
  }

  get orderNotes() {
    return this.model.orderNotes;
  }

  get addonProducts() {
    return this.model.addonProducts;
  }

  get solution() {
    return this.model.solution;
  }

  get customer() {
    return this.model.customer;
  }

  get customerCarBrandSlug() {
    return this.customer.carBrand?.slug;
  }

  get customerCarBrandName() {
    return this.customer.carBrand?.name;
  }

  get customerCarModel() {
    return this.customer.carModel;
  }

  get customerCarModelConnector() {
    return this.customer.carModel?.connector;
  }

  get customerCarModelSlug() {
    return this.customer.carModel?.slug;
  }

  get customerCarDeliveryDate() {
    return this.customer.carDeliveryDate
      ? new Date(this.customer.carDeliveryDate)
      : null;
  }

  get customerCarDeliveryDateWith12HoursAdded() {
    return this.customerCarDeliveryDate
      ? add(this.customerCarDeliveryDate, { hours: 12 })
      : null;
  }

  get orderLines(): ProductWithQuantityOne[] {
    if (this.solution) {
      const chargerWithQuantityOne: ProductWithQuantityOne = {
        ...this.solution.chargingStation,
        quantity: 1,
      };
      const addonProductsWithQuantity: ProductWithQuantityOne[] = this.addonProducts
        .filter((product) => Boolean(product.axNumber))
        .map((product) => ({
          ...product,
          quantity: 1,
        }));
      return [chargerWithQuantityOne, ...addonProductsWithQuantity];
    }
    return [];
  }

  get addonProductsForCurrentSolution() {
    if (this.solution) {
      if (this.customerCarModelConnector) {
        const connector = this.customerCarModelConnector
          .toLowerCase()
          .replace(/ /g, "") as keyof AddonProductsPerType;
        return this.solution.addonProducts?.[connector] || [];
      }
      return Object.values(this.solution.addonProducts).reduce<Product[]>(
        (allAddons, newAddons: Product[]) => {
          const nonDuplicateAddons = newAddons.filter(
            (newAddon) =>
              allAddons.findIndex((addon) => addon.id === newAddon.id) === -1
          );
          return [...allAddons, ...nonDuplicateAddons];
        },
        []
      );
    }
    return [];
  }

  get hasAddonProductsForCurrentSolution() {
    return Boolean(this.addonProductsForCurrentSolution.length);
  }

  get hasAddon() {
    return (id: string) => this.addonProducts.some((addon) => addon.id === id);
  }

  get hasStartedOrderProcess() {
    return this.hasChosenCarBrand || this.hasChosenSolution;
  }

  get hasChosenCarBrand() {
    return Boolean(this.customer.carBrand?.name);
  }

  get hasChosenSolution() {
    return Boolean(this.solution);
  }

  @Mutation
  SUBMIT_ORDER_SET_SOLUTION(solution: Nullable<Solution>) {
    this.model.solution = solution;
  }

  @Mutation
  SUBMIT_ORDER_SET_CUSTOMER_FIELD<K extends keyof Customer>({
    key,
    value,
  }: {
    key: K;
    value: Customer[K];
  }) {
    this.model.customer[key] = value;
  }

  @Mutation
  SUBMIT_ORDER_SET_ORDER_NOTES({ orderNotes }: { orderNotes: string }) {
    this.model.orderNotes = orderNotes;
  }

  @Mutation
  SUBMIT_ORDER_SET_RETAILER_ORDER_REF({
    retailerOrderRef,
  }: {
    retailerOrderRef: string;
  }) {
    this.model.retailerOrderRef = retailerOrderRef;
  }

  @Mutation
  SUBMIT_ORDER_SET_ADDON_PRODUCTS(addonProducts: Product[]) {
    this.model.addonProducts = addonProducts;
  }

  @Mutation
  SUBMIT_ORDER_SET_MODEL(model: SubmitOrder) {
    this.model = model;
  }

  @Action
  async resetModel() {
    this.SUBMIT_ORDER_SET_MODEL(initialState());
  }

  @Action
  async addAddon({ addon }: { addon: Product }) {
    const updatedAddons = [...this.model.addonProducts, addon];
    this.SUBMIT_ORDER_SET_ADDON_PRODUCTS(updatedAddons);
  }

  @Action
  async removeAddon({ addon }: { addon: Product }) {
    const updatedAddons = this.model.addonProducts.filter(
      (addonProduct) => addonProduct.id !== addon.id
    );
    this.SUBMIT_ORDER_SET_ADDON_PRODUCTS(updatedAddons);
  }

  @Action
  async clearAddons() {
    this.SUBMIT_ORDER_SET_ADDON_PRODUCTS([]);
  }

  @Action
  async clearSolution() {
    this.SUBMIT_ORDER_SET_SOLUTION(null);
  }

  @Action
  async createOrder() {
    const requestBody = {
      customerFullName: this.customer.fullName,
      customerEmail: this.customer.email,
      customerAddressLine1: this.customer.addressLine1,
      customerPostcode: this.customer.postCode,
      customerPlace: this.customer.place,
      customerPhone: this.customer.phone,
      retailerOrderRef: this.retailerOrderRef,
      orderNotes: this.orderNotes,
      approvedByHouseOwner: this.customer.approvedByHouseOwner,
      // TODO: This is not necessary anymore after https://github.com/defa-as/backend/issues/58
      customerCarDeliveryDate: this.customerCarDeliveryDateWith12HoursAdded,
      customerCarBrand: this.customer.carBrand?.name,
      customerCarModel: this.customer.carModel?.name,
      ...(inSweden() && {
        customerGrantRequested: this.customer.grantRequested,
      }),
      orderLines: this.orderLines,
    };
    const order = await createOrder(requestBody);
    await this.resetModel();
    return order;
  }

  @Action
  async clearModelAndBrand() {
    this.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key: "carBrand",
      value: null,
    });
    this.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key: "carModel",
      value: null,
    });
  }

  @Action
  async removeUnavailableAddons({
    availableAddons,
  }: {
    availableAddons: Product[];
  }) {
    this.addonProducts.forEach((selectedAddon) => {
      if (
        !availableAddons.some(
          (availableAddon) => availableAddon.id === selectedAddon.id
        )
      ) {
        this.removeAddon({ addon: selectedAddon });
      }
    });
  }
}

export const submitOrderModule = getModule(SubmitOrderModule);
