import { AxiosError, AxiosRequestConfig } from "axios";
import {
  captureExceptionWithSentry,
  setAuthorizationHeaderOnConfig,
  shouldErrorBeReported,
  userFriendlyErrorMessage,
} from "@defa-as/utils";
import { errorModule } from "@/store/modules/error";
import { userModule } from "@/store/modules/user";

export const requestInterceptorAddAuthorizationHeader = (
  config: AxiosRequestConfig
) => setAuthorizationHeaderOnConfig(config, userModule.token);

export const responseInterceptorReportError = async (error: AxiosError) => {
  if (shouldErrorBeReported(error)) {
    await errorModule.setMessageKey({
      messageKey: userFriendlyErrorMessage(error),
    });
    await captureExceptionWithSentry(error);
  }
  throw error;
};
