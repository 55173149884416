



















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseSelect } from "@defa-as/components";

@Component({
  components: {
    DefaBaseSelect,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
  inheritAttrs: false,
})
export default class BaseSelect extends Vue {
  @Prop({ default: "" }) readonly legend!: string;
  @Prop({ default: "label" }) readonly optionLabelProp!: string;
}
