



































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseTextarea,
  DefaModal,
} from "@defa-as/components";
import { assignmentModule } from "@/store/modules/assignment";

@Component({
  components: {
    DefaBaseButton,
    DefaModal,
    DefaBaseTextarea,
  },
})
export default class InstallationPartnerCancellationModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: false }) readonly loading!: boolean;
  message = "";

  get assignmentModule() {
    return assignmentModule;
  }

  onClickConfirm() {
    this.$emit("confirm", { message: this.message });
  }
}
