


















import { Component, Vue } from "vue-property-decorator";

@Component
export default class BaseInfo extends Vue {
  hidden = true;

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }
}
