








import { Component, Vue } from "vue-property-decorator";
import HomecheckNotSentStage from "@/components/order/homecheck/stages/HomecheckNotSentStage.vue";
import HomecheckSentStage from "@/components/order/homecheck/stages/HomecheckSentStage.vue";
import HomecheckCompletedStage from "@/components/order/homecheck/stages/HomecheckCompletedStage.vue";
import { activeOrderModule } from "@/store/modules/active-order";

@Component({
  components: {
    HomecheckNotSentStage,
    HomecheckSentStage,
    HomecheckCompletedStage,
  },
})
export default class TabHomecheck extends Vue {
  get showHomecheckSentStage() {
    return activeOrderModule.activeOrderStateField("remoteHomeCheckSent").state;
  }

  get showHomecheckCompletedStage() {
    return activeOrderModule.activeOrderStateField(
      "remoteHomeCheckCustomerSubmitted"
    ).state;
  }
}
