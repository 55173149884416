






















import { Component, Vue } from "vue-property-decorator";
import {
  getRetailerGroupHandleForDomain,
  originForRetailerGroup,
} from "@/config/portals/retailer-groups";
import { getCountry } from "@defa-as/utils";

@Component
export default class NotificationRetailerGroup extends Vue {
  get retailerGroupDomainCurrent() {
    return this.retailerGroupDomainNew.replace(`${getCountry()}.`, "");
  }

  get retailerGroupDomainNew() {
    return originForRetailerGroup(getRetailerGroupHandleForDomain());
  }
}
