
















































import { ValidationObserver } from "vee-validate";
import { Component, Mixins } from "vue-property-decorator";
import { DefaModalConfirmCancel } from "@defa-as/components";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import { submitOrderModule } from "@/store/modules/submit-order";
import OrderSubmissionRetailer from "@/components/order-submission/OrderSubmissionRetailer.vue";
import OrderSubmissionSummary from "@/components/order-submission/OrderSubmissionSummary.vue";
import OrderSubmissionCustomerAddress from "@/components/order-submission/OrderSubmissionCustomerAddress.vue";
import OrderSubmissionCustomerInfo from "@/components/order-submission/OrderSubmissionCustomerInfo.vue";
import OrderSubmissionCustomerExtra from "@/components/order-submission/OrderSubmissionCustomerExtra.vue";
import OrderSubmissionCustomerHouseApproved from "@/components/order-submission/OrderSubmissionCustomerHouseApproved.vue";
import type { NavigationGuardNext, Route } from "vue-router";
import OrderSubmissionSubmit from "@/components/order-submission/OrderSubmissionSubmit.vue";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    DefaModalConfirmCancel,
    OrderSubmissionSubmit,
    OrderSubmissionSummary,
    OrderSubmissionRetailer,
    OrderSubmissionCustomerInfo,
    OrderSubmissionCustomerAddress,
    OrderSubmissionCustomerExtra,
    OrderSubmissionCustomerHouseApproved,
    ValidationObserver,
  },
})
export default class ViewOrderCreationSubmission extends Mixins(
  UnsavedChangesModalMixin
) {
  submitting = false;

  get submitOrderModule() {
    return submitOrderModule;
  }

  async submitOrder() {
    this.submitting = true;
    try {
      const { id } = await this.submitOrderModule.createOrder();
      await this.$router.replace({
        name: ROUTE_NAMES.ORDERS.DETAILS.PROGRESS,
        params: {
          id,
        },
      });
    } finally {
      this.submitting = false;
    }
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    if (to.name !== ROUTE_NAMES.ORDERS.DETAILS.PROGRESS) {
      return this.onLeaveOrderCreation(next, to);
    }
    next();
  }
}
