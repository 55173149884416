export const ROUTE_NAMES = {
  ROOT: "Root",
  ORDERS: {
    LIST: "OrderList",
    DETAILS: {
      PROGRESS: "OrdersDetailProgress",
      HOMECHECK: "OrderDetailsHomecheck",
      CHANGELOG: "OrderDetailsChangelog",
      COMMENTS_INSTALLATION: "OrderDetailsCommentsInstallation",
    },
  },
  RECOVER_PASSWORD: {
    EMAIL: "RecoverPasswordEmail",
    CONFIRM: "RecoverPasswordConfirmation",
  },
  NOT_FOUND: "ViewNotFound",
  PROFILE: "Profile",
  ORDERS_NEW: {
    START: "OrdersNewStart",
    CARS: "OrdersNewCars",
    MODELS: "OrdersNewModels",
    SOLUTIONS: "OrdersNewSolutions",
    SOLUTION_ACCESSORIES: "OrdersNewSolutionAccessories",
    SUBMIT: "OrdersNewSubmit",
  },
  LOGIN: "Login",
  REPORT: "Reports",
  REGISTER: {
    NEW: "RegisterUserNew",
    CONFIRM: "RegisterUserConfirmation",
  },
  CONNECT_RETAILER: "ConnectRetailer",
  CONNECT_RETAILER_FOR_PUBLIC_RG: "ConnectRetailerForPublicRG",
  DENY_USER_FOR_PUBLIC_RG: "DenyUserForPublicRG",
  GDPR_DOCUMENT_CONFIRMATION: "GDPRDocumentConfirmation",
};
