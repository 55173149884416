import { httpClient, longTermCache } from "@/http";
import apollo from "@/http/apollo";
import {
  ApiCarModel,
  CarModel,
  CarBrand,
  Solution,
  ApiEvLogo,
} from "@/store/types/submit-order";
import { gql } from "@apollo/client/core";

/**
 * Retrieves an array of car brands.
 *
 * @async
 * @returns {Promise<CarBrand[]>} - A promise that resolves to an array of CarBrand objects.
 */
export const getCarBrands = async (): Promise<CarBrand[]> => {
  const GET_LOGOS = gql`
    query GetLogos {
      allEvlogos {
        nodes {
          url
          make
          uuid
        }
      }
    }
  `;

  // get data from old and new api in parallel
  const [defaApiResult, uuids] = await Promise.all([
    apollo.query({ query: GET_LOGOS }),
    getRetailerLogoUuids(),
  ]);
  const allEvLogos = defaApiResult.data.allEvlogos.nodes;
  const retailerCarLogos = allEvLogos.filter((l: ApiEvLogo) =>
    uuids.includes(l.uuid)
  );

  return retailerCarLogos.map(
    (l: ApiEvLogo) =>
      ({
        id: l.uuid,
        slug: l.make,
        imageUrl: l.url,
        name: l.make,
      } as CarBrand)
  );
};

/**
 * The relationship between retailer group and car brands is still managed in salto db, so we need this query
 * to filter down the results to a subset of logos for the current retailer group.
 */
const getRetailerLogoUuids = async (): Promise<string[]> => {
  const apiResponse = await httpClient.get("/car-brands", {
    cache: longTermCache,
  });
  const retailerCarBrands: CarBrand[] = apiResponse.data;
  return retailerCarBrands.map((b) => b.logoUuid);
};

/**
 * Retrieves the models of a car brand.
 *
 * @param {string} brandSlug - The slug of the car brand.
 * @returns {Promise<CarModel[]>} - A promise that resolves to an array of CarModel objects.
 */
export const getModels = async (make: string): Promise<CarModel[]> => {
  // incoming brand is a slug from craft, so we need to map it to the brand name used in products.iot.defa
  // const brandName = await getBrandName(brandSlug);

  const GET_MODELS = gql`
    query GetModels($make: String!) {
      allEvs(condition: { make: $make }) {
        nodes {
          year
          dcMaxPowerW
          imageUrl
          model
          acChargePhases
          acMaxPowerW
          acConnectorType
          batteryCapacityWh
          dcConnectorTypes
          evType
          make
          uuid
        }
      }
    }
  `;

  const result = await apollo.query({
    query: GET_MODELS,
    variables: {
      make,
    },
  });

  // map ApiCarModel to CarModel type
  const models = result.data.allEvs.nodes.map(
    (m: ApiCarModel) =>
      ({
        batteryCapacity: m.batteryCapacityWh,
        carEngineType: mapCarEngineType(m.evType),
        carPhase: m.acChargePhases + "-fase",
        connector: m.acConnectorType,
        imageUrl: m.imageUrl,
        maxEffect: m.acMaxPowerW,
        name: m.make + " " + m.model,
        slug: m.model,
        title: m.model,
      } as CarModel)
  );

  return models;
};

/**
 * Retrieves solutions based on the provided car model.
 *
 * @async
 * @param {object} params - The parameters for retrieving solutions.
 * @param {string} params.carModel - The car model to filter solutions by.
 * @returns {Promise<{ data: Solution[] }>} - A promise that resolves to an object containing the retrieved solutions.
 */
export const getSolutions = async (params: { carModel?: string }) => {
  const urlSearchParams = new URLSearchParams();
  if (params.carModel) {
    urlSearchParams.append("carModel", params.carModel);
  }
  return httpClient.get<{ data: Solution[] }, { data: Solution[] }>(
    "/solutions",
    {
      params: urlSearchParams,
    }
  );
};

/**
 * Maps a car engine type to a human-readable string description.
 *
 * @param {("EV"|"PHEV")} type - The car engine type.
 * @return {string} The human-readable description of the car engine type.
 */
function mapCarEngineType(type: "EV" | "PHEV"): string {
  if (type === "EV") {
    return "Elektrisk";
  }

  if (type === "PHEV") {
    return "Plug-in hybrid";
  }

  return type;
}
