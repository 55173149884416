


















import {
  DefaIconHouseOwnerApproved,
  DefaIconHouseOwnerNA,
  DefaIconHouseOwnerRejected,
} from "@defa-as/components";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import HomecheckOptions from "@/components/forms/inputs-generic/HomecheckOptions.vue";

@Component({
  components: {
    HomecheckOptions,
    ValidationProvider,
    DefaIconHouseOwnerApproved,
    DefaIconHouseOwnerRejected,
    DefaIconHouseOwnerNA,
  },
})
export default class HomecheckOptionsWithValidation extends Vue {
  @Prop({ required: true }) readonly value!: {
    value: string;
    label: string;
  };

  get approvedByHouseOwnerOptions() {
    return [
      {
        value: "approved",
        label: this.$t("orderSubmission.houseApproved.approved"),
        icon: DefaIconHouseOwnerApproved,
      },
      {
        value: "rejected",
        label: this.$t("orderSubmission.houseApproved.rejected"),
        icon: DefaIconHouseOwnerRejected,
      },
      {
        value: "n/a",
        label: this.$t("orderSubmission.houseApproved.na"),
        icon: DefaIconHouseOwnerNA,
      },
    ];
  }
}
