
























import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaModal } from "@defa-as/components";

@Component({
  components: {
    DefaBaseButton,
    DefaModal,
  },
})
export default class OrderCancellationModal extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: false }) loading!: boolean;
}
