
































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseOrderLine } from "@defa-as/components";
import type { OrderLine } from "@defa-as/utils";

@Component({
  components: {
    DefaBaseOrderLine,
  },
})
export default class OrderLines extends Vue {
  @Prop({ required: true }) readonly orderLines!: OrderLine[];
  @Prop({ default: "" }) readonly retailerName!: string;

  get vatRate() {
    if (this.orderLines.length) {
      return `${this.orderLines[0]?.vatRate * 100}%`;
    }
    return null;
  }

  get vatTotal() {
    const vat = this.orderLines
      .filter((orderLine) => Boolean(orderLine.vat))
      .reduce((sum, orderLine) => sum + orderLine.vat, 0);
    return Math.round(vat * 100) / 100;
  }

  get totalPrice() {
    const total = this.orderLines
      .filter((orderLine) => Boolean(orderLine.price))
      .reduce((sum, orderLine) => sum + orderLine.price, 0);
    return Math.round(total * 100) / 100;
  }

  get orderLinesGroupedByName() {
    return this.orderLines.reduce((currentOrderLines, orderLine) => {
      const { name } = orderLine;
      if (currentOrderLines[name]) {
        currentOrderLines[name] = [...currentOrderLines[name], orderLine];
      } else {
        currentOrderLines[name] = [orderLine];
      }
      return currentOrderLines;
    }, {} as Record<string, OrderLine[]>);
  }
}
