import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { config } from "vuex-module-decorators";
import {
  clearLocalStorageIfOnNewVersion,
  getVuexLocalStorageKey,
  onLocal,
} from "@defa-as/utils";
import type { SubmitOrderModule } from "@/store/modules/submit-order";
import type { UserModule } from "@/store/modules/user";
import type { ProgressModule } from "@/store/modules/progress";
import type { PipelineFiltersModule } from "@/store/modules/pipeline-filters";
import type { HomecheckModule } from "@/store/modules/homecheck";
import type { ErrorModule } from "@/store/modules/error";
import type { AssignmentModule } from "@/store/modules/assignment";
import type { ActiveOrderModule } from "@/store/modules/active-order";
import type { ResetModule } from "@/store/modules/reset";

Vue.use(Vuex);
config.rawError = true;

const plugins = [];

interface StoreWithModules {
  user: UserModule;
  submitOrder: SubmitOrderModule;
  progress: ProgressModule;
  pipelineFilters: PipelineFiltersModule;
  homecheck: HomecheckModule;
  error: ErrorModule;
  assignment: AssignmentModule;
  activeOrder: ActiveOrderModule;
  reset: ResetModule;
}

const localStorageKey = getVuexLocalStorageKey("vuex-evportal", true);
clearLocalStorageIfOnNewVersion(localStorageKey);
const vuexLocalStorage = new VuexPersist<StoreWithModules>({
  key: localStorageKey,
  storage: window.localStorage,
  modules: ["user", "submitOrder"],
});
plugins.push(vuexLocalStorage.plugin);

export default new Vuex.Store<StoreWithModules>({
  strict: onLocal(),
  plugins,
});
