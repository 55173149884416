import { httpClient } from "@/http";
import type {
  Order,
  OrderWithHomecheckAndAssignment,
  Homecheck,
  HomecheckWithImageIds,
} from "@defa-as/utils";

export const sendHomecheck = async (orderId: Order["id"]) =>
  httpClient.post<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}/homecheck/send`);

export const updateHomecheck = async (
  orderId: Order["id"],
  body: HomecheckWithImageIds
) =>
  await httpClient.put<{ homecheck: Homecheck }, { homecheck: Homecheck }>(
    `/orders/${orderId}/homecheck`,
    body
  );
