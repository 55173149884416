












































































import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import ProfileSection from "@/components/profile/ProfileSection.vue";
import PasswordWithConfirmation from "@/components/forms/PasswordWithConfirmation.vue";
import UsernameInputHidden from "@/components/forms/UsernameInputHidden.vue";
import { userModule } from "@/store/modules/user";
import { httpClient } from "@/http";
import InputPassword from "@/components/forms/InputPassword.vue";
import InputTelephone from "@/components/forms/InputTelephone.vue";
import InputFullName from "@/components/forms/InputFullName.vue";
import { API_VERSION_JULY_2021 } from "@defa-as/utils";
import { updateUser } from "@/http/requests/requests-user";

interface Model {
  telephoneNumber: string;
  fullName?: string;
  password: {
    current: string;
    new: string;
    newConfirm: string;
  };
}

type Editing = Record<keyof Model, boolean>;
type Loading = Record<keyof Model, boolean>;

@Component({
  components: {
    InputFullName,
    InputTelephone,
    InputPassword,
    ValidationObserver,
    ProfileSection,
    PasswordWithConfirmation,
    UsernameInputHidden,
  },
})
export default class ProfileGeneralInfo extends Vue {
  currentPasswordIsWrong = false;
  model: Model = {
    telephoneNumber: userModule.userTelephoneNumber,
    fullName: userModule.userFullName,
    password: {
      current: "",
      new: "",
      newConfirm: "",
    },
  };

  editing: Editing = {
    telephoneNumber: false,
    fullName: false,
    password: false,
  };

  loading: Loading = {
    telephoneNumber: false,
    fullName: false,
    password: false,
  };

  onEditPassword() {
    this.currentPasswordIsWrong = false;
    this.editing.password = true;
    this.model.password.new = "";
    this.model.password.newConfirm = "";
    this.model.password.current = "";
  }

  onEditTelephone() {
    this.editing.telephoneNumber = true;
  }

  onEditFullName() {
    this.editing.fullName = true;
  }

  onCancelPassword() {
    this.currentPasswordIsWrong = false;
    this.editing.password = false;
    this.model.password.new = "";
    this.model.password.newConfirm = "";
    this.model.password.current = "";
  }

  onCancelTelephone() {
    this.editing.telephoneNumber = false;
    this.model.telephoneNumber = userModule.userTelephoneNumber;
  }

  onCancelFullName() {
    this.editing.fullName = false;
    this.model.fullName = userModule.userFullName;
  }

  onNewPasswordInput(value: string) {
    this.model.password.new = value;
  }

  onNewPasswordConfirmInput(value: string) {
    this.model.password.newConfirm = value;
  }

  async onSavePassword() {
    this.currentPasswordIsWrong = false;
    this.loading.password = true;
    try {
      // Try logging user in w/ current password as backend doesn't have an endpoint to change password and check current password
      await httpClient.post(
        "/users/login",
        {
          loginName: userModule.userEmail,
          password: this.model.password.current,
        },
        {
          headers: {
            version: API_VERSION_JULY_2021,
            "X-Defa-Version": API_VERSION_JULY_2021,
          },
        }
      );
    } catch (e) {
      if (e.response?.status === 401) {
        this.currentPasswordIsWrong = true;
      } else {
        throw e;
      }
      this.loading.password = false;
    }
    if (!this.currentPasswordIsWrong) {
      try {
        await this.save("password", {
          newPassword: this.model.password.new,
        });
        this.model.password.new = "";
        this.model.password.newConfirm = "";
        this.model.password.current = "";
      } finally {
        this.loading.password = false;
      }
    }
  }

  async onSaveTelephone() {
    this.loading.telephoneNumber = true;
    try {
      await this.save("telephoneNumber", {
        telephoneNumber: this.model.telephoneNumber,
      });
    } finally {
      this.loading.telephoneNumber = false;
    }
  }

  async onSaveFullName() {
    this.loading.fullName = true;
    try {
      await this.save("fullName", {
        fullName: this.model.fullName as string,
      });
    } finally {
      this.loading.fullName = false;
    }
  }

  async save(field: keyof Model, body: Record<string, string>) {
    const user = await updateUser(body);
    await userModule.setUser({ user });
    this.editing[field] = false;
  }
}
