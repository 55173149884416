import Vue from "vue";
import VueI18n from "vue-i18n";
import { getLocale } from "@defa-as/utils";

Vue.use(VueI18n);

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: "nb",
});
