














































import { Component, Prop, Vue } from "vue-property-decorator";
import type { Nullable } from "@defa-as/utils";
import { pdfBaseUrl } from "@defa-as/utils";
import {
  DefaBaseButton,
  DefaBaseCard,
  DefaBaseLinkExternal,
  DefaIconPdf,
} from "@defa-as/components";

@Component({
  components: {
    DefaBaseButton,
    DefaBaseLinkExternal,
    DefaIconPdf,
    DefaBaseCard,
  },
})
export default class BaseCard extends Vue {
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly imageUrl!: string;
  @Prop({ default: () => [] }) readonly subtitles!: string[];
  @Prop({ default: null }) readonly price!: Nullable<number>;
  @Prop({ default: false }) readonly toggleable!: boolean;
  @Prop({ default: false }) readonly toggled!: boolean;
  @Prop({ default: "" }) readonly buttonLabel!: string;
  @Prop({ default: "" }) readonly buttonLabelOn!: string; // Only relevant if toggleable
  @Prop({ default: false }) readonly includeSpecificationsLink!: boolean;
  @Prop({ default: "" }) readonly axNumber!: string;

  get imageProps() {
    return {
      "--image-height": `${this.imageDimensions.height}px`,
      "--image-width": `${this.imageDimensions.width}px`,
    };
  }

  get imageDimensions() {
    return {
      height: 202,
      width: 202,
    };
  }

  get isSecondary() {
    if (this.toggleable) {
      return !this.toggled;
    }
    return false;
  }

  get label() {
    if (this.toggleable) {
      if (this.toggled) {
        return this.buttonLabelOn || this.$t("baseCard.labelToggle.default.on");
      }
      return this.buttonLabel || this.$t("baseCard.labelToggle.default.off");
    }
    return this.buttonLabel || this.$t("baseCard.labelButton.default");
  }

  get linkSpecifications() {
    return `${pdfBaseUrl()}/${this.axNumber}`;
  }

  created() {
    if (this.includeSpecificationsLink && !this.axNumber) {
      console.error(
        "[BaseCard]: You should specify property 'axNumber' defined when specifying 'includeSpecificationsLink'"
      );
    }
  }
}
