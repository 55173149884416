









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TaskDescription extends Vue {
  @Prop({ default: false }) readonly checked!: boolean;
  @Prop({ default: "" }) readonly label!: string;
}
