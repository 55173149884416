import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import type {
  PipelineFilter,
  PipelineFilterCounters,
  PipelineFilterType,
} from "@/store/types/pipeline-filters";

@Module({ name: "pipelineFilters", store, dynamic: true, namespaced: true })
export class PipelineFiltersModule extends VuexModule {
  filters: { [key in PipelineFilterType]: PipelineFilter<key> } = {
    allOrders: {
      label: "orderList.filters.allOrders",
      amount: 0,
      pipelineFilter: "allOrders",
      type: "all",
    },
    allOpenOrders: {
      label: "orderList.filters.openOrders.allOpenOrders",
      amount: 0,
      pipelineFilter: "allOpenOrders",
      type: "open",
    },
    invoicedWithoutInstallerInvoice: {
      label: "orderList.filters.openOrders.invoicedWithoutInstallerInvoice",
      amount: 0,
      pipelineFilter: "invoicedWithoutInstallerInvoice",
      type: "open",
    },
    installationPartnerRejected: {
      label: "orderList.filters.openOrders.installationPartnerRejected",
      amount: 0,
      pipelineFilter: "installationPartnerRejected",
      type: "open",
    },
    customerSchemaNotSent: {
      label: "orderList.filters.openOrders.customerSchemaNotSent",
      amount: 0,
      pipelineFilter: "customerSchemaNotSent",
      type: "open",
    },
    customerSchemaMissingQuestions: {
      label: "orderList.filters.openOrders.customerSchemaMissingQuestions",
      amount: 0,
      pipelineFilter: "customerSchemaMissingQuestions",
      type: "open",
    },
    installationPartnerNotRequested: {
      label: "orderList.filters.openOrders.installationPartnerNotRequested",
      amount: 0,
      pipelineFilter: "installationPartnerNotRequested",
      type: "open",
    },
    installationPartnerUnconfirmed: {
      label: "orderList.filters.openOrders.installationPartnerUnconfirmed",
      amount: 0,
      pipelineFilter: "installationPartnerUnconfirmed",
      type: "open",
    },
    commChannelNotReadByDefa: {
      label: "orderList.filters.openOrders.commChannelNotReadByDefa",
      amount: 0,
      pipelineFilter: "commChannelNotReadByDefa",
      type: "open",
    },
    commChannelNotReadByInst: {
      label: "orderList.filters.openOrders.commChannelNotReadByInst",
      amount: 0,
      pipelineFilter: "commChannelNotReadByInst",
      type: "open",
    },
    selectedChargerCanBeSent: {
      label: "orderList.filters.openOrders.selectedChargerCanBeSent",
      amount: 0,
      pipelineFilter: "selectedChargerCanBeSent",
      type: "open",
    },
    installationDateBeforeExpectedReceiptOfGoods: {
      label:
        "orderList.filters.openOrders.installationDateBeforeExpectedReceiptOfGoods",
      amount: 0,
      pipelineFilter: "installationDateBeforeExpectedReceiptOfGoods",
      type: "open",
    },
    noProgressLastThreeDays: {
      label: "orderList.filters.openOrders.noProgressLastThreeDays",
      amount: 0,
      pipelineFilter: "noProgressLastThreeDays",
      type: "open",
    },
    requestToBeCancelled: {
      label: "orderList.filters.openOrders.requestToBeCancelled",
      amount: 0,
      pipelineFilter: "requestToBeCancelled",
      type: "open",
    },
    installed: {
      label: "orderList.filters.closedOrders.installed",
      amount: 0,
      pipelineFilter: "installed",
      type: "closed",
    },
    done: {
      label: "orderList.filters.closedOrders.done",
      amount: 0,
      pipelineFilter: "done",
      type: "closed",
    },
    cancelled: {
      label: "orderList.filters.closedOrders.cancelled",
      amount: 0,
      pipelineFilter: "cancelled",
      type: "closed",
    },
  };

  currentFilterType: PipelineFilterType | "" = "";

  get hasFilter() {
    return (filter: string) => filter in this.filters;
  }

  get noCurrentFilter() {
    return !this.currentFilterType;
  }

  get allOrdersFilter() {
    return Object.values(this.filters).find((filter) => filter.type === "all");
  }

  get openOrderFilters() {
    return Object.values(this.filters).filter(({ type }) => type === "open");
  }

  get closedOrderFilters() {
    return Object.values(this.filters).filter(({ type }) => type === "closed");
  }

  get isCurrentFilter() {
    return (pipelineFilterType: PipelineFilterType) =>
      this.currentFilterType === pipelineFilterType;
  }

  @Mutation
  PIPELINE_FILTER_SET_CURRENT_FILTER({
    pipelineFilterType,
  }: {
    pipelineFilterType: PipelineFilterType;
  }) {
    this.currentFilterType = pipelineFilterType;
  }

  @Mutation
  PIPELINE_FILTER_SET_CUSTOMER_FILTER_AMOUNT({
    field,
    amount,
  }: { field: PipelineFilterType } & Pick<
    PipelineFilter<PipelineFilterType>,
    "amount"
  >) {
    this.filters[field].amount = amount;
  }

  @Action
  setNewFilterAmounts({
    filterAmounts,
  }: {
    filterAmounts: PipelineFilterCounters;
  }) {
    Object.entries(filterAmounts)
      .filter(([pipelineFilter]) => this.hasFilter(pipelineFilter))
      .forEach(([pipelineFilter, amount]) => {
        this.PIPELINE_FILTER_SET_CUSTOMER_FILTER_AMOUNT({
          field: pipelineFilter as PipelineFilterType,
          amount,
        });
      });
  }

  @Action
  resetPipelineFilterAmounts() {
    Object.keys(this.filters).forEach((pipelineFilter) =>
      this.PIPELINE_FILTER_SET_CUSTOMER_FILTER_AMOUNT({
        field: pipelineFilter as PipelineFilterType,
        amount: 0,
      })
    );
  }
}

export const pipelineFiltersModule = getModule(PipelineFiltersModule);
