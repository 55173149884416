import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";
import fetch from "cross-fetch";
import { defaApiUrl } from "@/config";

const cache = new InMemoryCache();

const apollo = new ApolloClient({
  // Provide required constructor fields
  cache: cache,
  uri: defaApiUrl,
  link: new HttpLink({ uri: defaApiUrl, fetch }),

  // Provide some optional constructor fields
  name: "evportal",
  // queryDeduplication: false,
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'cache-and-network',
  //   },
  // },
});

export default apollo;
