




















import { Component, Prop, Vue } from "vue-property-decorator";
import type { OrderStateFieldKey } from "@defa-as/utils";
import CheckTask from "@/components/order/progress/task-types/CheckTask.vue";
import LightSwitch from "@/components/forms/inputs-generic/LightSwitch.vue";
import TaskDescription from "@/components/order/progress/task-addons/TaskDescription.vue";
import TaskTimestamp from "@/components/order/progress/task-addons/TaskTimestamp.vue";
import { activeOrderModule } from "@/store/modules/active-order";

@Component({
  components: {
    TaskTimestamp,
    CheckTask,
    LightSwitch,
    TaskDescription,
  },
})
export default class ManualTask extends Vue {
  @Prop({ default: "task" }) readonly type!: "task" | "setting";
  @Prop({ default: "" }) readonly name!: string;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: "" }) readonly stateField!: OrderStateFieldKey;

  get loading() {
    return activeOrderModule.activeOrderStateField(this.stateField).loading;
  }

  get dateUpdated() {
    return activeOrderModule.activeOrderStateField(this.stateField).dateUpdated;
  }

  get state() {
    return activeOrderModule.activeOrderStateField(this.stateField).state;
  }

  get isSetting() {
    return this.type === "setting";
  }

  get isTask() {
    return this.type === "task";
  }

  get showTimestamp() {
    return this.dateUpdated;
  }

  doPatchState(state: boolean) {
    const data = { state: { [this.stateField]: { state } } };
    activeOrderModule.patchOrderState({ data, stateField: this.stateField });
  }
}
