




import { Component, Vue } from "vue-property-decorator";
import { userModule } from "@/store/modules/user";

// To be used in places where password gets reset
// See https://www.chromium.org/developers/design-documents/create-amazing-password-forms
@Component
export default class UsernameInputHidden extends Vue {
  get userModule() {
    return userModule;
  }
}
