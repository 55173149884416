import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import ViewProfile from "@/views/ViewProfile.vue";
import ViewLogin from "@/views/ViewLogin.vue";
import ViewOrderCreationBrands from "@/views/order-creation/ViewOrderCreationBrands.vue";
import ViewOrderCreationModels from "@/views/order-creation/ViewOrderCreationModels.vue";
import ViewOrderCreationSolutions from "@/views/order-creation/ViewOrderCreationSolutions.vue";
import ViewOrderCreationAccessories from "@/views/order-creation/ViewOrderCreationAccessories.vue";
import ViewOrderCreationSubmission from "@/views/order-creation/ViewOrderCreationSubmission.vue";
import ViewOrder from "@/views/ViewOrder.vue";
import ViewOrderList from "@/views/ViewOrderList.vue";
import TabProgress from "@/components/order/progress/TabProgress.vue";
import TabHomecheck from "@/components/order/homecheck/TabHomecheck.vue";
import TabChangelog from "@/components/order/changelog/TabChangelog.vue";
import TabCommentsInstallation from "@/components/order/comments-installation/TabCommentsInstallation.vue";
import { ROUTE_NAMES } from "@/router/route-names";
import { redirectToFromRoot } from "@/router/route-redirection";
import {
  navigationGuardAdmin,
  navigationGuardBeforeEach,
  navigationGuardHasChosenSolution,
  navigationGuardModel,
  navigationGuardNotLoggedIn,
  navigationGuardRetailerConnection,
} from "@/router/navigation-guards";
import ViewOrderCreationStart from "@/views/order-creation/ViewOrderCreationStart.vue";

Vue.use(Router);

const orderTabs = [
  {
    path: "",
    redirect: "progress",
  },
  {
    path: "progress",
    name: ROUTE_NAMES.ORDERS.DETAILS.PROGRESS,
    component: TabProgress,
  },
  {
    path: "homecheck",
    name: ROUTE_NAMES.ORDERS.DETAILS.HOMECHECK,
    component: TabHomecheck,
  },
  {
    path: "changelog",
    name: ROUTE_NAMES.ORDERS.DETAILS.CHANGELOG,
    component: TabChangelog,
  },
  {
    path: "comments-installation",
    name: ROUTE_NAMES.ORDERS.DETAILS.COMMENTS_INSTALLATION,
    component: TabCommentsInstallation,
  },
];

export const routes: RouteConfig[] = [
  {
    path: "/",
    name: ROUTE_NAMES.ROOT,
    redirect: redirectToFromRoot,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: ROUTE_NAMES.LOGIN,
    component: ViewLogin,
    beforeEnter: navigationGuardNotLoggedIn,
  },
  {
    path: "/new-order/start",
    name: ROUTE_NAMES.ORDERS_NEW.START,
    component: ViewOrderCreationStart,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-order/cars",
    name: ROUTE_NAMES.ORDERS_NEW.CARS,
    component: ViewOrderCreationBrands,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-order/cars/:brand",
    name: ROUTE_NAMES.ORDERS_NEW.MODELS,
    component: ViewOrderCreationModels,
    props: true,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: navigationGuardModel,
  },
  {
    path: "/new-order/solutions",
    name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    component: ViewOrderCreationSolutions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-order/accessories",
    name: ROUTE_NAMES.ORDERS_NEW.SOLUTION_ACCESSORIES,
    component: ViewOrderCreationAccessories,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: navigationGuardHasChosenSolution,
  },
  {
    path: "/new-order/submit",
    name: ROUTE_NAMES.ORDERS_NEW.SUBMIT,
    component: ViewOrderCreationSubmission,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: navigationGuardHasChosenSolution,
  },
  {
    path: "/orders/:id(\\d{5,})",
    component: ViewOrder,
    props: true,
    meta: { requiresAuth: true },
    children: orderTabs,
  },
  {
    path: "/orders",
    name: ROUTE_NAMES.ORDERS.LIST,
    component: ViewOrderList,
    props: ({ query }) => query,
    meta: { requiresAuth: true },
  },
  {
    path: "/user",
    name: ROUTE_NAMES.PROFILE,
    component: ViewProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "/register/new",
    name: ROUTE_NAMES.REGISTER.NEW,
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/ViewRegistration.vue"
      ),
    beforeEnter: navigationGuardNotLoggedIn,
  },
  {
    path: "/register/confirm/:hash",
    name: ROUTE_NAMES.REGISTER.CONFIRM,
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/ViewRegistrationConfirmation.vue"
      ),
    props: true,
    beforeEnter: navigationGuardNotLoggedIn,
  },
  {
    path: "/connect-retailer",
    name: ROUTE_NAMES.CONNECT_RETAILER,
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/ViewRetailerConnection.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: navigationGuardRetailerConnection,
  },
  {
    path: "/connect-retailer-for-public-rg/:userId(\\d{6,})",
    name: ROUTE_NAMES.CONNECT_RETAILER_FOR_PUBLIC_RG,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "RetailerConnectionForPublicRG" */ "@/views/ViewRetailerConnectionForPublicRG.vue"
      ),
    beforeEnter: navigationGuardAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/deny-user-for-public-rg/:userId(\\d{6,})",
    name: ROUTE_NAMES.DENY_USER_FOR_PUBLIC_RG,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "DenyUserForPublicRG" */ "@/views/ViewDenyUserForPublicRG.vue"
      ),
    beforeEnter: navigationGuardAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/confirm-gdpr",
    name: ROUTE_NAMES.GDPR_DOCUMENT_CONFIRMATION,
    props: ({ query }) => query,
    component: () =>
      import(
        /* webpackChunkName: "GDPRDocumentConfirmation" */ "@/views/ViewGDPRDocumentConfirmation.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recover",
    name: ROUTE_NAMES.RECOVER_PASSWORD.EMAIL,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecovery" */ "@/views/password-recovery/ViewPasswordRecovery.vue"
      ),
    beforeEnter: navigationGuardNotLoggedIn,
  },
  {
    path: "/recover/confirm/:hash",
    name: ROUTE_NAMES.RECOVER_PASSWORD.CONFIRM,
    component: () =>
      import(
        /* webpackChunkName: "passwordRecoveryConfirmation" */ "@/views/password-recovery/ViewPasswordRecoveryConfirmation.vue"
      ),
    props: true,
    beforeEnter: navigationGuardNotLoggedIn,
  },
  {
    path: "/report",
    name: ROUTE_NAMES.REPORT,
    props: ({ query }) => query,
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/ViewReport.vue"),
    meta: { fullWidth: true, requiresAuth: true },
    beforeEnter: navigationGuardAdmin,
  },
  {
    path: "/not-found",
    name: ROUTE_NAMES.NOT_FOUND,
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/ViewNotFound.vue"),
  },
  {
    path: "*",
    redirect: {
      name: ROUTE_NAMES.NOT_FOUND,
    },
  },
];

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach(navigationGuardBeforeEach);

export default router;
