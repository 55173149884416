
























import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaModal } from "@defa-as/components";
import { imagesBaseUrl } from "@defa-as/utils";

@Component({
  components: {
    DefaModal,
    DefaBaseButton,
  },
})
export default class ModalThreePhaseSolution extends Vue {
  @Prop({ default: false }) readonly show!: boolean;

  get fourHundredVoltImage() {
    return `${imagesBaseUrl()}/400v-solution.jpg`;
  }
}
