



















import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { submitOrderModule } from "@/store/modules/submit-order";
import { ROUTE_NAMES } from "@/router/route-names";
import { NavigationGuardNext, Route } from "vue-router";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";

@Component({
  components: {
    DefaBaseButton,
  },
})
export default class ViewOrderCreationStart extends Mixins(
  UnsavedChangesModalMixin
) {
  async created() {
    await submitOrderModule.resetModel();
  }

  async goToSolutions() {
    await submitOrderModule.clearModelAndBrand();
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    });
  }

  async goToBrands() {
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.CARS,
    });
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    this.onLeaveOrderCreation(next, to);
  }
}
