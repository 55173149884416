import "@/class-component";
import Vue from "vue";
import {
  configureSentry,
  formatDate,
  formatDateShort,
  formatDatetimeLong,
  formatDatetimeShort,
  onLocalOrTest,
  setLangOnHtmlElement,
  setupThemingOnRootElement,
  RELEASE_VERSION,
} from "@defa-as/utils";
import { getTitle } from "@/config/portals";

import { formatCurrency, upperCaseFirstLetter } from "@/filters";
import { setupValidation } from "@/validation";

export const setup = () => {
  if (onLocalOrTest()) {
    Vue.config.devtools = true;
  }
  setupValidation();
  setupThemingOnRootElement({
    colorPrimary: "#00B27C",
    colorPrimaryContrastLow: "#005139",
    colorAccent: "#1d2720",
  });
  document.title = getTitle();
  setLangOnHtmlElement();
  configureSentry(Vue, RELEASE_VERSION);

  Vue.filter("formatDate", formatDate);
  Vue.filter("formatDateShort", formatDateShort);
  Vue.filter("formatDatetimeShort", formatDatetimeShort);
  Vue.filter("formatDatetimeLong", formatDatetimeLong);
  Vue.filter("toCurrency", formatCurrency);
  Vue.filter("upperCaseFirstLetter", upperCaseFirstLetter);
};
