






































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaCircleWrapper, DefaModal } from "@defa-as/components";

@Component({
  components: {
    DefaCircleWrapper,
    DefaModal,
  },
})
export default class BaseCardServicesIncludedModal extends Vue {
  @Prop({ required: true }) readonly servicesIncluded!: string[];
  @Prop({ default: false }) readonly show!: boolean;

  get notes() {
    let notes: string[] = [];
    for (let i = 0; true; i++) {
      const noteTranslationKey = `baseCardExtraDetailsSolution.included.modal.notes.${i}`;
      const noteExists = this.$te(noteTranslationKey);
      if (!noteExists) {
        break;
      }
      notes = [...notes, this.$t(noteTranslationKey) as string];
    }
    return notes;
  }
}
