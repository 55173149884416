












import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";

@Component({
  components: { DefaBaseInput },
})
export default class InputTelephone extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: false }) readonly darkTheme!: boolean;

  get colorProps() {
    return this.darkTheme
      ? {
          labelBackgroundColor: "var(--defa-oreon-color-accent)",
          color: "white",
        }
      : null;
  }

  get valueForInput() {
    return this.value;
  }

  set valueForInput(value: string) {
    this.$emit("input", value);
  }

  get translatedLabel() {
    return this.label || this.$t("inputTelephone.defaultLabel");
  }
}
