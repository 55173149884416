import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type { Assignment, Nullable } from "@defa-as/utils";
import type {
  AssignmentTimestampsAsMap,
  AssignmentWithTimestampsAsMap,
  SetInstallationPartnerPayload,
} from "@/store/types/assignment";
import store from "@/store";
import { activeOrderModule } from "@/store/modules/active-order";
import {
  cancelAssignment,
  sendAssignmentToInstallationPartner,
  setInstallationPartner,
} from "@/http/requests/requests-assignment";

@Module({ name: "assignment", store, dynamic: true, namespaced: true })
export class AssignmentModule extends VuexModule {
  loading = false;
  data: AssignmentWithTimestampsAsMap = {
    rejection: {
      reason: null,
      comment: null,
    },
    confirmation: {
      correctWallbox: null,
      installationPossible: null,
      additionalServicesRequired: null,
      comment: null,
    },
    inspection: {
      scheduled: null,
      reasonFailed: null,
      comment: null,
    },
    installation: {
      completed: null,
      scheduled: null,
      reasonFailed: null,
      comment: null,
      files: [],
      serialNumbers: null,
      workingTimeInMinutes: null,
    },
    installationPartner: null,
    // We're using a map to ensure the order of the timestamps as these are relevant when displaying these in the Order Details view
    timestamps: new Map(
      Object.entries<Nullable<string>>({
        assignmentOpened: null,
        assignmentRejected: null,
        assignmentConfirmed: null,
        inspectionRequired: null,
        inspectionSkipped: null,
        inspectionScheduled: null,
        inspectionFailed: null,
        inspectionSuccessful: null,
        consignmentRequested: null,
        consignmentSent: null,
        installationScheduled: null,
        consignmentCompleted: null,
        installationFailed: null,
        installationSuccessful: null,
        reportCompleted: null,
      })
    ) as AssignmentTimestampsAsMap,
  };

  @Mutation
  ASSIGNMENT_SET_LOADING() {
    this.loading = true;
  }

  @Mutation
  ASSIGNMENT_UNSET_LOADING() {
    this.loading = false;
  }

  @Mutation
  ASSIGNMENT_SET({ data }: { data: AssignmentWithTimestampsAsMap }) {
    this.data = data;
  }

  get hasInstallationPartner() {
    return Boolean(this.data.installationPartner);
  }

  get installationPartner() {
    return this.data.installationPartner;
  }

  get loadingAssignment() {
    return this.loading;
  }

  get assignmentData() {
    return this.data;
  }

  get assignmentTimestamps() {
    return this.data.timestamps;
  }

  @Action
  async setAssignmentData({ data }: { data: Assignment }) {
    const timestampsAsMap = [...this.assignmentData.timestamps.keys()].reduce(
      (timestamps, timestamp) => {
        timestamps.set(timestamp, data.timestamps[timestamp]);
        return timestamps;
      },
      new Map()
    );
    this.ASSIGNMENT_SET({ data: { ...data, timestamps: timestampsAsMap } });
  }

  @Action
  async setInstallationPartner(payload: SetInstallationPartnerPayload) {
    this.ASSIGNMENT_SET_LOADING();
    try {
      const assignment = await setInstallationPartner(
        activeOrderModule.activeOrderId,
        payload
      );
      await this.setAssignmentData({ data: assignment });
      await activeOrderModule.refreshActiveOrder();
    } finally {
      this.ASSIGNMENT_UNSET_LOADING();
    }
  }

  @Action
  async cancelInstallationPartner({ message }: { message: string }) {
    this.ASSIGNMENT_SET_LOADING();
    try {
      const assignment = await cancelAssignment(
        activeOrderModule.activeOrderId,
        {
          message,
        }
      );
      await this.setAssignmentData({ data: assignment });
      await activeOrderModule.refreshActiveOrder();
    } finally {
      this.ASSIGNMENT_UNSET_LOADING();
    }
  }

  @Action
  async sendAssignmentToInstallationPartner() {
    const assignment = await sendAssignmentToInstallationPartner(
      activeOrderModule.activeOrderId
    );
    await this.setAssignmentData({ data: assignment });
    await activeOrderModule.refreshActiveOrder();
  }
}

export const assignmentModule = getModule(AssignmentModule);
