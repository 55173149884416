














import { Component, Vue } from "vue-property-decorator";
import HomecheckQuestions from "@/components/order/homecheck/questions/HomecheckQuestions.vue";
import { homecheckModule } from "@/store/modules/homecheck";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    HomecheckQuestions,
  },
})
export default class HomecheckCompletedStage extends Vue {
  get homecheckModule() {
    return homecheckModule;
  }

  get userModule() {
    return userModule;
  }

  created() {
    this.homecheckModule.HOMECHECK_READONLY();
  }
}
