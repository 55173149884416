






















































import { Component, Mixins } from "vue-property-decorator";
import { inNorway } from "@defa-as/utils";
import { DefaModalConfirmCancel } from "@defa-as/components";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import BaseList from "@/components/BaseList.vue";
import { submitOrderModule } from "@/store/modules/submit-order";
import type { Solution } from "@/store/types/submit-order";
import { NavigationGuardNext, Route } from "vue-router";
import BaseCard from "@/components/BaseCard.vue";
import { ROUTE_NAMES } from "@/router/route-names";
import BaseCardSubHeadingSolution from "@/components/BaseCardSubHeadingSolution.vue";
import BaseCardExtraDetailsSolution from "@/components/BaseCardExtraDetailsSolution.vue";
import BaseCardConnectionIconsSolution from "@/components/BaseCardConnectionIconsSolution.vue";
import ModalThreePhaseSolution from "@/components/modals/ModalThreePhaseSolution.vue";
import { getSolutions } from "@/http/requests/requests-submit-order";

@Component({
  components: {
    ModalThreePhaseSolution,
    BaseCardConnectionIconsSolution,
    BaseCardExtraDetailsSolution,
    BaseCardSubHeadingSolution,
    BaseCard,
    BaseList,
    DefaModalConfirmCancel,
  },
})
export default class ViewOrderCreationSolutions extends Mixins(
  UnsavedChangesModalMixin
) {
  solutions: Solution[] = [];
  loading = false;
  modalThreePhaseSolutionVisible = false;

  get submitOrderModule() {
    return submitOrderModule;
  }

  get isFirstSolutionOfGroup() {
    return (i: number) => i === 0;
  }

  get solutionsSortedByType() {
    return [
      this.sortedFordSolutions,
      this.sortedMGSolutions,
      this.sortedIQSolutions,
      this.sortedUnoSolutions,
      this.sortedDuoSolutions,
      this.sortedUnknownSolutions,
    ];
  }

  get sortedFordSolutions() {
    return this.sortedSolutions(
      this.solutions.filter((solution) => solution.range === "FORD")
    );
  }

  get sortedMGSolutions() {
    return this.sortedSolutions(
      this.solutions.filter((solution) => solution.range === "MG")
    );
  }

  get sortedIQSolutions() {
    return this.sortedSolutions(
      this.solutions.filter((solution) => solution.range === "IQ")
    );
  }

  get sortedUnoSolutions() {
    return this.sortedSolutions(
      this.solutions.filter((solution) => solution.range === "UNO")
    );
  }

  get sortedDuoSolutions() {
    return this.sortedSolutions(
      this.solutions.filter((solution) => solution.range === "DUO")
    );
  }

  get sortedUnknownSolutions() {
    return this.sortedSolutions(
      this.solutions.filter(
        (solution) =>
          !["MG", "FORD", "IQ", "UNO", "DUO"].includes(solution.range)
      )
    );
  }

  get sortedSolutions() {
    return (solutions: Solution[]) => {
      const sortOnPriorityInRange = (
        solutionOne: Solution,
        solutionTwo: Solution
      ) => {
        if (
          solutionOne.sortPriorityInRange &&
          solutionTwo.sortPriorityInRange
        ) {
          return (
            solutionOne.sortPriorityInRange - solutionTwo.sortPriorityInRange
          );
        }
        if (solutionOne.sortPriorityInRange) {
          return -1;
        }
        if (solutionTwo.sortPriorityInRange) {
          return -1;
        }
        return 0;
      };
      const sortOnPhase = (solutionOne: Solution, solutionTwo: Solution) =>
        solutionTwo.phase - solutionOne.phase;
      const sortOnCable = (solutionOne: Solution, solutionTwo: Solution) => {
        if (solutionOne.integratedCable) {
          return solutionTwo.integratedCable ? 0 : -1;
        }
        return solutionTwo.integratedCable ? 1 : 0;
      };
      return [...solutions].sort((solutionOne, solutionTwo) => {
        return (
          sortOnPriorityInRange(solutionOne, solutionTwo) ||
          sortOnPhase(solutionOne, solutionTwo) ||
          sortOnCable(solutionOne, solutionTwo)
        );
      });
    };
  }

  async created() {
    const params = {
      ...(submitOrderModule.customerCarModelSlug
        ? {
            carModel: submitOrderModule.customerCarModelSlug,
          }
        : null),
    };
    this.loading = true;
    try {
      const solutionsResponse = await getSolutions(params);
      const { data: solutions } = solutionsResponse;
      this.solutions = solutions;
    } finally {
      this.loading = false;
    }
  }

  async onClickSolution(chosenSolution: Solution) {
    const solution = this.solutions.find(
      (solution) => solution.id === chosenSolution.id
    );
    if (solution) {
      submitOrderModule.SUBMIT_ORDER_SET_SOLUTION(solution);
      if (inNorway() && solution.phase === 3) {
        this.modalThreePhaseSolutionVisible = true;
      } else {
        await this.continue();
      }
    }
  }

  async continue() {
    if (submitOrderModule.hasAddonProductsForCurrentSolution) {
      return await this.$router.push({
        name: ROUTE_NAMES.ORDERS_NEW.SOLUTION_ACCESSORIES,
      });
    }
    await submitOrderModule.clearAddons();
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SUBMIT,
    });
  }

  cancelModalThreePhaseSolution() {
    this.modalThreePhaseSolutionVisible = false;
    submitOrderModule.clearSolution();
  }

  async confirmModalThreePhaseSolution() {
    this.modalThreePhaseSolutionVisible = false;
    await this.continue();
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    this.onLeaveOrderCreation(next, to);
  }
}
