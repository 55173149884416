import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type { Homecheck, HomecheckWithImageIds } from "@defa-as/utils";
import store from "@/store";
import { activeOrderModule } from "@/store/modules/active-order";
import {
  sendHomecheck,
  updateHomecheck,
} from "@/http/requests/requests-homecheck";

@Module({ name: "homecheck", store, dynamic: true, namespaced: true })
export class HomecheckModule extends VuexModule {
  loading = {
    send: false,
    save: false,
  };

  readonly = true;
  data: Homecheck = {
    propertyId: null,
    personalIdentificationNumber: null,
    mainFuseCapacity: null,
    voltage: null,
    distributionBoardSpaceAvailable: null,
    cableLengthEstimate: null,
    internetConnection: null,
    excavationNecessary: null,
    specializedDrillingNecessary: null,
    propertyType: null,
    propertyOwnerApproval: null,
    comment: null,
    distributionBoardImages: [],
    distributionBoardSchemaImages: [],
    electricityMeterBoxImages: [],
    installationSiteImages: [],
  };

  get images() {
    return [
      ...this.getData.distributionBoardImages,
      ...this.getData.distributionBoardSchemaImages,
      ...this.getData.electricityMeterBoxImages,
      ...this.getData.installationSiteImages,
    ];
  }

  get isSendLoading() {
    return this.loading.send;
  }

  get getData() {
    return this.data;
  }

  get isSaveLoading() {
    return this.loading.save;
  }

  get isReadonly() {
    return this.readonly;
  }

  get homecheckWithMappedImages() {
    return (homecheck: Homecheck) => {
      return Object.entries(homecheck).reduce(
        (homecheckData, [key, value]) => ({
          ...homecheckData,
          [key]: Array.isArray(value) ? value.map((image) => image.id) : value,
        }),
        {} as HomecheckWithImageIds
      );
    };
  }

  @Mutation
  HOMECHECK_SEND_SET_LOADING() {
    this.loading.send = true;
  }

  @Mutation
  HOMECHECK_SEND_UNSET_LOADING() {
    this.loading.send = false;
  }

  @Mutation
  HOMECHECK_SAVE_SET_LOADING() {
    this.loading.save = true;
  }

  @Mutation
  HOMECHECK_SAVE_UNSET_LOADING() {
    this.loading.save = false;
  }

  @Mutation
  HOMECHECK_SET({ data }: { data: Homecheck }) {
    this.data = data;
  }

  @Mutation
  HOMECHECK_READONLY() {
    this.readonly = true;
  }

  @Mutation
  HOMECHECK_EDITABLE() {
    this.readonly = false;
  }

  @Mutation
  HOMECHECK_SET_FIELD<T extends keyof Homecheck>({
    field,
    value,
  }: {
    field: T;
    value: Homecheck[T];
  }) {
    this.data[field] = value;
  }

  @Action
  async setHomecheckData({ data }: { data: Homecheck }) {
    this.HOMECHECK_SET({ data });
  }

  @Action
  async sendHomecheck() {
    this.HOMECHECK_SEND_SET_LOADING();
    try {
      const order = await sendHomecheck(activeOrderModule.activeOrderId);
      await activeOrderModule.setOrderData({ data: order });
    } finally {
      this.HOMECHECK_SEND_UNSET_LOADING();
    }
  }

  @Action
  async updateHomecheck({ homecheck }: { homecheck: Homecheck }) {
    this.HOMECHECK_SAVE_SET_LOADING();
    try {
      const { homecheck: updatedHomecheck } = await updateHomecheck(
        activeOrderModule.activeOrderId,
        this.homecheckWithMappedImages(homecheck)
      );
      await this.setHomecheckData({ data: updatedHomecheck });
      this.HOMECHECK_READONLY();
    } finally {
      this.HOMECHECK_SAVE_UNSET_LOADING();
    }
  }
}

export const homecheckModule = getModule(HomecheckModule);
