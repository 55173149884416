import { getCurrencyForLocale, getLocale, Nullable } from "@defa-as/utils";

const currency = getCurrencyForLocale();

const formatter = new Intl.NumberFormat(getLocale(), {
  style: "currency",
  currency,
});

export const upperCaseFirstLetter = (value?: Nullable<string>) => {
  if (value) {
    if (value.length > 1) {
      return `${value.slice(0, 1).toUpperCase()}${value
        .slice(1)
        .toLowerCase()}`;
    }
    return value.toUpperCase();
  }
  return value;
};

export const formatCurrency = (value?: Nullable<number>) => {
  if (typeof value === "number") {
    return formatter.format(value);
  }
  return value;
};
