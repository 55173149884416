



























import { Component, Prop, Vue } from "vue-property-decorator";
import type { Solution } from "@/store/types/submit-order";

@Component
export default class BaseCardSubHeadingSolution extends Vue {
  @Prop({ required: true }) readonly solution!: Solution;

  get moreThanOneConnector() {
    return this.solution.connectorCount > 1;
  }

  get effectDividedBy1000() {
    return this.solution.effect / 1000;
  }

  get solutionPhases() {
    if (!this.solution.phase) {
      return [];
    }
    return this.solution.phase.toString().split(".");
  }

  get connectorType() {
    const type = this.solution.connectorType
      ? this.solution.connectorType.replace(/^\w/, (c) => c.toUpperCase())
      : "";
    const integrated = this.$t(
      `baseCardSubHeadingSolution.integrated.${Boolean(
        this.solution.integratedCable
      )}`
    );
    return `${type} ${integrated}`;
  }
}
