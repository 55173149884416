



























































































import type { CancelTokenSource } from "axios";
import axios from "axios";
import type { NavigationGuardNext, Route } from "vue-router";
import { isEqual } from "lodash-es";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseCheckbox,
  DefaLoaderCircle,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import type {
  Nullable,
  Order,
  OrderWithHomecheckAndAssignment,
} from "@defa-as/utils";
import {
  formatDateShortWithDots,
  formatDatetime,
  inSweden,
  OrderClient,
} from "@defa-as/utils";
import OrderListItem from "@/components/order-list/OrderListItem.vue";
import OrderListPaginationAndSorting from "@/components/order-list/OrderListPaginationAndSorting.vue";
import { pipelineFiltersModule } from "@/store/modules/pipeline-filters";
import OrderListPipelineFilters from "@/components/order-list/OrderListPipelineFilters.vue";
import OrderListSearch from "@/components/order-list/OrderListSearch.vue";
import OrderListStatusFilter from "@/components/order-list/OrderListStatusFilter.vue";
import type { PipelineFilterType } from "@/store/types/pipeline-filters";
import { ROUTE_NAMES } from "@/router/route-names";
import { userModule } from "@/store/modules/user";
import { listOrders, ListOrdersResponse } from "@/http/requests/requests-order";

const sortValues = [
  "dateCreated desc",
  "dateCreated asc",
  "dateUpdated desc",
  "dateUpdated asc",
];

type SortValue = typeof sortValues[number];

type RouteParam =
  | "page"
  | "sort"
  | "search"
  | "status"
  | "pipelineFilter"
  | "includeElli";

type RouteQuery = { [param in RouteParam]: string };

@Component({
  components: {
    OrderListStatusFilter,
    OrderListSearch,
    OrderListPipelineFilters,
    OrderListPaginationAndSorting,
    DefaLoaderCircle,
    DefaBaseCheckbox,
    DefaTransitionFadeOutIn,
    OrderListItem,
    DefaBaseButton,
  },
})
export default class ViewOrderList extends Vue {
  @Prop({ default: "1" }) readonly page!: string;
  @Prop({ default: sortValues[0] }) readonly sort!: SortValue;
  @Prop({ default: "" }) readonly search!: string;
  @Prop({ default: "" }) readonly status!: string;
  @Prop({ default: "false" }) readonly includeElli!: string;
  // prettier-ignore
  @Prop({ default: "allOpenOrders" }) readonly pipelineFilter!: PipelineFilterType;

  orders: Order[] = [];
  loading = false;
  request: Nullable<CancelTokenSource> = null;
  totalPages = 1;

  reports = {
    loading: false,
    file: {
      url: "",
      name: "",
    },
  };

  get isReportButtonVisible() {
    return !this.loading && this.hasOrders && userModule.isAdmin;
  }

  get isPaginationAndSortingVisible() {
    return !this.loading && this.hasOrders;
  }

  get showNoOrdersText() {
    return !this.loading && !this.hasOrders;
  }

  get showOrders() {
    return !this.loading && this.hasOrders;
  }

  get hasOrders() {
    return Boolean(this.orders.length);
  }

  get isSwedishAdminUser() {
    return inSweden() && userModule.isAdmin;
  }

  get sortOptions() {
    return sortValues
      .map((sortValue) => sortValue.split(" "))
      .map((sortFieldAndDirection) => ({
        label: this.$t(
          `orderList.view.sort.${sortFieldAndDirection[0]}.${sortFieldAndDirection[1]}`
        ),
        value: sortFieldAndDirection.join(" "),
      }));
  }

  get pageOptions() {
    return Array.from(Array(this.totalPages), (_, i) => ({
      label: i + 1,
      value: i + 1,
    }));
  }

  get selectedPageOption() {
    return this.pageOptions.find(({ value }) => value === +this.page)?.value;
  }

  get selectedSortOption() {
    return this.sortOptions.find(({ value }) => value === this.sort)?.value;
  }

  get includeElliAsBoolean() {
    return this.includeElli.toLowerCase() === "true";
  }

  get isStatusFilterVisible() {
    return !["done", "cancelled"].includes(this.pipelineFilter);
  }

  get propsAsRouteParams() {
    const params = new URLSearchParams();
    params.append("page", this.page);
    params.append("search", this.search);
    params.append("order", this.sort);
    params.append("status", this.status);
    params.append("pipelineFilter", this.pipelineFilter);
    params.append("includeElli", this.includeElli);
    params.append("context-app", "EP");
    return params;
  }

  beforeRouteEnter(
    to: Route,
    _: Route,
    next: NavigationGuardNext<ViewOrderList>
  ) {
    next(async (vm) => {
      const query = {
        page: vm.page,
        /**
         * The query params in the URL and the API request used to be different for this field
         * These were unified sometime in 2020, which did mean that some people's bookmarks broke, as they still used the legacy query param value
         * By falling back to sortValues[0] for an invalid value, we're working around this issue for those people
         */
        sort: sortValues.includes(vm.sort) ? vm.sort : sortValues[0],
        search: vm.search,
        pipelineFilter: vm.pipelineFilter,
        status: vm.status,
        ...(inSweden() ? { includeElli: vm.includeElli } : null),
      };
      pipelineFiltersModule.PIPELINE_FILTER_SET_CURRENT_FILTER({
        pipelineFilterType: vm.pipelineFilter,
      });
      if (!isEqual(to.query, query)) {
        await vm.$router.replace({
          name: ROUTE_NAMES.ORDERS.LIST,
          query,
        });
      } else {
        // Can't rely on $route.query watcher to load orders in this case
        await vm.loadOrders(true);
      }
    });
  }

  beforeRouteLeave(_: Route, __: Route, next: NavigationGuardNext) {
    if (this.request) {
      this.request.cancel();
      next();
    }
  }

  async created() {
    await pipelineFiltersModule.resetPipelineFilterAmounts();
  }

  async onDebouncedSearchInput({ search }: { search: string }) {
    await this.updateRouteQueryValue("search", search);
  }

  async changeStatus(status: string) {
    await this.updateRouteQueryValue("status", status);
  }

  async changeIncludeElli(value: boolean) {
    await this.updateRouteQueryValue("includeElli", value + "");
  }

  async clearStatus() {
    await this.changeStatus("");
  }

  async onChangeSort(sort: SortValue) {
    await this.updateRouteQueryValue("sort", sort);
  }

  async onChangePage(page: number) {
    await this.updateRouteQueryValue("page", "" + page);
  }

  async onChangePipelineFilter({
    pipelineFilter,
  }: {
    pipelineFilter: PipelineFilterType;
  }) {
    await this.updateRouteQueryValue("pipelineFilter", pipelineFilter);
    pipelineFiltersModule.PIPELINE_FILTER_SET_CURRENT_FILTER({
      pipelineFilterType: this.pipelineFilter,
    });
    if (this.status) {
      await this.clearStatus();
    }
  }

  async updateRouteQueryValue(name: RouteParam, value: string) {
    if (this.$route.query[name] !== value) {
      const query = {
        ...this.$route.query,
        page: "1",
        [name]: value,
      };
      await this.$router.push({
        name: ROUTE_NAMES.ORDERS.LIST,
        query,
      });
    }
  }

  handleResponse({ data: orders, meta }: ListOrdersResponse) {
    this.orders = orders;
    this.totalPages = meta.pagination.total_pages;
    if (meta.pipelineFilterCounters) {
      pipelineFiltersModule.setNewFilterAmounts({
        filterAmounts: meta.pipelineFilterCounters,
      });
    }
  }

  async loadOrders(includePipelineFilterCounters: boolean) {
    if (this.request) {
      this.request.cancel();
    }
    this.request = axios.CancelToken.source();
    this.loading = true;
    const params = this.propsAsRouteParams;
    if (includePipelineFilterCounters) {
      params.append("pipelineFilterCounters", "true");
    }
    try {
      const orderResponseData = await listOrders(params, this.request.token);
      this.handleResponse(orderResponseData);
      this.loading = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.loading = false;
      }
    }
  }

  clearReportsInfo() {
    setTimeout(() => {
      URL.revokeObjectURL(this.reports.file.url);
      this.reports.file.url = "";
      this.reports.file.name = "";
    }, 2000);
  }

  async prepareReport() {
    this.reports.loading = true;
    try {
      const { Parser, transforms } = await import(
        /* webpackChunkName: "json2csv"  */ "json2csv"
      );
      const parser = new Parser({
        transforms: [
          (order: OrderWithHomecheckAndAssignment) => ({
            "Order Reference": order.title,
            "Order Status": this.$t(
              `shared.options.orderStatus.${order.orderStatus}`
            ),
            "Customer Name": order.customerFullName,
            "Car Brand": order.customerCarBrand,
            "Car Model": order.customerCarModel,
            "Car Delivery Date": formatDateShortWithDots(
              order.customerCarDeliveryDate?.date || ""
            ),
            "Retailer Name": order.retailer?.name || "",
            "Installation Partner":
              order.assignment.installationPartner?.companyName || "",
            "Comments from DEFA": order.administratorNotes,
            Created: formatDatetime(order.created.date),
            Updated: formatDatetime(order.updated.date),
            "Order Lines": order.orderLines,
          }),
          transforms.flatten({ arrays: true, objects: true, separator: "_" }),
        ],
      });
      const orderClient = new OrderClient(
        userModule.token,
        this.propsAsRouteParams
      );
      const orders: Order[] = await orderClient.fetchOrdersPaged();
      const csv = parser.parse(orders);
      const reportsBlob = new Blob([csv], {
        type: "text/csv",
      });
      this.reports.file.url = URL.createObjectURL(reportsBlob);
      this.reports.file.name = this.$t("orderList.report.filename", {
        timestamp: formatDatetime(new Date()),
      }) as string;
    } finally {
      this.reports.loading = false;
    }
  }

  isInitialRouteNavigation(queryOld: RouteQuery) {
    return !queryOld || isEqual(queryOld, {});
  }

  includeElliUpdated(queryOld: RouteQuery, queryNew: RouteQuery) {
    return queryOld.includeElli !== queryNew.includeElli;
  }

  @Watch("$route.query")
  async onRouteChange(queryNew: RouteQuery, queryOld: RouteQuery) {
    if (
      this.isInitialRouteNavigation(queryOld) ||
      this.includeElliUpdated(queryOld, queryNew)
    ) {
      await this.loadOrders(true);
    } else {
      await this.loadOrders(false);
    }
  }
}
