
































import { Component, Mixins } from "vue-property-decorator";
import { getPlaceForPostalCode, inNorway } from "@defa-as/utils";
import { DefaBaseInput } from "@defa-as/components";
import OrderSubmissionSection from "@/mixins/order-submission-section";

@Component({
  components: {
    DefaBaseInput,
  },
})
export default class OrderSubmissionCustomerAddress extends Mixins(
  OrderSubmissionSection
) {
  get inNorway() {
    return inNorway();
  }

  onPostalCodeInput(postalCode: string) {
    this.onCustomerInput("postCode", postalCode);
    this.setCustomerPlace(postalCode);
  }

  async setCustomerPlace(postalCode: string) {
    this.submitOrderModule.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key: "place",
      value: await getPlaceForPostalCode(postalCode),
    });
  }
}
