




























import { Component, Prop, Vue } from "vue-property-decorator";
import type { InstallationPartner } from "@defa-as/utils";

@Component
export default class ProgressTabInstallationPartnerLookupEntry extends Vue {
  @Prop({ required: true }) readonly installationPartner!: InstallationPartner;
  maxRating = 5;

  get isStarHighlighted() {
    return (index: number) => index <= this.installationPartner.rating;
  }
}
