var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ProfileSection',{attrs:{"edit-link-text":_vm.$t('profile.telephone.change'),"edit-mode":_vm.editing.telephoneNumber,"loading":_vm.loading.telephoneNumber},on:{"cancel":_vm.onCancelTelephone,"edit":_vm.onEditTelephone,"save":function($event){return handleSubmit(_vm.onSaveTelephone)}}},[_c('InputTelephone',{attrs:{"disabled":!_vm.editing.telephoneNumber,"dark-theme":""},model:{value:(_vm.model.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.model, "telephoneNumber", $$v)},expression:"model.telephoneNumber"}})],1)],1)]}}])}),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ProfileSection',{attrs:{"edit-link-text":_vm.$t('profile.fullName.change'),"edit-mode":_vm.editing.fullName,"loading":_vm.loading.fullName},on:{"cancel":_vm.onCancelFullName,"edit":_vm.onEditFullName,"save":function($event){return handleSubmit(_vm.onSaveFullName)}}},[_c('InputFullName',{attrs:{"disabled":!_vm.editing.fullName,"dark-theme":""},model:{value:(_vm.model.fullName),callback:function ($$v) {_vm.$set(_vm.model, "fullName", $$v)},expression:"model.fullName"}})],1)],1)]}}])}),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('UsernameInputHidden'),_c('ProfileSection',{attrs:{"edit-link-text":_vm.$t('profile.password.change'),"edit-mode":_vm.editing.password,"loading":_vm.loading.password},on:{"cancel":_vm.onCancelPassword,"edit":_vm.onEditPassword,"save":function($event){return handleSubmit(_vm.onSavePassword)}}},[_c('InputPassword',{staticClass:"password-current",attrs:{"disabled":!_vm.editing.password,"password-type":"passwordCurrent"},model:{value:(_vm.model.password.current),callback:function ($$v) {_vm.$set(_vm.model.password, "current", $$v)},expression:"model.password.current"}}),_c('PasswordWithConfirmation',{attrs:{"disabled":!_vm.editing.password,"password-confirm-value":_vm.model.password.newConfirm,"password-value":_vm.model.password.new},on:{"input:password":_vm.onNewPasswordInput,"input:password-confirm":_vm.onNewPasswordConfirmInput}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPasswordIsWrong),expression:"currentPasswordIsWrong"}],staticClass:"profile-general-info__error-message"},[_vm._v(" "+_vm._s(_vm.$t("profile.passwordCurrent.error"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }