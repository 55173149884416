











import { Component, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaModal } from "@defa-as/components";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";

/**
 * Used for users that somehow were logged into a domain for a RG they never should have had access to
 */
@Component({
  components: {
    DefaBaseButton,
    DefaModal,
  },
})
export default class RetailerGroupModalDeactivateRetailer extends Vue {
  async onAccept() {
    await userModule.deactivateRetailer();
    if (this.$route.name !== ROUTE_NAMES.CONNECT_RETAILER) {
      await this.$router.replace({
        name: ROUTE_NAMES.CONNECT_RETAILER,
      });
    }
  }
}
