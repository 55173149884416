


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HomecheckQuestionSelect extends Vue {
  @Prop({ required: true }) readonly fieldName!: string;
  @Prop({ required: true }) readonly options!: string[];
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: null }) readonly value!: string | boolean;

  get isSelected() {
    return this.value || this.value === false;
  }

  onChange(value: string) {
    // Parse Boolean string to Boolean value
    if (value === "true" || value === "false") {
      value = JSON.parse(value);
    }
    this.$emit("input", value);
  }
}
