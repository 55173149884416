













import { Component, Mixins } from "vue-property-decorator";
import HomecheckOptionsWithValidation from "@/components/forms/inputs-generic/HomecheckOptionsWithValidation.vue";
import OrderSubmissionSection from "@/mixins/order-submission-section";

@Component({
  components: {
    HomecheckOptionsWithValidation,
  },
})
export default class OrderSubmissionCustomerHouseApproved extends Mixins(
  OrderSubmissionSection
) {}
