import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";

@Module({ name: "error", store, dynamic: true, namespaced: true })
export class ErrorModule extends VuexModule {
  model = {
    messageKey: "",
  };

  @Mutation
  private SET_MESSAGE_KEY(messageKey: string) {
    this.model.messageKey = messageKey;
  }

  @Mutation
  private CLEAR_MESSAGE_KEY() {
    this.model.messageKey = "";
  }

  get messageKey() {
    return this.model.messageKey;
  }

  @Action
  setMessageKey({ messageKey }: { messageKey: string }) {
    this.SET_MESSAGE_KEY(messageKey);
  }

  @Action
  clear() {
    this.CLEAR_MESSAGE_KEY();
  }
}

export const errorModule = getModule(ErrorModule);
