





























import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosError } from "axios";
import { DefaConfirmationResend } from "@defa-as/components";
import {
  findRouteByName,
  extractDomainFromEmailIncludingAtSymbol,
  serviceDetails,
} from "@defa-as/utils";
import { httpClient } from "@/http";
import { routes } from "@/router";
import { ROUTE_NAMES } from "@/router/route-names";

type ErrorReason =
  | "DOMAIN_NOT_ALLOWED"
  | "NOT_ACTIVATED"
  | "WAITING_FOR_DEFA_APPROVAL"
  | "DENIED_BY_DEFA"
  | "ONLY_PUBLIC_RG_ALLOWED"
  | "NOT_ALLOWED_FOR_PUBLIC_RG";

@Component({
  components: {
    DefaConfirmationResend,
  },
})
export default class LoginFormError extends Vue {
  @Prop({ required: true }) readonly error!: AxiosError;
  @Prop({ default: "" }) readonly email!: string;

  get serviceDetails() {
    return serviceDetails();
  }

  get httpClient() {
    return httpClient;
  }

  get confirmationPath() {
    return findRouteByName(routes, ROUTE_NAMES.REGISTER.CONFIRM).path;
  }

  get emailDomain() {
    return extractDomainFromEmailIncludingAtSymbol(this.email);
  }

  get isAuthenticationError() {
    return (
      this.errorResponse?.status === 401 || this.errorResponse?.status === 404
    );
  }

  get isAuthorizationError() {
    return this.errorResponse?.status === 403;
  }

  get errorResponse() {
    return this.error.response;
  }

  get errorReason(): ErrorReason | undefined {
    return this.errorResponse?.data?.error?.details?.reason;
  }

  get isDomainError() {
    return (
      this.isAuthorizationError && this.errorReason === "DOMAIN_NOT_ALLOWED"
    );
  }

  get isActivationError() {
    return this.isAuthorizationError && this.errorReason === "NOT_ACTIVATED";
  }

  get isWaitingForApprovalError() {
    return (
      this.isAuthorizationError &&
      this.errorReason === "WAITING_FOR_DEFA_APPROVAL"
    );
  }

  get isNotAllowedForPublicRGError() {
    return (
      this.isAuthorizationError &&
      this.errorReason === "NOT_ALLOWED_FOR_PUBLIC_RG"
    );
  }

  get isOnlyPublicRGAllowedError() {
    return (
      this.isAuthorizationError && this.errorReason === "ONLY_PUBLIC_RG_ALLOWED"
    );
  }

  get isDeniedError() {
    return this.isAuthorizationError && this.errorReason === "DENIED_BY_DEFA";
  }

  get errorMessagePath() {
    if (this.isOnlyPublicRGAllowedError) {
      return "login.errors.onlyPublicRGAllowed";
    }
    if (this.isNotAllowedForPublicRGError) {
      return "login.errors.notAllowedForPublicRG";
    }
    if (this.isDomainError) {
      return "login.errors.domain";
    }
    if (this.isWaitingForApprovalError) {
      return "login.errors.waitingForApproval";
    }
    if (this.isDeniedError) {
      return "login.errors.denied";
    }
    if (this.isAuthenticationError) {
      return "login.errors.authentication";
    }
    return null;
  }
}
