














































import { Component, Vue } from "vue-property-decorator";
import { activeOrderModule } from "@/store/modules/active-order";
import BaseInfo from "@/components/BaseInfo.vue";
import BaseStatus from "@/components/BaseStatus.vue";

@Component({
  components: {
    BaseInfo,
    BaseStatus,
  },
})
export default class OrderHeaderGeneralInfo extends Vue {
  get activeOrderModule() {
    return activeOrderModule;
  }

  get salesPersonDisplayName() {
    return (
      this.activeOrderModule.activeOrder.retailerSalesPerson.fullName ||
      this.activeOrderModule.activeOrder.retailerSalesPerson.email
    );
  }

  get salesPersonEmailLink() {
    const subject = this.$t("orderHeaderGeneralInfo.retailerMailToSubject", {
      orderTitle: this.activeOrderModule.activeOrder.title,
    });
    return `mailto:${this.activeOrderModule.activeOrder.retailerSalesPerson.email}?subject=${subject}`;
  }

  get salesPersonTelephoneLink() {
    return `tel:${this.activeOrderModule.activeOrder.retailerSalesPerson.telephone}`;
  }
}
