





























































import { Component, Vue } from "vue-property-decorator";
import { DefaLoaderCircle } from "@defa-as/components";
import ProgressTabInstallationPartnerLookup from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerLookup.vue";
import ProgressTabInstallationPartnerInfo from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerInfo.vue";
import ProgressTabInstallationPartnerProgress from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerProgress.vue";
import ProgressTabInstallationPartnerButtonEmail from "@/components/order/progress/installation-partner/ProgressTabInstallationPartnerButtonEmail.vue";
import { assignmentModule } from "@/store/modules/assignment";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";
import type { SetInstallationPartnerPayload } from "@/store/types/assignment";

@Component({
  components: {
    ProgressTabInstallationPartnerButtonEmail,
    ProgressTabInstallationPartnerLookup,
    ProgressTabInstallationPartnerInfo,
    ProgressTabInstallationPartnerProgress,
    DefaLoaderCircle,
  },
})
export default class ProgressTabInstallationPartner extends Vue {
  get userModule() {
    return userModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  async onChooseInstallationPartner(payload: SetInstallationPartnerPayload) {
    await this.assignmentModule.setInstallationPartner(payload);
  }
}
