







































import { Component, Vue } from "vue-property-decorator";
import { inNorway } from "@defa-as/utils";
import { DefaBaseLinkExternal } from "@defa-as/components";
import {
  adminPortalBaseUrl,
  homecheckBaseUrl,
  installationPartnerViewBaseUrl,
} from "@/config";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    DefaBaseLinkExternal,
  },
})
export default class OrderHeaderNavigation extends Vue {
  isAdmin!: boolean;

  get userModule() {
    return userModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get ipvUrl() {
    return `${installationPartnerViewBaseUrl}/${this.activeOrderModule.activeOrder.id}/${this.activeOrderModule.activeOrder.secret}`;
  }

  get homecheckUrl() {
    return `${homecheckBaseUrl}/${this.activeOrderModule.activeOrder.id}/${this.activeOrderModule.activeOrder.secret}`;
  }

  get adminPortalUrl() {
    const orderTitleWithoutFence = this.activeOrderModule.activeOrder.title.replace(
      "#",
      ""
    );
    const retailerFromOrderTitleMatch = orderTitleWithoutFence.match(
      /[A-Za-z]+(?=-)/
    );
    if (retailerFromOrderTitleMatch) {
      const retailerFromOrderTitle = retailerFromOrderTitleMatch[0].toLowerCase();
      const orderSlug = `${this.activeOrderModule.activeOrder.id}-${retailerFromOrderTitle}-0/no`;
      return `${adminPortalBaseUrl}/entries/customerOrders/${orderSlug}`;
    }
    return "";
  }

  get showAdminPortalUrl() {
    return inNorway() && this.userModule.isAdmin && this.adminPortalUrl;
  }

  onClickListItem() {
    this.$emit("close");
  }
}
