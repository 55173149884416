











































import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";
import { formatDateShort } from "@defa-as/utils";
import ModalMixin from "@/mixins/modal.mixin";
import ManualTasks from "@/components/order/progress/ManualTasks.vue";
import OrderCancellationModal from "@/components/order/progress/administrator/OrderCancellationModal.vue";
import AutomaticTask from "@/components/order/progress/AutomaticTask.vue";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    ManualTasks,
    OrderCancellationModal,
    AutomaticTask,
    DefaBaseButton,
  },
})
export default class ProgressTabAdministratorTasks extends Mixins(ModalMixin) {
  get isCancelButtonVisible() {
    return userModule.isAdmin && activeOrderModule.isNotCancelled;
  }

  get homecheckTabRoute() {
    return {
      name: ROUTE_NAMES.ORDERS.DETAILS.HOMECHECK,
    };
  }

  get visibleAutomaticTasks() {
    return this.automaticTasks.filter((task) => task.visible);
  }

  get manualTasks() {
    return [
      {
        label: this.$t("progress.administratorTasks.houseOwnerApprovalNeeded"),
        attribute: "houseOwnerApprovalNeeded",
        type: "setting",
      },
      {
        label: this.$t(
          "progress.administratorTasks.houseOwnerApprovalCompleted"
        ),
        attribute: "houseOwnerApprovalCompleted",
        type: "task",
        hidden: !activeOrderModule.activeOrderStateField(
          "houseOwnerApprovalNeeded"
        ).state,
      },
      {
        label: activeOrderModule.hardwareConsignmentSent.state
          ? this.$t("progress.administratorTasks.hardwareConsignmentSent")
          : this.$t("progress.administratorTasks.hardwareConsignmentNotSent"),
        attribute: "hardwareConsignmentSent",
        type: "task",
        hidden: !activeOrderModule.activeOrderStateField(
          "hardwareConsignmentRequested"
        ).state,
      },
    ];
  }

  get automaticTasks() {
    return [
      {
        description: this.$t(
          "progress.administratorTasks.remoteHomecheckNotSent"
        ),
        instructions:
          "progress.administratorTasks.remoteHomecheckNotSentInstructions",
        visible: !activeOrderModule.remoteHomecheckSent.state,
        isHomecheckInstructions: true,
        done: false,
      },
      {
        description: this.$t("progress.administratorTasks.waitingForHomecheck"),
        instructions:
          "progress.administratorTasks.waitingForHomecheckInstructions",
        visible:
          activeOrderModule.remoteHomecheckSent.state &&
          !activeOrderModule.remoteHomecheckCustomerSubmitted.state,
        instructionTranslationVars: {
          date: formatDateShort(
            activeOrderModule.remoteHomecheckSent.dateUpdated as Date
          ),
        },
        done: false,
      },
      {
        description: this.$t(
          "progress.administratorTasks.remoteHomecheckCompleted"
        ),
        instructions:
          "progress.administratorTasks.remoteHomecheckCompletedInstructions",
        instructionTranslationVars: {
          dateReceived: formatDateShort(
            activeOrderModule.remoteHomecheckSent.dateUpdated as Date
          ),
          dateSubmitted: formatDateShort(
            activeOrderModule.remoteHomecheckCustomerSubmitted
              .dateUpdated as Date
          ),
        },
        visible: activeOrderModule.remoteHomecheckCustomerSubmitted.state,
        done: true,
        dateUpdated:
          activeOrderModule.remoteHomecheckCustomerSubmitted.dateUpdated,
      },
      {
        description: activeOrderModule.installationPartnerRequested.state
          ? this.$t(
              "progress.administratorTasks.installationPartnerRequested",
              {
                date: formatDateShort(
                  activeOrderModule.installationPartnerRequested
                    .dateUpdated as Date
                ),
              }
            )
          : this.$t(
              "progress.administratorTasks.installationPartnerNotRequested"
            ),
        instructions: activeOrderModule.installationPartnerRequested.state
          ? ""
          : "progress.administratorTasks.installationPartnerNotRequestedInstructions",
        instructionTranslationVars: null,
        visible: true,
        done: activeOrderModule.installationPartnerRequested.state,
      },
    ];
  }

  async onConfirmOrderCancellation() {
    this.setModalLoading();
    try {
      await activeOrderModule.cancelOrder();
    } finally {
      this.hideModal();
      this.unsetModalLoading();
    }
  }
}
