



































import { Component, Vue } from "vue-property-decorator";
import {
  DefaIconCompany,
  DefaIconUser,
  DefaLoaderCircle,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import type { RetailerGroup } from "@defa-as/utils";
import { onProduction } from "@defa-as/utils";
import LoginForm from "@/components/LoginForm.vue";
import RetailerGroupSwitcher from "@/components/retailer-group/RetailerGroupSwitcher.vue";
import {
  getRetailerGroupForDomain,
  getRetailerGroupsForCurrentLocale,
  isRetailerGroupForDomainPublic,
} from "@/config/portals/retailer-groups";
import { ROUTE_NAMES } from "@/router/route-names";

@Component({
  components: {
    DefaTransitionFadeOutIn,
    DefaLoaderCircle,
    DefaIconCompany,
    DefaIconUser,
    RetailerGroupSwitcher,
    LoginForm,
  },
})
export default class ViewLogin extends Vue {
  loginView = true;
  loadingRetailerGroups = false;
  retailerGroupsForCurrentLocale: RetailerGroup[] = [];

  get isRetailerGroupForDomainPublic() {
    return onProduction() && isRetailerGroupForDomainPublic();
  }

  get viewSwitcherText() {
    return this.loginView
      ? this.$t("login.switchToRetailerGroup")
      : this.$t("login.switchToLogin");
  }

  get retailerGroupForDomain() {
    if (this.retailerGroupsForCurrentLocale.length) {
      return getRetailerGroupForDomain(this.retailerGroupsForCurrentLocale);
    }
    return null;
  }

  get registerUserLink() {
    return {
      name: ROUTE_NAMES.REGISTER.NEW,
    };
  }

  async toggleView() {
    this.loginView = !this.loginView;
    // Switching to retailer group view and has not loaded retailer groups before
    if (!this.loginView && !this.retailerGroupsForCurrentLocale.length) {
      this.loadingRetailerGroups = true;
      try {
        this.retailerGroupsForCurrentLocale = await getRetailerGroupsForCurrentLocale();
      } finally {
        this.loadingRetailerGroups = false;
      }
    }
  }
}
