import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { resetCaches } from "@/http";
import { userModule } from "@/store/modules/user";
import { submitOrderModule } from "@/store/modules/submit-order";

@Module({ name: "reset", dynamic: true, store, namespaced: true })
export class ResetModule extends VuexModule {
  @Action
  async clearState() {
    userModule.USER_RESET_USER();
    await submitOrderModule.resetModel();
    resetCaches();
  }
}

export const resetModule = getModule(ResetModule);
