



































import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import OrderSubmissionSection from "@/mixins/order-submission-section";
import InputTelephone from "@/components/forms/InputTelephone.vue";
import InputFullName from "@/components/forms/InputFullName.vue";

@Component({
  components: {
    InputTelephone,
    InputFullName,
    DefaBaseInput,
  },
})
export default class OrderSubmissionCustomerInfo extends Mixins(
  OrderSubmissionSection
) {}
