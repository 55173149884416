




















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaCircleWrapper, DefaLoaderCircle } from "@defa-as/components";

@Component({
  components: {
    DefaCircleWrapper,
    DefaLoaderCircle,
  },
})
export default class CheckTask extends Vue {
  @Prop({ default: false }) readonly value!: boolean;
  @Prop({ default: false }) readonly loading!: boolean;
}
