



















import { Component, Vue } from "vue-property-decorator";
import HomecheckAction from "@/components/order/homecheck/action/HomecheckAction.vue";
import { homecheckModule } from "@/store/modules/homecheck";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    HomecheckAction,
  },
})
export default class HomecheckNotSentStage extends Vue {
  get homecheckModule() {
    return homecheckModule;
  }

  get userModule() {
    return userModule;
  }
}
