

































import { Component, Mixins } from "vue-property-decorator";
import { DefaBaseButton, DefaModalConfirmCancel } from "@defa-as/components";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import BaseList from "@/components/BaseList.vue";
import { submitOrderModule } from "@/store/modules/submit-order";
import type { NavigationGuardNext, Route } from "vue-router";
import type { Product } from "@/store/types/submit-order";
import { ROUTE_NAMES } from "@/router/route-names";
import BaseCard from "@/components/BaseCard.vue";

@Component({
  components: {
    BaseCard,
    BaseList,
    DefaBaseButton,
    DefaModalConfirmCancel,
  },
})
export default class ViewOrderCreationAccessories extends Mixins(
  UnsavedChangesModalMixin
) {
  get addons() {
    return submitOrderModule.addonProductsForCurrentSolution;
  }

  get isCableAddon() {
    return (addon: Product) =>
      ["mode3Cable", "mode2Cable", "chargingCable"].includes(addon.type);
  }

  get isSelected() {
    return (addon: Product) => submitOrderModule.hasAddon(addon.id);
  }

  created() {
    submitOrderModule.removeUnavailableAddons({ availableAddons: this.addons });
  }

  async goToSubmission() {
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SUBMIT,
    });
  }

  onClickAddon(addon: Product) {
    if (submitOrderModule.hasAddon(addon.id)) {
      submitOrderModule.removeAddon({ addon });
    } else {
      submitOrderModule.addAddon({ addon });
    }
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    this.onLeaveOrderCreation(next, to);
  }
}
