






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TaskTimestamp extends Vue {
  @Prop({ default: "" }) readonly dateUpdated!: string;
}
