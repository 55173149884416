import type { RetailerGroup, User } from "@defa-as/utils";
import {
  inSweden,
  setUserForSentry,
  UserWithPublicRGInfo,
} from "@defa-as/utils";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { Role } from "@/store/types/user";
import store from "@/store";
import { resetModule } from "@/store/modules/reset";
import { getRetailerGroupHandleForDomain } from "@/config/portals/retailer-groups";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/route-names";
import {
  activateRetailer,
  deactivateRetailer,
  getUserInfo,
  signGDPRDocument,
} from "@/http/requests/requests-user";

const initialState = (): {
  user: User;
} => ({
  user: {
    company: "",
    email: "",
    fullName: "",
    groups: [],
    id: "",
    telephoneNumber: "",
    token: "",
    profilePic: "",
    retailers: [],
    activeRetailerId: null,
  },
});

@Module({
  name: "user",
  store,
  dynamic: true,
  namespaced: true,
  preserveState: Boolean(store.state.user),
})
export class UserModule extends VuexModule {
  user = initialState().user;

  @Mutation
  USER_SET_USER({ user }: { user: User }) {
    this.user = user;
  }

  @Mutation
  USER_RESET_USER() {
    this.user = initialState().user;
  }

  @Mutation
  USER_UNSET_ACTIVE_RETAILER_ID() {
    this.user.activeRetailerId = null;
  }

  get retailerIds() {
    return this.user.retailers.map((retailer) => retailer.id);
  }

  get token() {
    return this.user.token;
  }

  get userHasFullName() {
    return Boolean(this.userFullName);
  }

  get userFullName() {
    return this.user.fullName;
  }

  get username() {
    return this.userFullName || this.user.email;
  }

  get userEmail() {
    return this.user.email;
  }

  get isLoggedIn() {
    return Boolean(this.user.token);
  }

  get userTelephoneNumber() {
    return this.user.telephoneNumber;
  }

  get isSwedishNonAdminUser() {
    return inSweden() && !this.isAdmin;
  }

  get role(): Role {
    if (this.user.groups.includes("evPortalAdmin")) {
      return "evPortalAdmin";
    }
    if (this.user.groups.includes("evPortalSuperuser")) {
      return "evPortalSuperuser";
    }
    return "evPortalRetailer";
  }

  get isAdmin() {
    return this.role === "evPortalAdmin";
  }

  get hasRegisteredForRetailerGroupForDomain() {
    return this.hasRegisteredForRetailerGroup(
      getRetailerGroupHandleForDomain()
    );
  }

  get hasRegisteredForRetailerGroup() {
    return (retailerGroupHandle: string) =>
      this.retailerGroups.some(
        (userRetailerGroup) => userRetailerGroup.handle === retailerGroupHandle
      );
  }

  get activeRetailerHasSignedGDPRDocument() {
    return this.activeRetailer?.gdprDocumentSigned;
  }

  get hasActiveRetailer() {
    return Boolean(this.activeRetailer);
  }

  get canUsePortal() {
    if (this.isSwedishNonAdminUser) {
      return this.hasActiveRetailer && this.activeRetailerHasSignedGDPRDocument;
    }
    return this.hasActiveRetailer;
  }

  get activeRetailerGroup() {
    return this.activeRetailer?.group;
  }

  get activeRetailer() {
    return this.user.retailers?.find(
      (retailer) => retailer.id === this.user.activeRetailerId
    );
  }

  get activeRetailerGroupOutOfSyncWithDomain() {
    return (
      this.activeRetailerGroup?.handle !== getRetailerGroupHandleForDomain()
    );
  }

  get retailerIdForRetailerGroupHandle() {
    return (retailerGroupHandle: string) =>
      this.user.retailers.find(
        (retailer) => retailer.group.handle === retailerGroupHandle
      )?.id;
  }

  get retailerGroups() {
    return this.user.retailers.map((retailer) => retailer.group);
  }

  get hasMultipleRetailerGroups() {
    return this.retailerGroups.length > 1;
  }

  get adminRulingExists() {
    return (user: UserWithPublicRGInfo) =>
      user.publicRg.adminRuling === "APPROVED" ||
      user.publicRg.adminRuling === "DENIED";
  }

  @Action
  async refreshUser() {
    if (this.isLoggedIn) {
      await this.setUser({ user: await getUserInfo() });
    }
  }

  @Action
  async setUser(payload: { user: User }) {
    this.USER_SET_USER(payload);
    setUserForSentry(payload.user);
  }

  @Action
  async switchActiveRetailerId(payload: Pick<RetailerGroup, "handle">) {
    const newRetailerId = this.retailerIdForRetailerGroupHandle(payload.handle);
    if (newRetailerId) {
      const user = await activateRetailer(newRetailerId);
      return await this.setUser({ user });
    }
    throw new Error(
      `A user tried to switch their active retailer to the retailer for the retailer group with handle ${payload.handle}, while they don't have a retailer for said retailer group`
    );
  }

  @Action
  async logout() {
    await resetModule.clearState();
    await router.replace({
      name: ROUTE_NAMES.LOGIN,
    });
  }

  @Action
  async signGDPRDocument() {
    const user = await signGDPRDocument();
    await this.setUser({ user });
  }

  @Action
  async deactivateRetailer() {
    const user = await deactivateRetailer();
    await this.setUser({ user });
  }
}

export const userModule = getModule(UserModule);
