



































import { Component, Mixins, Prop } from "vue-property-decorator";
import { DefaBaseButton, DefaModalConfirmCancel } from "@defa-as/components";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import BaseList from "@/components/BaseList.vue";
import { submitOrderModule } from "@/store/modules/submit-order";
import type { NavigationGuardNext, Route } from "vue-router";
import type { CarModel } from "@/store/types/submit-order";
import BaseCard from "@/components/BaseCard.vue";
import { ROUTE_NAMES } from "@/router/route-names";
import { getModels } from "@/http/requests/requests-submit-order";

interface CarModelAsCard extends CarModel {
  subtitles: string[];
}

@Component({
  components: {
    BaseCard,
    DefaBaseButton,
    BaseList,
    DefaModalConfirmCancel,
  },
})
export default class ViewOrderCreationModels extends Mixins(
  UnsavedChangesModalMixin
) {
  @Prop({ required: true }) readonly brand!: string;
  models: CarModelAsCard[] = [];
  loading = false;

  // Only relevant for KIA demos, we should eventually get the model back from the backend
  get mapToModel() {
    return (model: CarModel) => {
      if (model.name.toLowerCase().includes("niro")) {
        return "Niro";
      }
      if (model.name.toLowerCase().includes("ceed")) {
        return "Ceed";
      }
      return model.name;
    };
  }

  get carBrandName() {
    return submitOrderModule.customerCarBrandName;
  }

  get hasNoModels() {
    return !this.loading && !this.models.length;
  }

  get sortedModels() {
    return [...this.models].sort((modelOne, modelTwo) => {
      const modelOfModelOne = this.mapToModel(modelOne);
      const modelOfModelTwo = this.mapToModel(modelTwo);
      if (modelOfModelOne === modelOfModelTwo) {
        return modelOne.name.localeCompare(modelTwo.name);
      }
      return modelOfModelOne.localeCompare(modelOfModelTwo);
    });
  }

  async created() {
    this.loading = true;
    try {
      const models = await getModels(this.brand);
      this.models = models.map(this.transformModel);
    } finally {
      this.loading = false;
    }
  }

  async onClickModel(model: CarModelAsCard) {
    submitOrderModule.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key: "carModel",
      value: model,
    });
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    });
  }

  transformModel(model: CarModel): CarModelAsCard {
    return {
      ...model,
      subtitles: this.getSubtitles(model),
    };
  }

  getSubtitles(model: CarModel) {
    return [
      this.$t("orderCreationModels.maxEffect", {
        effect: model.maxEffect / 1000,
      }) as string,
      model.carPhase,
      model.carEngineType,
      this.$t("orderCreationModels.connector", {
        connector: model.connector,
      }) as string,
    ];
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    this.onLeaveOrderCreation(next, to);
  }

  async goToSolutions() {
    await submitOrderModule.clearModelAndBrand();
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    });
  }
}
