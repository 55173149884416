import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";

export const redirectToFromRoot = () => {
  if (userModule.isLoggedIn) {
    if (userModule.isAdmin) {
      return {
        name: ROUTE_NAMES.ORDERS.LIST,
      };
    }
    return {
      name: ROUTE_NAMES.ORDERS_NEW.START,
    };
  }
  return {
    name: ROUTE_NAMES.LOGIN,
  };
};
