import { extractCountryFromHostname, onProduction } from "@defa-as/utils";

const addCountryToUrl = (url: string) => {
  if (onProduction()) {
    const country = extractCountryFromHostname();
    if (country) {
      return url.replace(/https:\/\//, `https://${country}.`);
    }
  }
  return url;
};

export const installationPartnerViewBaseUrl = addCountryToUrl(
  process.env.VUE_APP_INSTALLATION_PARTNER_VIEW_URL as string
);
export const homecheckBaseUrl = addCountryToUrl(
  process.env.VUE_APP_HOMECHECK_URL as string
);
export const adminPortalBaseUrl = process.env
  .VUE_APP_SALTO_ADMIN_UI_BASE_URL as string;
export const defaApiUrl = process.env.VUE_APP_DEFA_GRAPHQL_URL;
