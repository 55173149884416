









import { Component, Vue } from "vue-property-decorator";

@Component
export default class TheAppShell extends Vue {
  get fullWidthContent() {
    return this.$route.meta.fullWidth;
  }
}
