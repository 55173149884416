










































import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBaseButton } from "@defa-as/components";
import type { Retailer } from "@defa-as/utils";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";

@Component({
  components: {
    ValidationObserver,
    DefaBaseButton,
    BaseSelect,
  },
})
export default class RetailerForm extends Vue {
  @Prop({ required: true }) readonly retailerOptions!: Retailer[];
  @Prop({ required: true }) readonly labelSubmit!: string;
  @Prop({ default: "" }) readonly initialRetailerId!: Retailer["id"];
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: false }) readonly disableSubmitIfUnchanged!: boolean;
  model = {
    retailerId: this.initialRetailerId,
  };

  get excludeDefaultOption() {
    return Boolean(this.initialRetailerId);
  }

  get isSubmitDisabled() {
    return (
      this.loading ||
      (this.disableSubmitIfUnchanged &&
        this.initialRetailerId === this.model.retailerId)
    );
  }

  get retailerOptionsSortedAndMapped() {
    return [...this.retailerOptions]
      .sort((retailerOne, retailerTwo) =>
        retailerOne.name.localeCompare(retailerTwo.name)
      )
      .map((retailer) => ({
        ...retailer,
        value: retailer.id,
      }));
  }

  onSubmit() {
    this.$emit("submit", this.model.retailerId);
  }
}
