












import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseInput } from "@defa-as/components";
import type { Rules } from "@defa-as/utils";

@Component({
  components: { DefaBaseInput },
})
export default class InputFullName extends Vue {
  @Prop({ required: true }) readonly value!: string;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: false }) readonly darkTheme!: boolean;
  @Prop({ default: "fullName" }) readonly rules!: Rules;

  get colorProps() {
    if (this.darkTheme) {
      return {
        labelBackgroundColor: "var(--defa-oreon-color-accent)",
        color: "white",
      };
    }
    return null;
  }

  get valueForInput() {
    return this.value;
  }

  set valueForInput(value: string) {
    this.$emit("input", value);
  }

  get translatedLabel() {
    return this.label || this.$t("inputFullName.defaultLabel");
  }
}
