import Vue from "vue";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import type {
  Order,
  OrderStateField,
  OrderStateFieldKey,
  OrderStateFieldWithDate,
  OrderWithHomecheckAndAssignment,
} from "@defa-as/utils";
import { resetThrottleCache } from "@/http";
import router from "@/router";
import store from "@/store";
import { homecheckModule } from "@/store/modules/homecheck";
import { assignmentModule } from "@/store/modules/assignment";
import type { OrderFieldEvent } from "@/store/types/active-order";
import { ROUTE_NAMES } from "@/router/route-names";
import {
  cancelOrder,
  getOrder,
  refreshOrder,
  updateOrder,
} from "@/http/requests/requests-order";
import { completeAssignment } from "@/http/requests/requests-assignment";

const orderEditKeys: (keyof Order)[] = [
  "administratorNotes",
  "orderNotes",
  "customerFullName",
  "customerPhone",
  "customerEmail",
];

const initialStateField = (): OrderStateField => ({
  state: false,
  dateUpdated: null,
  loading: false,
});

const initialStateFieldWithDate = (): OrderStateFieldWithDate => ({
  ...initialStateField(),
  date: null,
});

const initialState = (): { order: Order; loading: boolean } => ({
  order: {
    id: "",
    title: "",
    orderStatus: "orderReceived",
    retailer: {
      id: "",
      name: "",
      orgNumber: "",
      town: "",
      group: {
        id: "",
        handle: "",
        name: "",
      },
    },
    retailerSalesPerson: {
      email: "",
      fullName: "",
      telephone: "",
    },
    tracking: [],
    retailerOrderRef: "",
    customerFullName: "",
    customerAddressLine1: "",
    customerPostcode: "",
    customerPlace: "",
    customerPhone: "",
    customerEmail: "",
    customerCarBrand: "",
    customerCarModel: "",
    customerCarDeliveryDate: null,
    deliveryAddress: null,
    state: {
      installationPartnerRequested: initialStateField(),
      installationPartnerConfirmed: initialStateField(),
      remoteHomeCheckSent: initialStateField(),
      remoteHomeCheckCustomerSubmitted: initialStateField(),
      remoteHomeCheckCompleted: initialStateField(),
      houseOwnerApprovalNeeded: initialStateField(),
      houseOwnerApprovalCompleted: initialStateField(),
      physicalHomeCheckNeeded: initialStateField(),
      physicalHomeCheckCompleted: initialStateFieldWithDate(),
      hardwareConsignmentRequested: initialStateField(),
      hardwareConsignmentSent: initialStateField(),
      installationScheduled: initialStateFieldWithDate(),
      installationCompleted: initialStateFieldWithDate(),
    },
    approvedByHouseOwner: "n/a",
    secret: "",
    orderLines: [],
    orderNotes: "",
    administratorNotes: "",
    created: {
      date: "",
    },
    updated: {
      date: "",
    },
  },
  loading: false,
});

@Module({ name: "activeOrder", store, dynamic: true, namespaced: true })
export class ActiveOrderModule extends VuexModule {
  order = initialState().order;
  loading = initialState().loading;

  get activeOrderId() {
    return this.order.id;
  }

  get activeOrder() {
    return this.order;
  }

  get activeOrderRetailerName() {
    return this.activeOrder.retailer?.name;
  }

  get activeOrderField() {
    return (fieldName: keyof Order) => this.order[fieldName];
  }

  get activeOrderCustomerEmail() {
    return this.order.customerEmail;
  }

  get activeOrderLoading() {
    return this.loading;
  }

  get activeOrderStateField() {
    return (stateField: OrderStateFieldKey) => this.order.state[stateField];
  }

  get installationPartnerRequested() {
    return this.activeOrderStateField("installationPartnerRequested");
  }

  get hardwareConsignmentSent() {
    return this.activeOrderStateField("hardwareConsignmentSent");
  }

  get remoteHomecheckSent() {
    return this.activeOrderStateField("remoteHomeCheckSent");
  }

  get remoteHomecheckCustomerSubmitted() {
    return this.activeOrderStateField("remoteHomeCheckCustomerSubmitted");
  }

  get isNotCancelled() {
    return this.order.orderStatus !== "revoked";
  }

  get isNotDone() {
    return this.order.orderStatus !== "done";
  }

  get hasTrackingInfo() {
    return Boolean(this.order.tracking.length);
  }

  @Mutation
  ACTIVE_ORDER_SET(order: Order) {
    this.order = order;
  }

  @Mutation
  ACTIVE_ORDER_SET_LOADING() {
    this.loading = true;
  }

  @Mutation
  ACTIVE_ORDER_UNSET_LOADING() {
    this.loading = false;
  }

  @Mutation
  ACTIVE_ORDER_RESET() {
    Object.assign(this, initialState());
  }

  @Mutation
  ACTIVE_ORDER_SET_FIELD_LOADING({
    stateField,
  }: {
    stateField: OrderStateFieldKey;
  }) {
    // Necessary for reactivity purposes, as field "loading" can be removed by backend
    Vue.set(this.order.state[stateField], "loading", true);
  }

  @Mutation
  ACTIVE_ORDER_UNSET_FIELD_LOADING({
    stateField,
  }: {
    stateField: OrderStateFieldKey;
  }) {
    // Necessary for reactivity purposes, as field "loading" can be removed by backend
    Vue.set(this.order.state[stateField], "loading", false);
  }

  @Mutation
  ACTIVE_ORDER_SET_FIELD<K extends keyof Order>(
    orderEditFieldEvent: OrderFieldEvent<K>
  ) {
    this.order[orderEditFieldEvent.key] = orderEditFieldEvent.value;
  }

  @Action
  async refreshActiveOrder() {
    resetThrottleCache();
    const order = await refreshOrder(this.activeOrderId);
    await this.setOrderData({ data: order });
  }

  @Action
  async setOrderData({ data }: { data: OrderWithHomecheckAndAssignment }) {
    const { assignment, homecheck, ...order } = data;
    this.ACTIVE_ORDER_SET(order);
  }

  @Action
  async loadActiveOrder({ orderId }: { orderId: string }) {
    this.ACTIVE_ORDER_SET_LOADING();
    try {
      const orderResponse = await getOrder(orderId);
      await this.setOrderData({ data: orderResponse });
      const { homecheck, assignment } = orderResponse;
      await homecheckModule.setHomecheckData({ data: homecheck });
      await assignmentModule.setAssignmentData({ data: assignment });
    } catch (error) {
      if (error.response?.status === 404) {
        await router.push({
          name: ROUTE_NAMES.NOT_FOUND,
        });
      }
    } finally {
      this.ACTIVE_ORDER_UNSET_LOADING();
    }
  }

  @Action
  async patchOrderState(payload: {
    stateField: OrderStateFieldKey;
    data: { state: Record<string, { state: boolean }> };
  }) {
    try {
      this.ACTIVE_ORDER_SET_FIELD_LOADING({ stateField: payload.stateField });
      const order = await updateOrder(this.activeOrderId, payload.data);
      await this.setOrderData({ data: order });
      const { assignment } = order;
      await assignmentModule.setAssignmentData({ data: assignment });
    } finally {
      this.ACTIVE_ORDER_UNSET_FIELD_LOADING({ stateField: payload.stateField });
    }
  }

  @Action
  async cancelOrder() {
    const order = await cancelOrder(this.activeOrderId);
    await this.setOrderData({ data: order });
  }

  /**
   * Shortcut to completing an installation without going through the installation partner view.
   * This was originally requested by Norway to finish old orders that had not gone through the installation process
   * This is however still being used by Norwegian admins to shortcut assignments for installation partners that can't be bothered to complete the installation process
   */
  @Action
  async completeAssignment() {
    const order = await completeAssignment(this.activeOrderId);
    await this.setOrderData({ data: order });
  }

  @Action
  async resetOrder() {
    this.ACTIVE_ORDER_RESET();
  }

  @Action
  async patchOrderFields() {
    const payload = Object.entries(this.order)
      .filter(([key]) => orderEditKeys.includes(key as keyof Order))
      .reduce(
        (payload, [key, value]) => ({
          ...payload,
          [key]: value,
        }),
        {}
      );
    return await updateOrder(this.activeOrderId, payload);
  }
}

export const activeOrderModule = getModule(ActiveOrderModule);
