







































































import { uniqBy } from "lodash-es";
import { differenceInCalendarDays, parseISO } from "date-fns";
import type { Assignment, Order, OrderLine, OrderState } from "@defa-as/utils";
import { formatDate, InstallationPartner, isElliOrder } from "@defa-as/utils";
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseStatus from "@/components/BaseStatus.vue";
import { ROUTE_NAMES } from "@/router/route-names";

export type OrderInListView = Order & {
  assignment: Pick<Assignment, "installationPartner"> & {
    installationPartner: Pick<InstallationPartner, "companyName">;
  };
  state: Pick<OrderState, "installationScheduled">;
};

@Component({
  components: {
    BaseStatus,
  },
  // Too many props are passed in from Order as it's used with v-bind
  inheritAttrs: false,
})
export default class OrderListItem extends Vue {
  @Prop({ required: true }) readonly order!: OrderInListView;

  get skuOrAxNumber() {
    return (orderLine: OrderLine) => {
      if (this.order.retailer) {
        if (isElliOrder(this.order.retailer.name)) {
          return orderLine.sku;
        }
      }
      return orderLine.axNumber;
    };
  }

  get orderLinesWithUniqueName() {
    return uniqBy(this.order.orderLines, (orderLine) => orderLine.name);
  }

  get orderLink() {
    return {
      name: ROUTE_NAMES.ORDERS.DETAILS.PROGRESS,
      params: {
        id: this.order.id,
      },
    };
  }

  get daysSinceLastUpdate() {
    return differenceInCalendarDays(
      new Date(),
      parseISO(this.order.updated.date)
    );
  }

  get timeOfDayUpdated() {
    const updatedDate = parseISO(this.order.updated.date);
    const hours = `0${updatedDate.getHours()}`.slice(-2);
    const minutes = `0${updatedDate.getMinutes()}`.slice(-2);
    return `${hours}:${minutes}`;
  }

  get customerCar() {
    if (this.order.customerCarBrand && this.order.customerCarModel) {
      return `${this.order.customerCarBrand} ${this.order.customerCarModel}`;
    }
    return null;
  }

  get customerCarDeliveryDate() {
    if (this.order.customerCarDeliveryDate) {
      return formatDate(this.order.customerCarDeliveryDate.date);
    }
    return null;
  }

  get installationScheduledFormatted() {
    if (this.order.state.installationScheduled.date) {
      return formatDate(this.order.state.installationScheduled.date);
    }
    return null;
  }
}
