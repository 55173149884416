































import { Component, Mixins } from "vue-property-decorator";
import type { NavigationGuardNext, Route } from "vue-router";
import { DefaBaseButton, DefaModalConfirmCancel } from "@defa-as/components";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import BaseList from "@/components/BaseList.vue";
import { submitOrderModule } from "@/store/modules/submit-order";
import { ROUTE_NAMES } from "@/router/route-names";
import BaseCard from "@/components/BaseCard.vue";
import { CarBrand } from "@/store/types/submit-order";
import { getCarBrands } from "@/http/requests/requests-submit-order";

@Component({
  components: {
    BaseCard,
    DefaBaseButton,
    BaseList,
    DefaModalConfirmCancel,
  },
})
export default class ViewOrderCreationBrands extends Mixins(
  UnsavedChangesModalMixin
) {
  brands: CarBrand[] = [];
  loading = false;

  get submitOrderModule() {
    return submitOrderModule;
  }

  get sortedBrands() {
    return [...this.brands].sort((brandOne, brandTwo) =>
      brandOne.name.localeCompare(brandTwo.name)
    );
  }

  async created() {
    this.loading = true;
    try {
      const carBrands = await getCarBrands();
      if (carBrands.length === 1) {
        return await this.chooseBrand(carBrands[0]);
      }
      this.brands = carBrands;
    } finally {
      this.loading = false;
    }
  }

  async chooseBrand(brand: CarBrand) {
    this.submitOrderModule.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key: "carBrand",
      value: brand,
    });
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.MODELS,
      params: {
        brand: brand.slug,
      },
    });
  }

  async goToSolutions() {
    await this.submitOrderModule.clearModelAndBrand();
    await this.$router.push({
      name: ROUTE_NAMES.ORDERS_NEW.SOLUTIONS,
    });
  }

  beforeRouteLeave(to: Route, _: Route, next: NavigationGuardNext) {
    this.onLeaveOrderCreation(next, to);
  }
}
