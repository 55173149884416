


















import { Component, Vue } from "vue-property-decorator";
import { DefaBaseInput, DefaBaseTextarea } from "@defa-as/components";
import { submitOrderModule } from "@/store/modules/submit-order";

@Component({
  components: {
    DefaBaseInput,
    DefaBaseTextarea,
  },
})
export default class OrderSubmissionRetailer extends Vue {
  get retailerOrderRef() {
    return submitOrderModule.retailerOrderRef;
  }

  set retailerOrderRef(value: string) {
    submitOrderModule.SUBMIT_ORDER_SET_RETAILER_ORDER_REF({
      retailerOrderRef: value,
    });
  }

  get orderNotes() {
    return submitOrderModule.orderNotes;
  }

  set orderNotes(value: string) {
    submitOrderModule.SUBMIT_ORDER_SET_ORDER_NOTES({ orderNotes: value });
  }
}
