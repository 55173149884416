

















































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import {
  DefaBaseLinkExternal,
  DefaBaseTextarea,
  DefaIconHouseOwnerApproved,
  DefaIconHouseOwnerNA,
  DefaIconHouseOwnerRejected,
  DefaTrackingInformation,
} from "@defa-as/components";
import { activeOrderModule } from "@/store/modules/active-order";
import { userModule } from "@/store/modules/user";
import { assignmentModule } from "@/store/modules/assignment";
import { DeliveryAddress } from "@defa-as/utils";

@Component({
  components: {
    DefaTrackingInformation,
    DefaIconHouseOwnerApproved,
    DefaIconHouseOwnerRejected,
    DefaIconHouseOwnerNA,
    DefaBaseLinkExternal,
    DefaBaseTextarea,
    ValidationProvider,
  },
})
export default class OrderContentInstallation extends Vue {
  get activeOrderModule() {
    return activeOrderModule;
  }

  get assignmentModule() {
    return assignmentModule;
  }

  get userModule() {
    return userModule;
  }

  get administratorNotes() {
    return this.activeOrderModule.activeOrderField(
      "administratorNotes"
    ) as string;
  }

  set administratorNotes(value: string) {
    this.activeOrderModule.ACTIVE_ORDER_SET_FIELD({
      key: "administratorNotes",
      value,
    });
  }

  get deliveryAddress(): DeliveryAddress | null {
    if (
      this.activeOrderModule.activeOrder.deliveryAddress &&
      this.activeOrderModule.activeOrder.deliveryAddress.street.length
    ) {
      return this.activeOrderModule.activeOrder.deliveryAddress;
    }
    return null;
  }

  get orderNotes() {
    return this.activeOrderModule.activeOrderField("orderNotes") as string;
  }

  set orderNotes(value: string) {
    this.activeOrderModule.ACTIVE_ORDER_SET_FIELD({ key: "orderNotes", value });
  }

  get houseOwnerApprovedIcon() {
    switch (this.houseOwnerApprovalStatus) {
      case "approved":
        return DefaIconHouseOwnerApproved;
      case "rejected":
        return DefaIconHouseOwnerRejected;
      case "n/a":
        return DefaIconHouseOwnerNA;
      default:
        return DefaIconHouseOwnerNA;
    }
  }

  // TODO: Remove when this is handled by server-side
  get houseOwnerApprovalStatus() {
    if (
      this.activeOrderModule.activeOrder.state.houseOwnerApprovalNeeded.state
    ) {
      return this.activeOrderModule.activeOrder.state
        .houseOwnerApprovalCompleted.state
        ? "approved"
        : "rejected";
    }
    return this.activeOrderModule.activeOrder.approvedByHouseOwner;
  }

  get locationMapsLink() {
    return `https://maps.google.com/?q=${this.activeOrderModule.activeOrder.customerAddressLine1},${this.activeOrderModule.activeOrder.customerPostcode}`;
  }
}
