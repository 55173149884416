










import { Component, Prop, Vue } from "vue-property-decorator";
import type { OrderStatus } from "@defa-as/utils";
import { getOrderStatuses, Option } from "@defa-as/utils";
import { orderStatusNone } from "@/config/statuses";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";

@Component({
  components: {
    BaseSelect,
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
})
export default class OrderListStatusFilter extends Vue {
  @Prop({ required: true }) readonly modelValue!: OrderStatus | "none";

  get value() {
    return this.modelValue || this.statusOptions[0].value;
  }

  get statusOptions(): Option<OrderStatus | "none">[] {
    const orderStatusesWithLabel = getOrderStatuses().map((status) => ({
      value: status,
      label: this.$t(`shared.options.orderStatus.${status}`) as string,
    }));
    return [
      {
        value: orderStatusNone,
        label: this.$t(
          `shared.options.orderStatus.${orderStatusNone}`
        ) as string,
      },
      ...orderStatusesWithLabel,
    ];
  }

  onChange(status: OrderStatus | "none") {
    this.$emit("change", status === "none" ? "" : status);
  }
}
