






































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton, DefaRetailerGroupLogo } from "@defa-as/components";
import type { RetailerGroup } from "@defa-as/utils";
import { onProduction, setRetailerGroupInLocalStorage } from "@defa-as/utils";
import { userModule } from "@/store/modules/user";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";
import { originForRetailerGroup } from "@/config/portals/retailer-groups";

@Component({
  components: {
    DefaRetailerGroupLogo,
    DefaBaseButton,
    BaseSelect,
  },
})
export default class RetailerGroupSwitcher extends Vue {
  // prettier-ignore
  @Prop({ default: () => [] }) readonly availableRetailerGroups!: RetailerGroup[];
  // prettier-ignore
  @Prop({ default: () => userModule.activeRetailerGroup }) readonly currentRetailerGroup!: RetailerGroup;
  @Prop({ default: false }) readonly activateRetailerId!: boolean;
  selectedRetailerGroupId = this.currentRetailerGroup.id;
  loading = false;

  get selectedRetailerGroup() {
    return this.availableRetailerGroupsSorted.find(
      (retailerGroup) => retailerGroup.id === this.selectedRetailerGroupId
    );
  }

  get retailerGroupLogoDimensions() {
    return {
      height: "30",
      width: "30",
    };
  }

  get hasAvailableRetailerGroups() {
    return Boolean(this.availableRetailerGroups.length);
  }

  get availableRetailerGroupsSorted() {
    return [...this.availableRetailerGroups]
      .sort((retailerGroupOne, retailerGroupTwo) =>
        retailerGroupOne.name.localeCompare(retailerGroupTwo.name)
      )
      .map((retailerGroup) => ({
        ...retailerGroup,
        value: retailerGroup.id,
      }));
  }

  get currentRetailerGroupIsSelected() {
    return this.selectedRetailerGroup?.id === this.currentRetailerGroup.id;
  }

  async onRetailerGroupClick() {
    if (this.selectedRetailerGroup) {
      this.loading = true;
      try {
        const shouldSwitchActiveRetailerId =
          this.activateRetailerId &&
          userModule.hasRegisteredForRetailerGroup(
            this.selectedRetailerGroup.handle
          );
        const shouldDeactivateRetailer =
          this.activateRetailerId &&
          !userModule.hasRegisteredForRetailerGroup(
            this.selectedRetailerGroup.handle
          );
        if (shouldSwitchActiveRetailerId) {
          await userModule.switchActiveRetailerId({
            handle: this.selectedRetailerGroup.handle,
          });
        }
        if (!onProduction()) {
          setRetailerGroupInLocalStorage(this.selectedRetailerGroup.handle);
        }
        if (shouldDeactivateRetailer) {
          // Deactivate retailer so that vue-router redirects the user straight to /connect-retailer after switching location
          await userModule.deactivateRetailer();
        }
        window.location.assign(
          originForRetailerGroup(this.selectedRetailerGroup.handle)
        );
      } finally {
        this.loading = false;
      }
    }
  }
}
