


































import { Component, Vue } from "vue-property-decorator";
import HomecheckAction from "@/components/order/homecheck/action/HomecheckAction.vue";
import { homecheckModule } from "@/store/modules/homecheck";
import { userModule } from "@/store/modules/user";
import { activeOrderModule } from "@/store/modules/active-order";

@Component({
  components: {
    HomecheckAction,
  },
})
export default class HomecheckSentStage extends Vue {
  get homecheckModule() {
    return homecheckModule;
  }

  get activeOrderModule() {
    return activeOrderModule;
  }

  get userModule() {
    return userModule;
  }

  get remoteHomecheckSentDateUpdated() {
    return this.activeOrderModule.activeOrderStateField("remoteHomeCheckSent")
      .dateUpdated;
  }
}
