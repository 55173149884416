


















import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaLoaderCircle } from "@defa-as/components";

@Component({
  components: {
    DefaLoaderCircle,
  },
})
export default class BaseList extends Vue {
  @Prop({ required: true }) readonly heading!: string;
  @Prop({ default: false }) readonly loading!: boolean;
}
