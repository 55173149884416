



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class ChangelogTab extends Vue {
  get changes() {
    return [
      {
        id: "232432",
        timestamp: new Date(),
        field: "customerEmail",
        user: "robin@oreon.io",
        oldValue: "joske@vermeulen.co",
        newValue: "joske@vermeulen.com",
      },
      {
        id: "232433",
        timestamp: new Date(),
        field: "customerEmail",
        user: "robin@oreon.io",
        oldValue: "joske@vermeulen.co",
        newValue: "joske@vermeulen.com",
      },
    ];
  }
}
