

















import { Component, Vue } from "vue-property-decorator";
import ProgressTabAdministratorTasks from "@/components/order/progress/administrator/ProgressTabAdministratorTasks.vue";
import ProgressItem from "@/components/order/progress/ProgressItem.vue";
import { userModule } from "@/store/modules/user";
import { progressModule } from "@/store/modules/progress";

@Component({
  components: {
    ProgressTabAdministratorTasks,
    ProgressItem,
  },
})
export default class ProgressTabAdministrator extends Vue {
  get userModule() {
    return userModule;
  }

  get progressModule() {
    return progressModule;
  }
}
