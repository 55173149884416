















































import { Component, Vue } from "vue-property-decorator";
import { DefaBaseLinkExternal } from "@defa-as/components";
import { getCountry } from "@defa-as/utils";
import { userModule } from "@/store/modules/user";
import { adminPortalBaseUrl } from "@/config";

@Component({
  components: {
    DefaBaseLinkExternal,
  },
})
export default class ProfileHeader extends Vue {
  get linkToEVPortalBackendAdmin() {
    return `${adminPortalBaseUrl}/defalocaleanalyzer/v3/dashboard?page=landing&content=home&countryCode=${getCountry()}`;
  }

  get activeRetailerName() {
    return this.userModule.activeRetailer?.name;
  }

  get activeRetailerGroupName() {
    return this.userModule.activeRetailerGroup?.name;
  }

  get userRoleTranslated() {
    return this.$t(`profileHeader.role.${this.userModule.role}`);
  }

  get userModule() {
    return userModule;
  }
}
