







import { Component, Vue } from "vue-property-decorator";
import ProgressTabInstallationPartner from "@/components/order/progress/installation-partner/ProgressTabInstallationPartner.vue";
import ProgressTabAdministrator from "@/components/order/progress/administrator/ProgressTabAdministrator.vue";

@Component({
  components: {
    ProgressTabAdministrator,
    ProgressTabInstallationPartner,
  },
})
export default class TabProgress extends Vue {}
