import type { OrderStatus } from "@defa-as/utils";

const orderStatusesStandard: OrderStatus[] = [
  "installationAgreed",
  "orderReceived",
  "installationPartnerChosen",
  "forwardedToInstaller",
  "remoteHomeCheckSent",
  "remoteHomeCheckCompleted",
  "installationPartnerConfirmed",
  "hardwareConsignmentCompleted",
];
const orderStatusesFailed: OrderStatus[] = [
  "revoked",
  "deleted",
  "installationPartnerRejected",
];
const orderStatusesDone: OrderStatus[] = ["completed", "done"];

export const getTypeForOrderStatus = (orderStatus: OrderStatus) => {
  if (orderStatusesStandard.includes(orderStatus)) {
    return "standard";
  }
  if (orderStatusesFailed.includes(orderStatus)) {
    return "failed";
  }
  if (orderStatusesDone.includes(orderStatus)) {
    return "done";
  }
  return "other";
};

export const orderStatusNone = "none";
