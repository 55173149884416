





























import { Component, Vue } from "vue-property-decorator";
import { setMainAndBackupTranslations } from "@defa-as/utils";
import {
  DefaLanguageSwitcher,
  DefaNotificationError,
  DefaTestHeader,
  DefaAlertHeader,
  DefaFullPageLoader,
  DefaFooter,
} from "@defa-as/components";
import i18n from "@/i18n";
import TheAppHeader from "@/components/TheAppHeader.vue";
import TheAppShell from "@/components/TheAppShell.vue";
import { userModule } from "@/store/modules/user";
import { errorModule } from "@/store/modules/error";
import RetailerGroupModalVerification from "@/components/retailer-group/RetailerGroupModalVerification.vue";
import RetailerGroupModalDeactivateRetailer from "@/components/retailer-group/RetailerGroupModalDeactivateRetailer.vue";
import NotificationRetailerGroup from "@/components/NotificationRetailerGroup.vue";

@Component({
  components: {
    NotificationRetailerGroup,
    RetailerGroupModalDeactivateRetailer,
    RetailerGroupModalVerification,
    TheAppHeader,
    TheAppShell,
    DefaNotificationError,
    DefaLanguageSwitcher,
    DefaTestHeader,
    DefaAlertHeader,
    DefaFullPageLoader,
    DefaFooter,
  },
})
export default class App extends Vue {
  languageLoading = false;

  get activeRetailerGroupHandle() {
    return this.userModule.activeRetailerGroup?.handle;
  }

  get activeRetailerGroupName() {
    return this.userModule.activeRetailerGroup?.name;
  }

  get isRetailerGroupModalVerificationVisible() {
    return (
      userModule.isLoggedIn &&
      userModule.activeRetailerGroupOutOfSyncWithDomain &&
      userModule.hasRegisteredForRetailerGroupForDomain
    );
  }

  get isRetailerGroupModalDeactivateRetailerVisible() {
    return (
      userModule.canUsePortal &&
      !userModule.hasRegisteredForRetailerGroupForDomain
    );
  }

  get userModule() {
    return userModule;
  }

  get errorModule() {
    return errorModule;
  }

  async created() {
    try {
      this.languageLoading = true;
      await setMainAndBackupTranslations(i18n);
    } finally {
      this.languageLoading = false;
    }
    await this.userModule.refreshUser();
  }
}
