import { Component, Vue } from "vue-property-decorator";
import { submitOrderModule } from "@/store/modules/submit-order";
import { Customer } from "@/store/types/submit-order";

@Component
export default class OrderSubmissionSection extends Vue {
  get submitOrderModule() {
    return submitOrderModule;
  }

  onCustomerInput<K extends keyof Customer>(key: K, value: Customer[K]) {
    this.submitOrderModule.SUBMIT_ORDER_SET_CUSTOMER_FIELD({
      key,
      value,
    });
  }
}
