import { Component, Vue } from "vue-property-decorator";

@Component
export default class ModalMixin extends Vue {
  modalShown = false;
  modalLoading = false;

  showModal() {
    this.modalShown = true;
  }

  hideModal() {
    this.modalShown = false;
  }

  setModalLoading() {
    this.modalLoading = true;
  }

  unsetModalLoading() {
    this.modalLoading = false;
  }
}
