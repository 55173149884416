





















import { Component, Vue } from "vue-property-decorator";
import {
  DefaIconCompany,
  DefaIconUser,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { userModule } from "@/store/modules/user";
import ProfileGeneralInfo from "@/components/profile/ProfileGeneralInfo.vue";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import ProfileRetailerInfo from "@/components/profile/ProfileRetailerInfo.vue";
import { isRetailerGroupForDomainPublic } from "@/config/portals/retailer-groups";

@Component({
  components: {
    ProfileHeader,
    ProfileGeneralInfo,
    ProfileRetailerInfo,
    DefaTransitionFadeOutIn,
    DefaIconUser,
    DefaIconCompany,
  },
})
export default class ViewProfile extends Vue {
  profileView = true;

  get isProfileViewSwitcherVisible() {
    return !isRetailerGroupForDomainPublic() || userModule.isAdmin;
  }

  get userModule() {
    return userModule;
  }

  get profileSwitcherTranslation() {
    return this.profileView
      ? this.$t("profile.switcher.toRetailer")
      : this.$t("profile.switcher.toGeneral");
  }

  toggleView() {
    this.profileView = !this.profileView;
  }
}
