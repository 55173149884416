










































import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import {
  DefaLoaderCircle,
  DefaModalConfirmCancel,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { NavigationGuardNext } from "vue-router/types/router";
import OrderHeader from "@/components/OrderHeader.vue";
import OrderContent from "@/components/OrderContent.vue";
import OrderTabs from "@/components/order/tabs/OrderTabs.vue";
import { activeOrderModule } from "@/store/modules/active-order";
import { homecheckModule } from "@/store/modules/homecheck";
import UnsavedChangesModalMixin from "@/mixins/unsaved-changes-modal.mixin";
import type { Route } from "vue-router";

@Component({
  components: {
    DefaModalConfirmCancel,
    ValidationObserver,
    DefaLoaderCircle,
    DefaTransitionFadeOutIn,
    OrderHeader,
    OrderContent,
    OrderTabs,
  },
})
export default class ViewOrder extends Mixins(UnsavedChangesModalMixin) {
  @Prop({ required: true }) readonly id!: string;
  onConfirmLeave!: () => Promise<void>;
  leavingModalShown!: boolean;
  @Ref() readonly validationObserver!: InstanceType<typeof ValidationObserver>;
  loading = {
    saveOrder: false,
    saveOrderFromModal: false,
    completeAssignment: false,
  };

  get activeOrderModule() {
    return activeOrderModule;
  }

  get homecheckModule() {
    return homecheckModule;
  }

  async created() {
    await this.activeOrderModule.loadActiveOrder({ orderId: this.id });
  }

  async destroyed() {
    await this.activeOrderModule.resetOrder();
  }

  async saveOrder(resetFormValidation: () => void) {
    this.loading.saveOrder = true;
    try {
      await this.activeOrderModule.patchOrderFields();
      resetFormValidation();
    } finally {
      this.loading.saveOrder = false;
    }
  }

  async onConfirmSave(
    resetFormValidation: () => void,
    validate: () => Promise<boolean>
  ) {
    this.loading.saveOrderFromModal = true;
    try {
      if (await validate()) {
        await this.activeOrderModule.patchOrderFields();
        resetFormValidation();
        await this.onConfirmLeave();
      } else {
        this.leavingModalShown = false;
      }
    } finally {
      this.loading.saveOrderFromModal = false;
    }
  }

  async onClickCompleteAssignment() {
    this.loading.completeAssignment = true;
    try {
      await this.activeOrderModule.completeAssignment();
    } finally {
      this.loading.completeAssignment = false;
    }
  }

  beforeRouteLeave(_: Route, __: Route, next: NavigationGuardNext) {
    if (this.validationObserver.flags.dirty && !this.leavingModalShown) {
      this.onLeave(next);
    } else {
      next();
    }
  }
}
