import { render, staticRenderFns } from "./ProgressTabInstallationPartnerInfo.vue?vue&type=template&id=28c3a922&scoped=true&"
import script from "./ProgressTabInstallationPartnerInfo.vue?vue&type=script&lang=ts&"
export * from "./ProgressTabInstallationPartnerInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ProgressTabInstallationPartnerInfo.vue?vue&type=style&index=0&id=28c3a922&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c3a922",
  null
  
)

export default component.exports