












































import type { Nullable } from "@defa-as/utils";
import { Component, Vue } from "vue-property-decorator";
import {
  DefaBaseButton,
  DefaBaseInput,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import { login } from "@/http/requests/requests-user";
import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";
import LoginFormError from "@/components/LoginFormError.vue";
import type { AxiosError } from "axios";

@Component({
  components: {
    LoginFormError,
    DefaBaseButton,
    DefaBaseInput,
    DefaTransitionFadeOutIn,
  },
})
export default class LoginForm extends Vue {
  loading = false;
  email = "";
  password = "";
  error = {
    value: null as Nullable<AxiosError>,
    email: "",
  };

  get recoverPasswordLink() {
    return {
      name: ROUTE_NAMES.RECOVER_PASSWORD.EMAIL,
    };
  }

  async login() {
    this.loading = true;
    try {
      const user = await login({ email: this.email, password: this.password });
      await userModule.setUser({ user });
      await this.navigateUser();
    } catch (error) {
      this.error.value = error;
      this.error.email = this.email;
    } finally {
      this.loading = false;
    }
  }

  async navigateUser() {
    const redirectTo = this.$route.query["redirect-to"];
    if (userModule.hasActiveRetailer) {
      return await this.$router.replace(
        typeof redirectTo === "string"
          ? redirectTo
          : {
              name: ROUTE_NAMES.ROOT,
            }
      );
    }
    return await this.$router.replace({
      name: ROUTE_NAMES.CONNECT_RETAILER,
    });
  }
}
