
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OrderListPipelineFilterItem extends Vue {
  @Prop({ required: true }) readonly label!: string;
  @Prop({ required: true }) readonly amount!: number;
  @Prop({ required: true }) readonly pipelineFilter!: string;
  @Prop({ default: false }) readonly selected!: boolean;

  get amountTitle() {
    return this.amount > 1000 ? this.amount : null;
  }

  get amountIsZero() {
    return this.amount === 0;
  }

  get displayAmount() {
    if (this.amount >= 1000) {
      const amountAsString = this.amount.toString();
      // Replaces e.g. "9453" by "9K+"
      return `${amountAsString.slice(0, amountAsString.length - 3)}K+`;
    }
    return this.amount;
  }

  onClick() {
    if (!this.amountIsZero) {
      this.$emit("click", { pipelineFilter: this.pipelineFilter });
    }
  }
}
