















import { DefaBaseButton } from "@defa-as/components";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    DefaBaseButton,
  },
})
export default class ButtonPair extends Vue {
  @Prop({ required: true }) readonly primaryLabel!: string;
  @Prop({ required: true }) readonly secondaryLabel!: string;
  @Prop({ default: false }) readonly loading!: boolean;
}
