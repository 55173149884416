














import { Component, Prop, Vue } from "vue-property-decorator";
import { debounce } from "lodash-es";

@Component
export default class OrderListSearch extends Vue {
  @Prop({ required: true }) readonly value!: string;

  readonly onDebouncedInput = debounce(async function (
    this: OrderListSearch,
    value: string
  ) {
    this.$emit("input:debounced", { search: value });
  },
  300);
}
