import { render, staticRenderFns } from "./OrderHeaderGeneralInfo.vue?vue&type=template&id=39d8873c&scoped=true&"
import script from "./OrderHeaderGeneralInfo.vue?vue&type=script&lang=ts&"
export * from "./OrderHeaderGeneralInfo.vue?vue&type=script&lang=ts&"
import style0 from "./OrderHeaderGeneralInfo.vue?vue&type=style&index=0&id=39d8873c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d8873c",
  null
  
)

export default component.exports