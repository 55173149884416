






import { Component, Prop, Vue } from "vue-property-decorator";
import { getTypeForOrderStatus } from "@/config/statuses";
import type { OrderStatus } from "@defa-as/utils";

@Component
export default class BaseStatus extends Vue {
  @Prop({ required: true }) readonly status!: OrderStatus;

  get label() {
    return this.$t(`shared.options.orderStatus.${this.status}`);
  }

  get statusClass() {
    return `base-status--${getTypeForOrderStatus(this.status)}`;
  }
}
