import { assignmentModule } from "@/store/modules/assignment";

export const visibilityInspectionSuccessful = () =>
  assignmentModule.assignmentTimestamps.get("inspectionRequired") &&
  !assignmentModule.assignmentTimestamps.get("inspectionFailed");

export const visibilityInstallationSuccessful = () =>
  assignmentNotRejectedOrInspectionNotFailed() &&
  !assignmentModule.assignmentTimestamps.get("installationFailed");

export const visibilityFutureTimestamp = () =>
  assignmentNotRejectedOrInspectionNotFailed();

const assignmentNotRejectedOrInspectionNotFailed = () =>
  !(
    assignmentModule.assignmentTimestamps.get("assignmentRejected") ||
    assignmentModule.assignmentTimestamps.get("inspectionFailed")
  );
