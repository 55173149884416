import { render, staticRenderFns } from "./AutomaticTask.vue?vue&type=template&id=48b69632&scoped=true&"
import script from "./AutomaticTask.vue?vue&type=script&lang=ts&"
export * from "./AutomaticTask.vue?vue&type=script&lang=ts&"
import style0 from "./AutomaticTask.vue?vue&type=style&index=0&id=48b69632&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b69632",
  null
  
)

export default component.exports