







































import { Component, Prop, Vue } from "vue-property-decorator";
import type { InstallationPartner, Nullable } from "@defa-as/utils";
import { activeOrderModule } from "@/store/modules/active-order";

@Component
export default class ProgressTabInstallationPartnerInfo extends Vue {
  @Prop({ required: true }) installationPartner!: Nullable<InstallationPartner>;

  get installationPartnerMailTo() {
    if (this.installationPartner) {
      const subject = this.$t(
        "progress.installationPartner.installationPartnerMailToSubject",
        { orderTitle: activeOrderModule.activeOrder.title }
      );
      return `mailto:${this.installationPartner.email}?subject=${subject}`;
    }
    return null;
  }
}
