import { userModule } from "@/store/modules/user";
import { ROUTE_NAMES } from "@/router/route-names";
import type { NavigationGuard, RawLocation, Route } from "vue-router";
import { submitOrderModule } from "@/store/modules/submit-order";
import { Nullable } from "@defa-as/utils";

const authRequired = (route: Route) =>
  route.matched.some((routeRecord) => routeRecord.meta.requiresAuth);
const notAlreadyOnWayToNewRoute = (
  route: Route,
  newRoute: Pick<Route, "name">
) => route.name !== newRoute.name;

export const navigationGuardBeforeEach: NavigationGuard = (to, from, next) => {
  let newRoute: Nullable<RawLocation & { name: string }> = null;
  if (authRequired(to)) {
    if (userModule.isLoggedIn) {
      if (userModule.hasActiveRetailer) {
        if (
          userModule.isSwedishNonAdminUser &&
          !userModule.activeRetailerHasSignedGDPRDocument
        ) {
          newRoute = {
            name: ROUTE_NAMES.GDPR_DOCUMENT_CONFIRMATION,
            query: {
              gdprDocumentUpdated: "true",
            },
          };
        }
      } else {
        newRoute = {
          name: ROUTE_NAMES.CONNECT_RETAILER,
        };
      }
    } else {
      newRoute = {
        name: ROUTE_NAMES.LOGIN,
        query: {
          "redirect-to": to.fullPath,
        },
      };
    }
  }
  if (newRoute && notAlreadyOnWayToNewRoute(to, newRoute)) {
    const fromRedirectTo = from.query["redirect-to"];
    next({
      ...newRoute,
      query: {
        ...newRoute.query,
        ...(fromRedirectTo
          ? {
              "redirect-to": fromRedirectTo,
            }
          : null),
      },
    });
    return;
  }
  next();
};

export const navigationGuardModel: NavigationGuard = (to, _, next) => {
  const carBrandInRoute = to.params?.brand;
  if (
    carBrandInRoute &&
    submitOrderModule.customerCarBrandSlug === carBrandInRoute
  ) {
    return next();
  }
  next({
    name: ROUTE_NAMES.ORDERS_NEW.START,
  });
};

export const navigationGuardHasChosenSolution: NavigationGuard = (
  _,
  from,
  next
) => {
  if (submitOrderModule.hasChosenSolution) {
    return next();
  }
  next({
    name: ROUTE_NAMES.ORDERS_NEW.START,
  });
};

export const navigationGuardNotLoggedIn: NavigationGuard = (_, __, next) => {
  if (userModule.isLoggedIn) {
    return next({
      name: ROUTE_NAMES.PROFILE,
    });
  }
  next();
};

export const navigationGuardAdmin: NavigationGuard = (_, from, next) => {
  if (userModule.isAdmin) {
    return next();
  }
  next({
    name: ROUTE_NAMES.ORDERS.LIST,
  });
};

export const navigationGuardRetailerConnection: NavigationGuard = (
  _,
  __,
  next
) => {
  if (userModule.hasActiveRetailer) {
    return next({
      name: ROUTE_NAMES.ROOT,
    });
  }
  next();
};
