



















import { Component, Vue } from "vue-property-decorator";
import {
  DefaComments,
  DefaFullPageLoader,
  DefaTransitionFadeOutIn,
} from "@defa-as/components";
import {
  addComment,
  getCommentsForOrder,
  markCommentAsRead,
} from "@defa-as/utils";
import type { Comment } from "@defa-as/utils";
import { assignmentModule } from "@/store/modules/assignment";
import { activeOrderModule } from "@/store/modules/active-order";
import { httpClient } from "@/http";

@Component({
  components: {
    DefaComments,
    DefaFullPageLoader,
    DefaTransitionFadeOutIn,
  },
})
export default class TabCommentsInstallation extends Vue {
  comments: Comment[] = [];
  loading = {
    getComments: false,
    addComment: false,
  };

  async created() {
    this.loading.getComments = true;
    try {
      this.comments = await getCommentsForOrder(
        httpClient,
        activeOrderModule.activeOrderId
      );
    } finally {
      this.loading.getComments = false;
    }
  }

  get hasNoInstallationPartner() {
    return !assignmentModule.hasInstallationPartner;
  }

  get commentAnchorToScrollTo() {
    return this.$route.hash;
  }

  get chatInputTitle() {
    return this.hasNoInstallationPartner
      ? this.$t("commentsInstallation.chatDisabled.title")
      : null;
  }

  async onSend(chatMessage: Comment["content"]) {
    this.loading.addComment = true;
    try {
      const commentNew = await addComment(
        httpClient,
        activeOrderModule.activeOrderId,
        {
          content: chatMessage,
        }
      );
      this.comments = [...this.comments, commentNew];
    } finally {
      this.loading.addComment = false;
    }
  }

  async onRead({ comment }: { comment: Comment }) {
    const commentUpdated = await markCommentAsRead(
      httpClient,
      activeOrderModule.activeOrderId,
      comment
    );

    const mergeArrayWithObject = (arr: Comment[], obj: Comment) =>
      arr && arr.map((t: Comment) => (t.id === obj.id ? obj : t));
    this.comments = mergeArrayWithObject(this.comments, commentUpdated);
  }
}
