

















































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DefaCircleWrapper,
  DefaIconCaret,
  DefaTransitionExpand,
} from "@defa-as/components";

@Component({
  components: {
    DefaTransitionExpand,
    DefaIconCaret,
    DefaCircleWrapper,
  },
})
export default class ProgressItem extends Vue {
  @Prop({ required: true }) readonly index!: number;
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly completed!: boolean;
  @Prop({ default: "" }) readonly time!: string;
  @Prop({ default: () => [] }) readonly payloadItems!: { value: string }[];
  showPayloadItems = false;

  get caretDirection() {
    return this.showPayloadItems ? "up" : "down";
  }

  get hasPayloadItems() {
    return Boolean(this.payloadItems?.length);
  }

  togglePayload() {
    this.showPayloadItems = !this.showPayloadItems;
  }
}
