












































































import { Component, Vue } from "vue-property-decorator";
import {
  DefaIconList,
  DefaIconPlus,
  DefaIconStats,
  DefaIconUser,
  DefaRetailerGroupLogo,
  DefaBaseLinkExternal,
} from "@defa-as/components";
import { userModule } from "@/store/modules/user";
import { getRetailerGroupHandleForDomain } from "@/config/portals/retailer-groups";
import { ROUTE_NAMES } from "@/router/route-names";
import { redirectToFromRoot } from "@/router/route-redirection";
import { adminPortalBaseUrl } from "@/config";
import { getCountry } from "@defa-as/utils";

@Component({
  components: {
    DefaIconUser,
    DefaIconList,
    DefaIconStats,
    DefaIconPlus,
    DefaRetailerGroupLogo,
    DefaBaseLinkExternal,
  },
})
export default class TheAppHeader extends Vue {
  get userModule() {
    return userModule;
  }

  get logoComponent() {
    const { name: redirectRouteName } = redirectToFromRoot();
    if (redirectRouteName === this.$route.name) {
      return "div";
    }
    return "router-link";
  }

  get linkToEVPortalBackendAdmin() {
    return `${adminPortalBaseUrl}/defalocaleanalyzer/v3/dashboard?page=landing&content=home&countryCode=${getCountry()}`;
  }

  get logoLink() {
    return this.logoComponent === "router-link"
      ? {
          name: ROUTE_NAMES.ROOT,
        }
      : null;
  }

  get profileLink() {
    return {
      name: ROUTE_NAMES.PROFILE,
    };
  }

  get submitOrderCarsLink() {
    return {
      name: ROUTE_NAMES.ORDERS_NEW.START,
    };
  }

  get orderListLink() {
    return {
      name: ROUTE_NAMES.ORDERS.LIST,
    };
  }

  get reportsLink() {
    return {
      name: ROUTE_NAMES.REPORT,
    };
  }

  get getRetailerGroupHandleForDomain() {
    return getRetailerGroupHandleForDomain();
  }

  get activeRetailerGroup() {
    return this.userModule.activeRetailerGroup?.name;
  }

  get showOrderOverview() {
    return this.$route.name !== ROUTE_NAMES.ORDERS.LIST;
  }

  get showNewOrder() {
    return this.$route.name !== ROUTE_NAMES.ORDERS_NEW.START;
  }

  get showReports() {
    return this.userModule.isAdmin && this.$route.name !== ROUTE_NAMES.REPORT;
  }
}
