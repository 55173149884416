











import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaTransitionExpand } from "@defa-as/components";

const CUTOFF_LENGTH_DEFAULT = 153;

@Component({
  components: {
    DefaTransitionExpand,
  },
})
export default class BaseTextExpandable extends Vue {
  @Prop({ required: true }) text!: string;
  @Prop({ default: CUTOFF_LENGTH_DEFAULT }) cutoffLength!: number;

  expanded = false;

  get showFullText() {
    return !this.isExpandable || this.expanded;
  }

  get isExpandable() {
    return this.text.length > this.cutoffLength;
  }

  get cutoffText() {
    return `${this.text.slice(0, this.cutoffLength - 3)}...`;
  }

  toggleExpanded() {
    if (this.isExpandable) {
      this.expanded = !this.expanded;
    }
  }
}
