


















import ManualTask from "@/components/order/progress/ManualTask.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import type { OrderStateFieldKey } from "@defa-as/utils";

interface Task {
  type: "task" | "setting";
  name: string;
  label: string;
  stateField: OrderStateFieldKey;
}

@Component({
  components: {
    ManualTask,
  },
})
export default class ManualTasks extends Vue {
  @Prop({ default: () => [] }) readonly tasks!: Task[];
}
