








import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseButton } from "@defa-as/components";

@Component({
  components: {
    DefaBaseButton,
  },
})
export default class OrderSubmissionSubmit extends Vue {
  @Prop({ default: false }) readonly submitting!: boolean;
}
