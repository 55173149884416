





























import { Component, Vue, Watch } from "vue-property-decorator";
import { DefaBaseButton, DefaLoaderCircle } from "@defa-as/components";
import type { Retailer, RetailerGroup } from "@defa-as/utils";
import RetailerGroupSwitcher from "@/components/retailer-group/RetailerGroupSwitcher.vue";
import BaseSelect from "@/components/forms/inputs-generic/BaseSelect.vue";
import { userModule } from "@/store/modules/user";
import {
  getRetailerGroupsForCurrentLocale,
  isRetailerGroupForDomainPublic,
} from "@/config/portals/retailer-groups";
import { ROUTE_NAMES } from "@/router/route-names";
import RetailerForm from "@/components/retailer/RetailerForm.vue";
import { getRetailers } from "@/http/requests/requests-retailer";
import { linkRetailer } from "@/http/requests/requests-user";

@Component({
  components: {
    RetailerForm,
    DefaLoaderCircle,
    DefaBaseButton,
    RetailerGroupSwitcher,
    BaseSelect,
  },
})
export default class ProfileRetailerInfo extends Vue {
  optionsRetailers: Retailer[] = [];
  optionsRetailerGroups: RetailerGroup[] = [];

  model = {
    activeRetailerId: userModule.activeRetailer?.id || "",
  };

  loading = {
    optionsRetailers: false,
    optionsRetailerGroups: false,
  };

  saving = {
    activeRetailerId: false,
  };

  get isRetailerSwitchingPossible() {
    return !isRetailerGroupForDomainPublic() || userModule.isAdmin;
  }

  get isLoadingOptions() {
    return Object.values(this.loading).some(Boolean);
  }

  created() {
    // Don't await calls so these load in sync
    if (this.isRetailerSwitchingPossible) {
      this.loadRetailers();
    }
    this.loadRetailerGroups();
  }

  async loadRetailers() {
    this.loading.optionsRetailers = true;
    try {
      this.optionsRetailers = await getRetailers();
    } finally {
      this.loading.optionsRetailers = false;
    }
  }

  async loadRetailerGroups() {
    this.loading.optionsRetailerGroups = true;
    try {
      this.optionsRetailerGroups = await getRetailerGroupsForCurrentLocale(
        true
      );
    } finally {
      this.loading.optionsRetailerGroups = false;
    }
  }

  async saveRetailer(selectedRetailer: Retailer["id"]) {
    this.saving.activeRetailerId = true;
    try {
      const user = await linkRetailer(selectedRetailer);
      await userModule.setUser({ user });
      if (!userModule.canUsePortal) {
        await this.$router.replace({
          name: ROUTE_NAMES.GDPR_DOCUMENT_CONFIRMATION,
        });
      }
    } finally {
      this.saving.activeRetailerId = false;
    }
  }

  // Active retailer can be switched in "RetailerGroupModalVerification"
  @Watch("userModule.activeRetailer")
  onActiveRetailerChange(updatedActiveRetailer: Retailer) {
    this.model.activeRetailerId = updatedActiveRetailer.id;
  }
}
