









import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaLoaderCircle } from "@defa-as/components";

@Component({
  components: {
    DefaLoaderCircle,
  },
})
export default class LightSwitch extends Vue {
  @Prop({ default: false }) readonly value!: boolean;
  @Prop({ default: false }) readonly loading!: boolean;

  toggle() {
    this.$emit("input", !this.value);
  }
}
