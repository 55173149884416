import { httpClient } from "@/http";
import type { Order, OrderWithHomecheckAndAssignment } from "@defa-as/utils";
import { API_VERSION_FEBRUARY_2021 } from "@defa-as/utils";
import type { PipelineFilterCounters } from "@/store/types/pipeline-filters";
import type { CancelToken } from "axios";

interface OrdersResponseMeta {
  pagination: {
    // eslint-disable-next-line camelcase
    total_pages: number;
  };
  pipelineFilterCounters: PipelineFilterCounters;
}

export interface ListOrdersResponse {
  data: Order[];
  meta: OrdersResponseMeta;
}

export const getOrder = async (orderId: Order["id"], refresh = false) =>
  httpClient.get<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}`, {
    ...(refresh
      ? {
          forceUpdate: true,
        }
      : null),
  });

export const listOrders = async (
  urlSearchParams: URLSearchParams,
  cancelToken: CancelToken
) =>
  httpClient.get<ListOrdersResponse, ListOrdersResponse>("/orders", {
    headers: {
      version: API_VERSION_FEBRUARY_2021,
      "X-Defa-Version": API_VERSION_FEBRUARY_2021,
    },
    params: urlSearchParams,
    cancelToken,
  });

export const createOrder = async (body: unknown) =>
  await httpClient.post<Order, Order>("/orders", body);

export const updateOrder = async (
  orderId: Order["id"],
  body: Record<string, unknown>
) =>
  httpClient.patch<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`/orders/${orderId}`, body);

export const refreshOrder = async (orderId: Order["id"]) =>
  getOrder(orderId, true);

export const cancelOrder = async (orderId: Order["id"]) =>
  httpClient.post<
    OrderWithHomecheckAndAssignment,
    OrderWithHomecheckAndAssignment
  >(`orders/${orderId}/cancel`);
