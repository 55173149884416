import type { RetailerGroup } from "@defa-as/utils";
import {
  captureExceptionWithSentry,
  getCountry,
  getRetailerGroupInLocalStorage,
  onProduction,
  publicRetailerGroupHandle,
  defaRetailerGroupHandle,
} from "@defa-as/utils";
import { userModule } from "@/store/modules/user";
import { getRetailerGroups } from "@/http/requests/requests-retailer";

export const getRetailerGroupsForCurrentLocale = async (
  filterOnUserDomain = false
): Promise<RetailerGroup[]> => {
  if (filterOnUserDomain && !userModule.isLoggedIn) {
    captureExceptionWithSentry(
      new Error(
        "Can't filter retailer groups on user domain when the user is not logged in. Are you sure you meant to do that?"
      )
    );
  }
  return getRetailerGroups(filterOnUserDomain);
};

export const getRetailerGroupHandleForDomain = () => {
  if (!onProduction()) {
    return getRetailerGroupInLocalStorage() || defaRetailerGroupHandle;
  }
  const retailerGroupMatch = window.location.hostname.match(
    /([A-za-z]{2,})(\.evportal)?\.defa\.com$/
  );
  if (retailerGroupMatch) {
    return retailerGroupMatch[1] || defaRetailerGroupHandle;
  }
  throw new Error("No retailer group was found in the URL");
};

export const isRetailerGroupForDomainPublic = () =>
  getRetailerGroupHandleForDomain() === publicRetailerGroupHandle;

export const getRetailerGroupForDomain = (retailerGroups: RetailerGroup[]) => {
  const retailerGroupHandle = getRetailerGroupHandleForDomain();
  return retailerGroups.find(
    (retailerGroup) => retailerGroup.handle === retailerGroupHandle
  );
};

export const originForRetailerGroup = (retailerGroupHandle: string) =>
  onProduction()
    ? `https://${getCountry()}.${retailerGroupHandle}.evportal.defa.com`
    : window.location.origin;

export const originForRetailerGroupDefa = () =>
  originForRetailerGroup(defaRetailerGroupHandle);
