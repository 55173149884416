















import { Component, Vue } from "vue-property-decorator";
import OrderContentCustomer from "@/components/OrderContentCustomer.vue";
import OrderLines from "@/components/OrderLines.vue";
import OrderContentInstallation from "@/components/OrderContentInstallation.vue";
import { activeOrderModule } from "@/store/modules/active-order";

@Component({
  components: {
    OrderContentInstallation,
    OrderContentCustomer,
    OrderLines,
  },
})
export default class OrderContent extends Vue {
  get activeOrderModule() {
    return activeOrderModule;
  }
}
